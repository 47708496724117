import '../assets/fomantic/dist/semantic.css';
import React from 'react';
import '../App.css';
import { Footer } from './Footer';
import TraitStats from './Stats/TraitStats';
import CollectionStats from './Stats/CollectionStats';
import VoyagerStats from './Stats/VoyagerStats';


export const Stats = (props:{
    id?: number;
    navigation: () => void;
}) => {
    React.useEffect(() => {
        props.navigation();
    }, [])

    return (<div className="center">
        <VoyagerStats/>
        <TraitStats/>
        <CollectionStats/>
        <Footer/>
    </div>);
}

export default Stats;