import React from "react";
import { BBPodcast } from "../services/NamerStats";
import ImageFadeIn from "react-image-fade-in";
import { getAssetURL } from "../App";
import { Divider, Grid, Icon } from "semantic-ui-react";
import ReactPlayer from "react-player";
import { isMobileOnly, isTablet, useMobileOrientation } from "react-device-detect";
import { Link } from '@reach/router';
import { logEvent } from "../utils/utils";

export const Podcast = (props: {
    pod: BBPodcast;
    divider?: boolean;
    expanded: boolean;
    expandedChanged?: (id: number)=>void;
}) => {
    const [played, setPlayed] = React.useState<boolean>(false);
    const [imgLoaded, setImgLoaded] = React.useState<boolean>(false);
    const myRef = React.useRef(null);
    let mobileOrientation = useMobileOrientation(); 

    let imageStyle = {};
    if (!imgLoaded) {
        imageStyle = { display: "none", width:'320px', textAlign:'center' };
    }

    const scrollToPod = () => {
        let element = document.getElementById("pod"+props.pod.id);
        if (element) {
            let bottom = element.getBoundingClientRect().bottom;
            console.log("scrolling");
            window.scrollTo({
                behavior: "smooth",
                top: window.pageYOffset + bottom - window.innerHeight + (window.innerHeight-505)/2
            });
        }
    }

    React.useEffect(()=>{
        if (isMobileOnly && !props.expanded) setPlayed(false);
        if (props.divider || !props.expanded || !myRef.current) return;

        scrollToPod();
    }, [props.expanded]);

    const getPodNumber = (id: number) => {
        if (id > 7) return id-1;
        if (id == 7) return 6.5;
        return id;
    }

    return <React.Fragment>
    <div className={(isMobileOnly&&mobileOrientation.isPortrait)?"podContainerM":"podContainer"} key={"pod"+props.pod.id} id={"pod"+props.pod.id} ref={myRef}>
        <div className={"unselectable pod"+(!props.expanded?" podHover":"")} onClick={()=>{
                if (!props.divider || isMobileOnly) setPlayed(true);
                if (props.expandedChanged && !props.expanded) {
                    props.expandedChanged(props.pod.id);
                    scrollToPod();
                }
            }}>
                <div>
            <div className="podThumbnail">
                <ImageFadeIn style={imageStyle} opacityTransition={1} src={props.pod.thumbnail} onLoad={()=>setImgLoaded(true)}/>
                {!props.expanded && <div className="podPlayIcon">
                    <Icon className="podPlay" name="play"/>
                    <span className="podPlayText fade-in">Play Episode</span>
                </div>}
                {isMobileOnly && played && <ReactPlayer
                    className={isMobileOnly?(mobileOrientation.isPortrait?(props.divider?"podVideoMP":"podVideoMPP"):" podVideoM"):((isTablet||mobileOrientation.isPortrait)?"podVideoT":"podVideo")}
                    url={props.pod.link}
                    playing={false}
                    controls={true}
                    muted={false}
                />}
            </div>
            </div>
            <div className="podDesc">
                <span className="podDate">{new Date(new Date(props.pod.originaldate).getTime() + new Date(props.pod.originaldate).getTimezoneOffset() * 60000).toLocaleDateString("en-US", {month: "long", day: "2-digit", year: "numeric"})}</span>
                <span className="podTitle">#{getPodNumber(props.pod.id)} - {props.pod.title}</span>
                <p className="podSynopsis">{props.pod.synopsis}</p>
            </div>
        </div>
        {props.expanded && !isMobileOnly && <ReactPlayer
            className={isMobileOnly?(mobileOrientation.isPortrait?"podVideoMP":" podVideoM"):((isTablet||mobileOrientation.isPortrait)?"podVideoT":"podVideo")}
            url={props.pod.link}
            playing={!props.divider && !isMobileOnly}
            controls={true}
            muted={false}
        />}
    </div>
    {isMobileOnly && props.divider && <Link className="centered"
        to={"/podcast"}
        onClick={()=>logEvent("Podcast", "event")}>
        More Big Book Podcast episodes
    </Link>}
</React.Fragment>
}

export default Podcast;