import '../assets/fomantic/dist/semantic.css';
import { Container, Icon, Table } from 'semantic-ui-react';
import { Link } from '@reach/router';
import React from 'react';
import { Helmet } from 'react-helmet';

import '../App.css';
import { NamerCrew, getCrew, getCrewNotes, CrewNote, getAllCrewNotes } from '../services/NamerStats';
import { SearchableTable, ITableConfigRow } from './SearchableTable';
import { Footer } from './Footer';
import { logEvent, rarityClass, showThisCrew } from '../utils/utils';
import { getAssetURL } from '../App';

export const Flavors = (props: {
    navigation: () => void;
}) => {
    const [crew, setCrew] = React.useState<NamerCrew[]|undefined>(undefined);
    const [tempCrew, setTempCrew] = React.useState<NamerCrew[]|undefined>(undefined);
    const [crewNotes, setCrewNotes] = React.useState<Map<string,CrewNote|undefined>>(new Map());
    const [tempCrewNotes, setTempCrewNotes] = React.useState<Map<string,CrewNote|undefined>>(new Map());

    React.useEffect(() => {
        props.navigation();
        if (!crew) {
            getCrew().then((data) => {
                if (data && data.length > 0) {
                    // data = data.filter(x=>x.rarity>=4);
                    data.forEach(x=> {
                        x.triplet = x.skills.join("/");
                        x.flen = x.flavor ? x.flavor.length : 0;
                    });
                    data.sort((a,b) => a.fRank-b.fRank);
                    setTempCrew(data);
                    getAllCrewNotes().then(data=>setTempCrewNotes(data));
                }
            });
        }
    }, []);

    React.useEffect(() => {
        if (tempCrew && tempCrewNotes.size > 0) {
            setCrewNotes(tempCrewNotes);
            setCrew(tempCrew);
        }
    }, [tempCrew, tempCrewNotes]);

    if (!crew) {
        return <Container><div className="center ui active loader"/></Container>;
    }

    const tableConfig: ITableConfigRow[] = [
        { width: 4, column: 'name', title: 'Crew' },
        { width: 1, column: 'fRank', title: 'Rank', secondaryColumn: 'fTier', class: 'row-header'},
        { width: 1, column: 'flen', title: 'Length', secondaryColumn: 'fRank', class: 'row-header'},
        { width: 5, column: 'fNote', title: 'Editorial review', class: 'row-header'},  
    ]

    const descriptionLabel = (crew: NamerCrew) => {
        if (!crew.flavor) {
            return "";
        }
        return <span style={{fontSize:"small", fontStyle:"italic"}}><Icon size="small" className="quote right icon"/>{crew.flavor}</span>
    }

  const renderTableRow = (crew: NamerCrew, idx: number) => {
    return (
        <React.Fragment>
            <Table.Cell>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '60px auto',
                        gridTemplateAreas: `'icon stats' 'icon description'`,
                        gridGap: '1px'
                    }}
                >
                    <div style={{ gridArea: 'icon' }}>
                        <img width={48} src={getAssetURL(crew.portrait)} alt={crew.name}/>
                    </div>
                    <div style={{ gridArea: 'stats' }} className={rarityClass(crew.rarity)}>
                        <Link className={rarityClass(crew.rarity)}
                            to={"/crew/"+crew.symbol}
                            style={{ fontWeight: 'bolder', fontSize: '1.25em' }} 
                            onClick={()=>logEvent("CrewLink", "flavor", crew.symbol)}>
                            {crew.name}
                        </Link>
                    </div>
                    <div style={{ gridArea: 'description' }}>{descriptionLabel(crew)}</div>
                </div>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>#{crew.fRank}</b><br />
                {crewNotes.get(crew.symbol)?.fTier > 0 && <small style={{ fontSize: '100%' }}>Tier {crewNotes.get(crew.symbol)?.fTier}</small>}
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>{crew.flen}</b><br />
                {crew.flen > 0 && <small style={{ fontSize: '100%' }}>{crew.flavor.split(" ").length} words</small>}
            </Table.Cell>      
            <Table.Cell>
                {crewNotes.get(crew.symbol)?.fNote}
            </Table.Cell>
        </React.Fragment>
    );
  }

  const title = "Flavor ratings - The Big Book of Behold Advice";
  const link = "https://www.bigbook.app/ratings/flavor";
  const description = "Star Trek Timelines crew flavor text ratings and reviews";

  return (
    <div>
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={link} />
            <meta name="description" content={description}/>
            <meta property="og:url" content={link}/>
            <meta property="og:description" content={description}/>
            <meta name="twitter:url" content={link}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
        </Helmet>
        <SearchableTable
            id="flavor_crew"
            data={crew}
            config={tableConfig}
            renderTableRow={(crew, idx) => renderTableRow(crew, idx)}
            //renderExpandedRow={(crew, idx) => renderExpandedRow(crew, idx)}
            filterRow={showThisCrew}
            showFilterOptions={false}
            showSearchExplanation={true}
        />
        <Footer/>
    </div>
  );
}

export default Flavors;