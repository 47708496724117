import React, { PureComponent } from 'react';
import { isMobile } from 'react-device-detect';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine, Label } from 'recharts';
import { Checkbox, Container } from 'semantic-ui-react';
import { getCrew, NamerCrew } from '../../services/NamerStats';

export const VoyagerStats = () => {
    const rarities = [1,2,3,4,5]
    const legend = [
      {},
      { key: "common", color: "rgb(111, 116, 128)"},
      { key: "uncommon", color: "rgb(77, 163, 58)"},
      { key: "rare", color: "rgba(90, 170, 255, 1)"},
      { key: "superrare", color: "rgba(170, 45, 235, 1)"},
      { key: "legendary", color: "rgba(253, 210, 106, 1)"},
    ] 

    const [crew, setCrew] = React.useState<NamerCrew[]|undefined>(undefined);
    const [data, setData] = React.useState<Map<number,any>|undefined>(undefined);
    const [rarity, setRarity] = React.useState(2<<5);
    const [range, setRange] = React.useState([2700, 4000]);
    const [skillets, setSkillets] = React.useState(false);

    const updateDataFilters = (crew) => {
      if (!crew || crew.length == 0) return;
        crew.sort((a,b)=>b.vScoreR-a.vScoreR);
        let d = new Map();
        for (let r=1; r<=5;r++) {
          d.set(r, crew.filter(x=>x.rarity==r&&(skillets||x.skillset.length>2)).map((x,idx)=>
            ({name:x.name,rank:(idx+1).toString(),score:x.vScoreR.toFixed(0)})));
        }
        setData(new Map(d));
    }

    const toggleRarity = (r: number) => {
      if (r==0) setRarity(0);
      else setRarity(rarity^(2<<r));
    }

    const isRaritySelected = (r: number) => {
      if (r==0) return rarity==0;
      return ((rarity as number)&(2<<r))>0;
    }

    React.useEffect(() => {
        if (!crew || crew.length == 0) {
            getCrew().then((d) => {
                if (d && d.length > 0) {
                    setCrew(d);
                    updateDataFilters(d);
                }            
            });
        }
    }, [])

    React.useEffect(() => {
        updateDataFilters(crew);
    }, [rarity, skillets]);

    React.useEffect(() => {
      if (!data || data.size==0) {
        return;
      }
      let min=0,max=4000;
      for (let r=5;r>0;r--) {
        if (isRaritySelected(r)) {
          max = Math.ceil(data.get(r)[0].score/100)*100;
          break;
        }
      }
      for (let r=1;r<=5;r++) {
        if (isRaritySelected(r)) {
          min = Math.ceil(data.get(r)[data.get(r).length-1].score/100-1)*100;
          break;
        }
      }
      setRange([min, max]);
    }, [data]);

    if (crew == undefined || data == undefined) {
        return <Container>
          <div className="center" style={{marginTop:"1em",marginBottom:"2em"}}>
            <h3>Crew by unboosted voyage rank</h3>
            <div className="ui centered active inline loader"/>
          </div>
        </Container>;
    }

    return (<div className="center" style={{marginTop:"1em",marginBottom:"2em"}}>
        <h3>Crew by unboosted voyage rank</h3>
        <Checkbox fitted label="Show only triple-skill crew"
          checked={!skillets} onClick={() =>{setSkillets(!skillets)}}/>
          
        <div className={"rarityfilter"}>
          <span className="mono">Show:{' '}</span>
          <span className={"rarityfilter-0"+(isRaritySelected(0)?" rarityselected":"")} style={isRaritySelected(0)?{color:'white'}:{}} onClick={()=>toggleRarity(0)}>All</span>|
          <span className={"rarityfilter-5"+(isRaritySelected(5)?" rarityselected":"")} onClick={()=>toggleRarity(5)}>Legendary</span>{' '}
          <span className={"rarityfilter-4"+(isRaritySelected(4)?" rarityselected":"")} onClick={()=>toggleRarity(4)}>Super Rare</span>{' '}
          <span className={"rarityfilter-3 rare"+(isRaritySelected(3)?" rarityselected":"")} onClick={()=>toggleRarity(3)}>Rare</span>{' '}
          <span className={"rarityfilter-2 uncommon"+(isRaritySelected(2)?" rarityselected":"")} onClick={()=>toggleRarity(2)}>Uncommon</span>{' '}
          <span className={"rarityfilter-1 common"+(isRaritySelected(1)?" rarityselected":"")} onClick={()=>toggleRarity(1)}>Common</span>
        </div>
        
        <div className={isMobile?"statsPanels2m":"statsPanels2"}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart height={isMobile?500:300}>
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="rank" type="category" allowDuplicatedCategory={false} />
                <YAxis dataKey="score" type='number' domain={range} tickCount={12} />
                <Tooltip  formatter={(value, name, props)=>{
                        //const cname = name=="legendary"?legendaryData[props.payload.rank]. 
                        return value + " " + props.payload.name;
                    }}
                    contentStyle={{background:'rgb(50,50,50)', borderColor:"black"}} cursor={{fill:"rgb(50,50,50,0.7)"}}/>
                {(isRaritySelected(5) || isRaritySelected(0)) && <ReferenceLine y={3582} label={<Label value={"'Later losers' >3582"} fill={'white'}/>} stroke="gray" />}
                {(isRaritySelected(4) || isRaritySelected(0)) && <ReferenceLine y={data.get(4)[0].score} label={<Label value={"SR max "+data.get(4)[0].score} fill={'white'}/>} stroke="gray" />}
                {rarities.map((r,idx)=>{
                  if (isRaritySelected(r) || isRaritySelected(0)) {
                    return <Line dataKey="score" data={data.get(r)}
                      name={legend[r].key} key={legend[r].key} 
                      stroke={legend[r].color} fill={legend[r].color}/>}})}
                </LineChart>
            </ResponsiveContainer>
        </div>
    </div>); 
}

export default VoyagerStats;
