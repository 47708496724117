import '../assets/fomantic/dist/semantic.css';
import { Grid, Container, Header } from 'semantic-ui-react';
import { navigate } from "@reach/router";
import React from 'react';

import '../App.css';
import { EventLeaderboard, getLeaderboard } from '../services/NamerStats';
import { isMobile, isTablet } from 'react-device-detect';
import { Footer } from './Footer';
import { renderDefaultExplanation } from './SearchableTable';

export const Help = (props:{
    id?: number;
    navigation: () => void;
}) => {

    React.useEffect(() => {
        props.navigation();
    }, [])

    return <div>
        <h1 className="center">Search help</h1>
        <br/>
        {renderDefaultExplanation()}
        <Footer/>
    </div>;
}

export default Help;