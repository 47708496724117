import '../../assets/fomantic/dist/semantic.css';
import { Icon, List, Statistic, StatisticGroup } from 'semantic-ui-react';

import '../../App.css';
import { NamerCrew } from '../../services/NamerStats';
import { getAbilityNameAndIcon, getChargePhases, getShipBoostElement, getShipTriggerNameAndIcon } from '../../utils/utils';
import React from 'react';
import AdSense from 'react-adsense';
import { getAssetURL } from '../../App';
import { isMobileOnly } from 'react-device-detect';

export const ArenaStats = (props: {
    crew: NamerCrew;
    showAd?: boolean;
}) => {
    const [abilityIcon, abilityDesc] = getAbilityNameAndIcon(props.crew, props.crew.ship_action.ability.amount);
    const [triggerIcon, triggerAlt, triggerName] = getShipTriggerNameAndIcon(props.crew.ship_action.ability.condition);
    let extraStyle = {}
    if (!props.crew.ship_action.charge_phases || props.crew.ship_action.charge_phases.length == 0) {
        if (props.crew.ship_action.bonus_type == 1 && props.crew.ship_action.ability.type == 0) {
            extraStyle = { marginTop:"2px"}
        } else {
            extraStyle = { marginTop:"6px" }
        }
    }

    return (
        <List divided relaxed>
            <List.Item style={{marginBottom:"5px"}}>
                <Icon size="large" style={{marginRight:'10px'}}><img src={getAssetURL("ship_icon.png")} style={{maxWidth:"30px"}} alt="Action"/></Icon>
                <List.Content>
                    <List.Header style={{marginBottom:"5px"}}>{' '}{props.crew.ship_action.name ? <i><big>{props.crew.ship_action.name}</big></i> : <i>No ship action name</i>}</List.Header>
                    <List.Description style={{marginBottom:"8px"}}>
                    <StatisticGroup size="mini">
                            <Statistic>
                                <Statistic.Label>Bonus</Statistic.Label>
                                <Statistic.Value>{getShipBoostElement(props.crew.ship_action.bonus_amount+props.crew.rarity-1, props.crew.ship_action.bonus_type)}</Statistic.Value>
                            </Statistic>
                            <Statistic>
                                <Statistic.Label>Initiation</Statistic.Label>
                                <Statistic.Value style={{textTransform:"none"}}>{props.crew.ship_action.initial_cooldown}s</Statistic.Value>
                            </Statistic>
                            <Statistic>
                                <Statistic.Label>Duration</Statistic.Label>
                                <Statistic.Value style={{textTransform:"none"}}>{props.crew.ship_action.duration}s</Statistic.Value>
                            </Statistic>
                            <Statistic>
                                <Statistic.Label>Cooldown</Statistic.Label>
                                <Statistic.Value style={{textTransform:"none"}}>{props.crew.ship_action.cooldown}s</Statistic.Value>
                            </Statistic>
                            {props.crew.ship_action.limit > 0 && <Statistic>
                                <Statistic.Label>Limit</Statistic.Label>
                                <Statistic.Value>{props.crew.ship_action.limit}</Statistic.Value>
                            </Statistic>}
                            {props.crew.ship_action.penalty && props.crew.ship_action.penalty.amount > 0 && <Statistic>
                                <Statistic.Label>Penalty</Statistic.Label>
                                <Statistic.Value>{getShipBoostElement(-props.crew.ship_action.penalty.amount, props.crew.ship_action.penalty.type)}</Statistic.Value>
                            </Statistic>}
                            {props.crew.ship_action.ability.condition > 0 && <Statistic>
                                <Statistic.Label>Trigger</Statistic.Label>
                                <Statistic.Value><img style={{maxWidth:"24px", maxHeight:"24px"}} src={getAssetURL(triggerIcon)} alt={triggerAlt} title={triggerName}/></Statistic.Value>
                            </Statistic>}
                        </StatisticGroup>
                    </List.Description>
                </List.Content>
                {(props.crew.ship_action.ability.amount > 0 || (props.crew.ship_action.charge_phases && props.crew.ship_action.charge_phases.length > 0)) && <React.Fragment>
                    <br/>
                    <Icon size="large" style={{marginRight:'10px'}}><img className={"filter"+props.crew.ship_action.bonus_type} src={getAssetURL(abilityIcon)} style={{maxWidth:"30px"}} alt=""/></Icon>
                    <List.Content>
                        <List.Header style={{...extraStyle, marginBottom:"5px", fontSize:"larger"}}>{abilityDesc}</List.Header>
                        <List.Description>
                            {getChargePhases(props.crew)}
                        </List.Description>
                    </List.Content>
                </React.Fragment>}
            </List.Item>
            <List.Item>
                <List.Icon name='chart bar outline' size='large' verticalAlign='top' />
                <List.Content>
                    <List.Description>
                        <StatisticGroup size="mini">
                            <Statistic>
                                <Statistic.Label>Accuracy</Statistic.Label>
                                <Statistic.Value>{props.crew.ship_battle.accuracy}</Statistic.Value>
                            </Statistic>
                            <Statistic>
                                <Statistic.Label>Crit Bonus</Statistic.Label>
                                <Statistic.Value>{props.crew.ship_battle.crit_bonus}</Statistic.Value>
                            </Statistic>
                            <Statistic>
                                <Statistic.Label>Crit Rating</Statistic.Label>
                                <Statistic.Value>{props.crew.ship_battle.crit_chance}</Statistic.Value>
                            </Statistic>
                            <Statistic>
                                <Statistic.Label>Evasion</Statistic.Label>
                                <Statistic.Value>{props.crew.ship_battle.evasion}</Statistic.Value>
                            </Statistic>
                        </StatisticGroup>
                    </List.Description>
                </List.Content>
            </List.Item>
        </List>)
  }

  export default ArenaStats;