import '../assets/fomantic/dist/semantic.css';
import { Container, Table, Image } from 'semantic-ui-react';
import React from 'react';
import { Link } from '@reach/router';
import { Helmet } from 'react-helmet';

import '../App.css';
import { NamerCrew, Quipment, getCrew, getQuipments } from '../services/NamerStats';
import { SearchableTable, ITableConfigRow } from './SearchableTable';
import { Footer } from './Footer';
import { logEvent, rarityClass, showThisCrew, showThisQuipment } from '../utils/utils';
import { getAssetURL } from '../App';
import { traitIcon } from './EventList';

export const Quipments = (props: {
    navigation: () => void;
}) => {
    const cSkills = ["cmd","dip","eng","med","sci","sec"]
    const [equipment, setEquipment] = React.useState<Quipment[]|undefined>(undefined);

    React.useEffect(() => {
        props.navigation();
        if (equipment) {
            return;
        }
        getQuipments().then((data) => {
            if (data && data.length > 0) {
                data.forEach(x=> {
                    x.totalBases = 0;
                    x.totalMinProfs = 0;
                    x.totalMaxProfs = 0;
                    x.totalProfs = 0;
                    x.totalRoll = 0;
                    x.rolls = new Map();
                    for (const skill of cSkills) {
                        x.totalBases += x.bases[skill];
                        x.totalMinProfs += x.minProfs[skill]
                        x.totalMaxProfs += x.maxProfs[skill]
                        x.totalProfs += (x.minProfs[skill] + x.maxProfs[skill]) / 2;
                        x.rolls.set(skill, x.bases[skill] + (x.minProfs[skill] + x.maxProfs[skill]) / 2);
                    }
                    x.cmd = x.rolls.get("cmd");
                    x.dip = x.rolls.get("dip");
                    x.eng = x.rolls.get("eng");
                    x.med = x.rolls.get("med");
                    x.sci = x.rolls.get("sci");
                    x.sec = x.rolls.get("sec");
                    x.totalProfs = Math.floor(x.totalProfs);
                    x.totalRoll = x.totalBases + x.totalProfs;
                });
                data.sort((a,b) => b.rarity-a.rarity || b.max_rarity_requirement - a.max_rarity_requirement || b.totalRoll-a.totalRoll);
                setEquipment(data);
            }            
        });
    }, []);

    if (!equipment) {
        return <Container><div className="center ui active loader"/></Container>;
    }

    const tableConfig: ITableConfigRow[] = [
        { width: 3, column: 'name', title: 'Continuum equipment' },
        { width: 1, column: 'max_rarity_requirement', title: 'Max Rarity', secondaryColumn: 'totalRoll', class: 'row-header'},
        { width: 2, column: 'len(traits_requirement)', title: 'Traits (AND)', secondaryColumn: 'totalRoll', class: 'row-header'},
        { width: 1, column: 'totalRoll', title: 'Total', class: 'row-header'},
        { width: 1, column: 'totalBases', title: 'Bases', secondaryColumn: 'totalRoll', class: 'row-header'},
        { width: 1, column: 'totalProfs', title: 'Proficiencies', secondaryColumn: 'totalRoll', class: 'row-header'},
        { width: 1, column: 'cmd', title: <img alt='C' width='14' src={getAssetURL('icons_icon_command_skill.png')}/>, secondaryColumn: 'totalRoll', class: 'row-header'},
        { width: 1, column: 'dip', title: <img alt='D' width='14' src={getAssetURL('icons_icon_diplomacy_skill.png')}/>, secondaryColumn: 'totalRoll', class: 'row-header'},
        { width: 1, column: 'eng', title: <img alt='E' width='14' src={getAssetURL('icons_icon_engineering_skill.png')}/>, secondaryColumn: 'totalRoll', class: 'row-header'},
        { width: 1, column: 'med', title: <img alt='M' width='14' src={getAssetURL('icons_icon_medicine_skill.png')}/>, secondaryColumn: 'totalRoll', class: 'row-header'},
        { width: 1, column: 'sci', title: <img alt='Sc' width='14' src={getAssetURL('icons_icon_science_skill.png')}/>, secondaryColumn: 'totalRoll', class: 'row-header'},
        { width: 1, column: 'sec', title: <img alt='Se' width='14' src={getAssetURL('icons_icon_security_skill.png')}/>, secondaryColumn: 'totalRoll', class: 'row-header'},
    ]

    const descriptionLabel = (crew: NamerCrew) => {
        // let desc = [];
        // crew.bMentions.forEach((x, idx)=> {
        //     let tokens = x.split(" "); 
        //     desc.push(<Label key={idx} className="top-pair" horizontal size='small' basic circular>
        //         {tokens[0]} {tokens[1]}
        //     </Label>)
        // });
        let desc = [];
        crew.bMentions.forEach((x, idx)=> {
            desc.push(<span className="nowrap" key={idx}>{x}{(idx!==crew.bMentions.length-1)?", ":""}{idx%2===1?<br/>:''}</span>)
        });
        return <div className="shuttles-topranks">{desc.map(x=>x)}</div>
    }

  const renderTableRow = (quipment: Quipment, idx: number) => {
    return (
        <React.Fragment>
            <Table.Cell>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '60px auto',
                        gridTemplateAreas: `'icon stats' 'icon description'`,
                        gridGap: '1px'
                    }}
                >
                    <div style={{ gridArea: 'icon' }}>
                        <img alt={quipment.name} width={48} src={getAssetURL(quipment.icon)} />
                    </div>
                    <div style={{ gridArea: 'stats' }}>
                        <span className={rarityClass(quipment.rarity)}>
                            {quipment.name}
                        </span>
                    </div>
                    <div style={{ gridArea: 'description' }}><i>{quipment.flavor}</i></div>
                </div>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>{quipment.max_rarity_requirement}</b>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {quipment.traits_requirement && quipment.traits_requirement.map((trait,idx)=>{
                    return <div className="inline2" key={"t"+idx}>
                        <Image style={{width:'28px',marginRight:'4px'}} src={traitIcon(trait)}/>
                        <Link className="blueLink" to={"/ratings/voyage?search="+encodeURIComponent(quipment.traits_named.join(" "))} onClick={()=>logEvent("SearchType", "trait", quipment.traits_named.join(" "))}>{quipment.traits_named[idx]}</Link>
                    </div>
                })}
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>{quipment.totalBases}</b>
                <br/>
                <small style={{ fontSize: '80%', color: 'darkgray' }}><b>({quipment.totalMinProfs} - {quipment.totalMaxProfs})</b></small>
                <br/>
                <small style={{ fontSize: '80%', color: 'darkgray' }}>Avg: {quipment.totalRoll}</small>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {quipment.totalBases}
            </Table.Cell>
            <Table.Cell key='total' textAlign='center'>
                {quipment.totalMinProfs} - {quipment.totalMaxProfs}
                <br/>
                <small style={{ fontSize: '80%', color: 'darkgray' }}>Avg: {quipment.totalProfs}</small>
            </Table.Cell>
            {cSkills.map((skill,idx)=>{
                return quipment.rolls.get(skill) > 0 ? (
                    <Table.Cell key={skill} textAlign='center'>
                        <b>{quipment.bases[skill].toFixed(0)}</b>
                        <br />
                        <small style={{ fontSize: '80%', color: 'darkgray' }}>({quipment.minProfs[skill]} - {quipment.maxProfs[skill]})</small>
                        <br/>
                        <small style={{ fontSize: '80%', color: 'darkgray' }}>Avg: {quipment.rolls.get(skill)}</small>
                    </Table.Cell>
                ) : (
                    <Table.Cell key={skill} />
                )
            })}
        </React.Fragment>
    );
  }

  const title = "Continuum equipments - The Big Book of Behold Advice";
  const link = "https://www.bigbook.app/ratings/quipments";
  const description = "Star Trek Timelines list of continuum equipments, quipments or kwipments";

  return (
    <div>
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={link} />
            <meta name="description" content={description}/>
            <meta property="og:url" content={link}/>
            <meta property="og:description" content={description}/>
            <meta name="twitter:url" content={link}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
        </Helmet>
        <SearchableTable
            id="quipments"
            data={equipment}
            config={tableConfig}
            renderTableRow={(crew, idx) => renderTableRow(crew, idx)}
            filterRow={(crew, filters, filterType) => showThisQuipment(crew, filters, filterType)}
            showFilterOptions={false}
            showSearchExplanation={true}
            clearSearch={true}
        />
        <Footer/>
    </div>
  );
}

export default Quipments;