import '../assets/fomantic/dist/semantic.css';
import { Container, Divider, List, Statistic, StatisticGroup, Table } from 'semantic-ui-react';
import { Link } from '@reach/router';
import React from 'react';
import { Helmet } from 'react-helmet';

import '../App.css';
import { NamerCrew, getCrew, EventInfo, getEventInfo } from '../services/NamerStats';
import { SearchableTable, ITableConfigRow } from './SearchableTable';
import { isMobile, isTablet } from 'react-device-detect';
import { Footer } from './Footer';
import EventStats from './CrewStats/EventStats';
import { getAbilityNameAndIcon, getChargePhases, getShipBoostElement, getShipTriggerNameAndIcon, logEvent, rarityClass, showThisCrew } from '../utils/utils';
import { getAssetURL } from './../App';

export const Arena = (props: {
    navigation: () => void;
}) => {
    const [crew, setCrew] = React.useState<NamerCrew[]|undefined>(undefined);

    React.useEffect(() => {
        props.navigation();
        if (!crew) {
            getCrew().then((data) => {
                if (data && data.length > 0) {
                    // data = data.filter(x=>x.rarity>=4);
                    data.forEach(x=> {
                        x.triplet = x.skills.join("/");
                        x.aBoost = x.ship_action.bonus_amount + x.rarity - 1;
                        x.aPhases = x.ship_action.charge_phases ? x.ship_action.charge_phases.length : 0;
                        x.aAbAmount = x.ship_action.ability.amount;
                        if (x.aAbAmount > 0 && x.ship_action.ability.type == 0) {
                            x.aAbAmount += x.ship_action.bonus_amount + x.rarity - 1;
                        }
                        let type="";
                        switch (x.ship_action.bonus_type) {
                            case 0: type="attack"; break;
                            case 1: type="evasion"; break;
                            case 2: type="accuracy"; break;
                        }
                        x.aSearch = [];
                        x.aSearch.push("+" + x.aBoost + " " + type);
                        x.aSearch.push(x.ship_action.name);
                        if (x.ship_action.ability.condition) {
                            x.aSearch.push("trigger");
                            const [_, alt, __] = getShipTriggerNameAndIcon(x.ship_action.ability.condition);
                            x.aSearch.push(alt);
                        }
                        if (x.ship_action.penalty && x.ship_action.penalty.amount > 0) {
                            if (x.ship_action.penalty.type == 3) {
                                x.aSearch.push("ShieldRegen");
                            }
                            x.aSearch.push("penalty");
                        }
                        if (x.ship_action.limit > 0) {
                            x.aSearch.push("limit");
                        }
                        if (x.ship_action.ability && x.ship_action.ability.amount > 0) {
                            x.aSearch.push("ability");
                            const [_, desc] = getAbilityNameAndIcon(x, x.ship_action.ability.amount);
                            x.aSearch.push(desc);
                        }
                        if (x.ship_action.charge_phases && x.ship_action.charge_phases.length > 0) {
                            x.aSearch.push("charges");
                            x.aSearch.push("phases");
                            for (const charge of x.ship_action.charge_phases) {
                                const [_, desc] = getAbilityNameAndIcon(x, charge.ability_amount);
                                x.aSearch.push(desc);
                            }
                        }
                        x.ship_action.uptime = Math.round(100*(x.ship_action.duration / (x.ship_action.duration + x.ship_action.cooldown)))
                        x.ship_action.cycle = x.ship_action.duration + x.ship_action.cooldown
                    });
                    data.sort((a,b) => b.rarity-a.rarity || b.aRating-a.aRating);
                    setCrew(data);
                }            
            });
        }
    }, [])

    if (crew == undefined) {
        return <Container><div className="center ui active loader"/></Container>;
    }

    const tableConfig: ITableConfigRow[] = [
        { width: 5, column: 'name', title: 'Crew' },
        { width: 1, column: 'aRating', title: 'Rating', class: 'row-header'},,
        { width: 1, column: 'aBoost', title: 'Boost', secondaryColumn: 'aRating', class: 'row-header'},
        { width: 1, column: 'ship_action.initial_cooldown', title: 'Init', secondaryColumn: '-aRating', class: 'row-header'},
        { width: 1, column: 'ship_action.duration', title: 'Dur.', secondaryColumn: '-aRating', class: 'row-header'},
        { width: 1, column: 'ship_action.cooldown', title: 'CD', secondaryColumn: '-aRating', class: 'row-header'},
        { width: 1, column: 'ship_action.cycle', title: 'Cycle', secondaryColumn: '-aRating', class: 'row-header'},
        { width: 1, column: 'ship_action.uptime', title: 'Uptime', secondaryColumn: '-aRating', class: 'row-header'},
        { width: 5, column: 'aAbAmount', title: 'Ability', secondaryColumn: '-aRating', class: 'row-header'},
        { width: 1, column: 'ship_action.limit', title: 'Limit', secondaryColumn: '-aRating', class: 'row-header'},
        { width: 1, column: 'ship_action.ability.condition', title: 'Trigger', secondaryColumn: '-aRating', class: 'row-header'},
        { width: 1, column: 'ship_action.penalty.amount', title: 'Penalty', secondaryColumn: 'aRating', class: 'row-header'},
        { width: 1, column: 'aPhases', title: "Charges", secondaryColumn: 'aRating', class: 'row-header'},
        { width: 1, column: 'ship_battle.crit_chance', title: "Crit Rating", secondaryColumn: 'aRating', class: 'row-header'},
        { width: 1, column: 'ship_battle.crit_bonus', title: "Crit Bonus", secondaryColumn: 'aRating', class: 'row-header'}
    ]

    const descriptionLabel = (crew: NamerCrew) => {
        return <span style={{fontSize:"small"}}>{crew.ship_action.name}</span>
    }

    const getShipAbility = (crew: NamerCrew) => {
        if (crew.ship_action.ability.amount === 0) {
            return;
        }

        const [icon, desc] = getAbilityNameAndIcon(crew, crew.ship_action.ability.amount);
        return <div className="sbAbility">
            <img className={"filter"+crew.ship_action.bonus_type} src={getAssetURL(icon)} alt="ship ability"/>
            <span>{desc}</span>
        </div>
    }

    const getShipTrigger = (type: number) => {
        if (type === 0) {
            return;
        }
        const [icon, alt, title] = getShipTriggerNameAndIcon(type);
        return <img style={{maxWidth:"24px", maxHeight:"24px"}} src={getAssetURL(icon)} alt={alt} title={title}/>
    }

  const renderTableRow = (crew: NamerCrew, idx: number) => {
    return (
        <React.Fragment>
            <Table.Cell>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '60px auto',
                        gridTemplateAreas: `'icon stats' 'icon description'`,
                        gridGap: '1px'
                    }}
                >
                    <div style={{ gridArea: 'icon' }}>
                        <img width={48} src={getAssetURL(crew.portrait)} />
                    </div>
                    <div style={{ gridArea: 'stats' }} className={rarityClass(crew.rarity)}>
                        <Link className={rarityClass(crew.rarity)}
                            to={"/crew/"+crew.symbol}
                            style={{ fontWeight: 'bolder', fontSize: '1.25em' }}
                            onClick={()=>logEvent("CrewLink", "event", crew.symbol)}>
                            {crew.name}
                        </Link>
                    </div>
                    <div style={{ gridArea: 'description' }}>{descriptionLabel(crew)}</div>
                </div>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>#{crew.aRank}</b><br />
                <small style={{ fontSize: '100%' }}>{crew.aRating}</small>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {getShipBoostElement(crew.aBoost, crew.ship_action.bonus_type)}
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {crew.ship_action.initial_cooldown ?? ""}s
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {crew.ship_action.duration ?? ""}s
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {crew.ship_action.cooldown ?? ""}s
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {crew.ship_action.cycle}s
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {crew.ship_action.uptime}%
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {getShipAbility(crew)}
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {crew.ship_action.limit > 0 ? crew.ship_action.limit : ""}
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {getShipTrigger(crew.ship_action.ability.condition)}
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {crew.ship_action.penalty && crew.ship_action.penalty.amount > 0 && getShipBoostElement(-crew.ship_action.penalty.amount, crew.ship_action.penalty.type)}
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {crew.aPhases > 0 ? crew.aPhases : ""}
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {crew.ship_battle.crit_chance}
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {crew.ship_battle.crit_bonus}
            </Table.Cell>
        </React.Fragment>
    );
  }

  const renderExpandedRow = (crew: NamerCrew, idx: number) => {
    // if (!crew.ship_action.charge_phases || crew.ship_action.charge_phases.length == 0) {
    //     return undefined;
    // }
    return (
        <React.Fragment>
            <Table.Cell colSpan={15} className="expanded-cell">
                <List>
                    {!(!crew.ship_action.charge_phases || crew.ship_action.charge_phases.length == 0) && <List.Item>
                        <List.Icon name='angle double right' size='large' verticalAlign='top' />
                        <List.Content>
                            Charge phases:
                            <br/>
                            {getChargePhases(crew)}
                        </List.Content>
                    </List.Item>}
                
                    <List.Item>
                        <List.Icon name='chart bar outline' size='large' verticalAlign='top' />
                        <List.Content>
                            <List.Description>
                                <StatisticGroup size="mini">
                                    <Statistic>
                                        <Statistic.Label>Accuracy</Statistic.Label>
                                        <Statistic.Value>{crew.ship_battle.accuracy}</Statistic.Value>
                                    </Statistic>
                                    <Statistic>
                                        <Statistic.Label>Crit Bonus</Statistic.Label>
                                        <Statistic.Value>{crew.ship_battle.crit_bonus}</Statistic.Value>
                                    </Statistic>
                                    <Statistic>
                                        <Statistic.Label>Crit Rating</Statistic.Label>
                                        <Statistic.Value>{crew.ship_battle.crit_chance}</Statistic.Value>
                                    </Statistic>
                                    <Statistic>
                                        <Statistic.Label>Evasion</Statistic.Label>
                                        <Statistic.Value>{crew.ship_battle.evasion}</Statistic.Value>
                                    </Statistic>
                                </StatisticGroup>
                            </List.Description>
                        </List.Content>
                    </List.Item>
                </List>
            </Table.Cell>
        </React.Fragment>
    )
  }

  const title = "Ship battle ratings - The Big Book of Behold Advice";
  const link = "https://www.bigbook.app/ratings/arena";
  const description = "Star Trek Timelines skirmish and arena crew abilities and utility ratings";

  return (
    <div>
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={link} />
            <meta name="description" content={description}/>
            <meta property="og:url" content={link}/>
            <meta property="og:description" content={description}/>
            <meta name="twitter:url" content={link}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
        </Helmet>
        <SearchableTable
            id="arena_crew"
            data={crew}
            config={tableConfig}
            renderTableRow={(crew, idx) => renderTableRow(crew, idx)}
            renderExpandedRow={(crew, idx) => renderExpandedRow(crew, idx)}
            filterRow={(crew, filters, filterType) => showThisCrew(crew, filters, filterType, true)}
            showFilterOptions={false}
            showSearchExplanation={true}
            querySuggestions={true}
        />
        <br/>
        <Footer/>
    </div>
  );
}

export default Arena;