import '../assets/fomantic/dist/semantic.css';
import { Grid, Container, Label, Button, Icon, GridRow, Divider } from 'semantic-ui-react';
import React from 'react';
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";
import ReactTwitchEmbedVideo from "react-twitch-embed-video";
import { Link } from '@reach/router';

import '../App.css';
import { CrewNote, getCrewNotes, getCrew, NamerCrew, FeaturedCrewSkillInfo, FullEventInfo, getFullEventInfo, getOngoingLeaderboard, EventLeaderboard, EventInfo, getLeaderboard, BBPodcast, getPodcasts } from '../services/NamerStats';
import { FeaturedCrew, FeaturedCrewSkill } from './FeaturedCrew';
import { Footer } from '../components/Footer';
import { isMobileOnly, isTablet, useMobileOrientation } from 'react-device-detect';
import { getEventHeader } from './Event';
import { logEvent } from '../utils/utils';
import Podcast from './Podcast';

export const Featured = (props: {
    navigation: () => void;
    livestream?: boolean;
}) => {
    let mobileOrientation = useMobileOrientation();
    const isMinimal = isMobileOnly || (isTablet && mobileOrientation.isPortrait);
    const [crewNotes, setCrewNotes] = React.useState<Map<string,CrewNote|undefined>>(new Map());
    const [tempCrewNotes, setTempCrewNotes] = React.useState<Map<string,CrewNote|undefined>>(new Map());
    const [featuredCrew, setFeaturedCrew] = React.useState<(NamerCrew|BBPodcast)[]|undefined>(undefined);
    const [tempFeaturedCrew, setTempFeaturedCrew] = React.useState<(NamerCrew|BBPodcast)[]|undefined>(undefined);
    const [error, setError] = React.useState<string|undefined>(undefined);
    const [days, setDays] = React.useState<number>(14);
    const [ongoingEventLeaderboard, setOngoingEvent] = React.useState<EventLeaderboard|undefined>(undefined);

    React.useEffect(() => {
        props.navigation();
        getCrew().then((data) => getPodcasts().then((pods) => {
            if (data && pods) {
                let tempData: (NamerCrew|BBPodcast)[] = [];

                let timestamp = new Date();
                timestamp.setDate(timestamp.getDate() - days);
                let limiteddata = data.filter(x=>x.date > timestamp);
                tempData = [].concat(limiteddata, pods.filter(x=>x.date > timestamp));                
                tempData.sort((a,b)=>{
                    if (('symbol' in a) && ('symbol' in b))  {
                        return a.date.getTime() == b.date.getTime() ? b.rarity-a.rarity : (a.date < b.date ? 1 : -1);
                    }
                    return a.date < b.date ? 1 : -1
                });
                setTempFeaturedCrew(tempData);
                getOngoingLeaderboard().then((l)=>{
                        if (l && l.event) {
                            setOngoingEvent(l);
                        }
                })
                getCrewNotes(limiteddata.map(x=>x.symbol)).then((data)=>setTempCrewNotes(data));
            } else {
                setError("There are no featured crew at this time.");
            }
        })).catch(() => setError("There are no featured crew at this time."));
    }, []);

    React.useEffect(() => {
        if (tempCrewNotes && tempFeaturedCrew && tempFeaturedCrew.length > 0) {
            setCrewNotes(tempCrewNotes);
            setFeaturedCrew(tempFeaturedCrew);
        }
    }, [tempCrewNotes, tempFeaturedCrew]);

    if (featuredCrew == undefined) {
        return <Container>
            {error ? <div className="center">{error}</div> : <div className="center ui active loader"/>}
        </Container>;
    }

    return (
    <div>
        <ScrollUpButton />
        <Grid stackable>
            {false && props.livestream &&<React.Fragment>
                <Grid.Row centered>
                    <div className='centerdiv'>
                        <ReactTwitchEmbedVideo channel="trektime" muted={true} layout="video" height={isMobileOnly?200:400} width={isMobileOnly?355:710}/>
                    </div>
                </Grid.Row>
                <Divider/>
            </React.Fragment>}
            {ongoingEventLeaderboard && <React.Fragment>
                <GridRow centered>
                    <Link className="animate" to={"/event/"+ongoingEventLeaderboard.id}
                        onClick={()=>logEvent("EventLink", "event", ongoingEventLeaderboard.id.toString())}>
                            {getEventHeader(ongoingEventLeaderboard.event, isMinimal, ongoingEventLeaderboard)}
                    </Link>
                </GridRow>
                {isMobileOnly && <Divider/>}
            </React.Fragment>}
            {/* {false && events && <GridRow centered>
                <FeaturedEvent event={events[0]}/>
            </GridRow>} */}
            {featuredCrew.map((crew, idx) => {
                if ('symbol' in crew) {
                    let primary : FeaturedCrewSkillInfo = {
                        skill: crew.skills[0] as FeaturedCrewSkill,
                        base: Math.floor(crew.baseScore[crew.skills[0]]),
                        min: Math.floor(crew.gMinBySkill[crew.skills[0]]),
                        max: Math.floor(crew.gMaxBySkill[crew.skills[0]])
                    };
                    let rawPrimary : FeaturedCrewSkillInfo = {
                        skill: crew.skills[0] as FeaturedCrewSkill,
                        base: Math.floor(crew.baseRaw[crew.skills[0]]),
                        min: Math.floor(crew.gRawMinBySkill[crew.skills[0]]),
                        max: Math.floor(crew.gRawMaxBySkill[crew.skills[0]])
                    };
                    let secondary : FeaturedCrewSkillInfo = crew.skills.length > 1 ? {
                        skill: crew.skills[1] as FeaturedCrewSkill,
                        base: Math.floor(crew.baseScore[crew.skills[1]]),
                        min: Math.floor(crew.gMinBySkill[crew.skills[1]]),
                        max: Math.floor(crew.gMaxBySkill[crew.skills[1]])
                    } : undefined;
                    let rawSecondary : FeaturedCrewSkillInfo = crew.skills.length > 1 ? {
                        skill: crew.skills[1] as FeaturedCrewSkill,
                        base: Math.floor(crew.baseRaw[crew.skills[1]]),
                        min: Math.floor(crew.gRawMinBySkill[crew.skills[1]]),
                        max: Math.floor(crew.gRawMaxBySkill[crew.skills[1]])
                    } : undefined;
                    let tertiary : FeaturedCrewSkillInfo = crew.skills.length > 2 ? {
                        skill: crew.skills[2] as FeaturedCrewSkill,
                        base: Math.floor(crew.baseScore[crew.skills[2]]),
                        min: Math.floor(crew.gMinBySkill[crew.skills[2]]),
                        max: Math.floor(crew.gMaxBySkill[crew.skills[2]])
                    } : undefined;
                    let rawTertiary : FeaturedCrewSkillInfo = crew.skills.length > 2 ? {
                        skill: crew.skills[2] as FeaturedCrewSkill,
                        base: Math.floor(crew.baseRaw[crew.skills[2]]),
                        min: Math.floor(crew.gRawMinBySkill[crew.skills[2]]),
                        max: Math.floor(crew.gRawMaxBySkill[crew.skills[2]])
                    } : undefined;

                    return <FeaturedCrew
                        index={idx}
                        key={crew.symbol}
                        symbol={crew.symbol}
                        name={crew.name}
                        rarity={crew.rarity}
                        image={crew.fullBody}
                        portrait={crew.portrait}
                        background={crew.background}
                        leftImage={idx%2==0}
                        primary={primary}
                        secondary={secondary}
                        tertiary={tertiary}
                        rawPrimary={rawPrimary}
                        rawSecondary={rawSecondary}
                        rawTertiary={rawTertiary}
                        note={crewNotes.get(crew.symbol)?.note}
                        noteAuthor={crewNotes.get(crew.symbol)?.author}
                        noteDate={crewNotes.get(crew.symbol)? (crewNotes.get(crew.symbol).modified ? new Date(crewNotes.get(crew.symbol).modified) : undefined) : undefined}
                        video={crewNotes.get(crew.symbol)?.videoLink}
                        audio={crewNotes.get(crew.symbol)?.audioLink}
                        traits={crew.traitsNamed}
                        collections={crew.collections.length > 0 ? crew.collections : undefined}
                        tier={crew.tier}
                        event={crew.eRating}
                        eventR={crew.eRatingR}
                        voyage={crew.vNRating}
                        voyageR={crew.vNRank}
                        gauntlet={crew.gRating}
                        gauntletR={crew.gNRank}
                        shuttle={crew.bRating}
                        shuttleR={crew.bRatingRank}
                        coll={crew.cNFinalRating}
                        collR={crew.cNFinalRank}
                        arena={crew.aRating}
                        arenaR={crew.aRank}
                        crMentions={crew.crMention}
                        crNow={crew.crNow}
                        crLater={crew.crLater}
                        date={crew.date}
                        obtained={crew.obtained}
                        portal={false}
                        nicknames={crew.nicknames}
                        showAd={idx%3==0}
                        ftm_name={crew.ftm_name}
                        ftm_date={crew.ftm_date}
                    />
                } else {
                    return <React.Fragment>
                        <Grid.Row centered>
                            <Podcast pod={crew as BBPodcast} divider={true} expanded={!isMobileOnly}/>
                        </Grid.Row>
                        <Divider/>
                    </React.Fragment>
                }
            }
        )}
        </Grid>
        <div className="loadMore">
            <Button basic circular icon labelPosition='left' onClick={()=>{
                let newDays = days + 7;
                getCrew().then(data=>getPodcasts().then((pods) =>{
                    if (!data || !pods) {
                        return;
                    }
                    let timestamp1 = new Date();
                    let timestamp2 = new Date();
                    timestamp1.setDate(timestamp1.getDate() - days);
                    timestamp2.setDate(timestamp2.getDate() - newDays);
                    setDays(newDays);
                    
                    let newPods = pods.filter(x => x.date > timestamp2 && x.date < timestamp1);
                    let newData2: (NamerCrew|BBPodcast)[] = [];
                    newData2 = [].concat(data.filter(x => x.date > timestamp2 && x.date < timestamp1), newPods);
                    newData2.sort((a,b)=>{

                        if (('symbol' in a) && ('symbol' in b))  {
                            return a.date.getTime() == b.date.getTime() ? b.rarity-a.rarity : (a.date < b.date ? 1 : -1);
                        }
                        return a.date < b.date ? 1 : -1
                    });

                    const allData = featuredCrew.concat(newData2);

                    getCrewNotes((allData.filter(x=>('symbol' in x)) as NamerCrew[]).map(x=>x.symbol)).then((data)=>{
                        setCrewNotes(data);
                        setFeaturedCrew(allData);
                    });
                }));
            }}>
                <Icon name='angle double down'/>
                Load more
            </Button>
        </div>

        <Footer/>
    </div>
    );
}

export default Featured;