import React from "react";
import { Helmet } from 'react-helmet';
import { BBPodcast, getPodcasts } from "../services/NamerStats";
import { Container, Grid } from "semantic-ui-react";
import Podcast from "./Podcast";
import { Footer } from "./Footer";
import { isMobileOnly } from "react-device-detect";

export const PodcastList = (props: {
    navigation: () => void;
}) => {
    let expandedPodcast = undefined;
    let urlParams = new URLSearchParams(window.location.search);
	if (urlParams.has('ep')) {
		expandedPodcast = urlParams.get('ep') ?? "";
	}

    const [pods, setPods] = React.useState<BBPodcast[]|undefined>(undefined);
    const [error, setError] = React.useState<string|undefined>(undefined);
    const [expandedPodID, setExpandedPodID] = React.useState<number|undefined>(expandedPodcast);
    
    React.useEffect(() => {
        props.navigation();
        getPodcasts().then((data)=>{
            if (data) {
                data.sort((a,b)=>a.date < b.date ? 1 : -1);
                setPods(data);
            }
        }).catch((error)=>setError(error));
    }, []);

    if (pods == undefined) {
        return <Container>
            {error ? <div className="center">{error}</div> : <div className="center ui active loader"/>}
        </Container>;
    }    

    const title = "Big Book Podcast";
    const link = "https://www.bigbook.app/podcast";
    const description = "The Big Book Podcast dives into the nitty gritty of crew in the game of Star Trek Timelines. Join Automaton_2000, Nifty Mittens, Jenos Idanian, Frank and Dangevin in the #1 Star Trek Timelines podcast.";

    return <div>
        <Helmet>
            <script
                src="https://apis.google.com/js/platform.js"
                crossOrigin="anonymous"
                async
            ></script>
            <title>{title}</title>
            <link rel="canonical" href={link} />
            <meta name="description" content={description}/>
            <meta property="og:url" content={link}/>
            <meta property="og:description" content={description}/>
            <meta name="twitter:url" content={link}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
        </Helmet>
        <div className="podsH">
            <h1 className="podsTitle">Big Book Podcast</h1>
            <div className="podsSub">
                <div className="g-ytsubscribe" data-channelid="UCzIxzjAFlCFrHLpaSRGc5pQ" data-layout="default" data-theme="dark" data-count="default"/>
            </div>
            <p className="podsDesc">Dive into the nitty gritty of crew in the game of Star Trek Timelines. Join Automaton_2000, Dangevin, Frank, Jenos Idanian and Nifty Mittens in this #1 Star Trek Timelines podcast.</p>
        </div>
        <br/>
        <Grid stackable centered={true} padded relaxed>
            {pods.map(x=><Podcast key={x.id} pod={x} expanded={x.id==expandedPodID} expandedChanged={(id)=>setExpandedPodID(id)}/>)}
        </Grid>
        <Footer/>
    </div>
}

export default PodcastList;