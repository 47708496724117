import '../../assets/fomantic/dist/semantic.css';
import { List, Table } from 'semantic-ui-react';
import React from 'react';
import AdSense from 'react-adsense';

import '../../App.css';
import { NamerCrew } from '../../services/NamerStats';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';

export const ShuttleStats = (props: {
    crew: NamerCrew;
    showAd?: boolean;
}) => {
    const isOnlyMobile = isMobile && !isTablet;
    let crew = props.crew;

    return (
        <List divided relaxed>
            <List.Item>
                <List.Icon name='chart bar' size='large' verticalAlign='top' />
                <List.Content>
                    <List.Header>Base stats</List.Header>
                    {!isOnlyMobile && <Table textAlign="center" definition basic='very' compact celled collapsing style={{"backgroundColor":"transparent"}}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{"color":"white", "backgroundColor":"transparent"}}></Table.HeaderCell>
                                <Table.HeaderCell>{crew.skills[0]}</Table.HeaderCell>
                                {crew.skills.length > 1 && <Table.HeaderCell>{crew.skills[1]}</Table.HeaderCell>}
                                {crew.skills.length > 2 && <Table.HeaderCell>{crew.skills[2]}</Table.HeaderCell>}
                                {crew.skills.length > 1 && <Table.HeaderCell>{crew.skills[0]}/{crew.skills[1]}</Table.HeaderCell>}
                                {crew.skills.length > 2 &&<Table.HeaderCell>{crew.skills[0]}/{crew.skills[2]}</Table.HeaderCell>}
                                {crew.skills.length > 2 &&<Table.HeaderCell>{crew.skills[1]}/{crew.skills[2]}</Table.HeaderCell>}
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            <Table.Row>
                                <Table.Cell style={{"color":"white"}}>rank</Table.Cell>
                                {crew.skills.map((skill, sidx) =>
                                    <Table.Cell key={skill}>#{crew.bRank[skill]}</Table.Cell>
                                )}
                                {crew.skills.length > 1 && <Table.Cell>#{crew.bPRank[0]}</Table.Cell>}
                                {crew.skills.length > 2 && <Table.Cell>#{crew.bPRank[1]}</Table.Cell>}
                                {crew.skills.length > 2 && <Table.Cell>#{crew.bPRank[2]}</Table.Cell>}
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell style={{"color":"white"}}>base</Table.Cell>
                                {crew.skills.map((skill, sidx) =>
                                    <Table.Cell key={skill}>{Math.floor(crew.baseScore[skill])}</Table.Cell>
                                )}
                                {crew.skills.length > 1 && <Table.Cell>{crew.bPScore[0]}</Table.Cell>}
                                {crew.skills.length > 2 && <Table.Cell>{crew.bPScore[1]}</Table.Cell>}
                                {crew.skills.length > 2 && <Table.Cell>{crew.bPScore[2]}</Table.Cell>}
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell style={{"color":"white"}}>success</Table.Cell>
                                {crew.skills.map((skill, sidx) =>
                                    <Table.Cell key={skill}>{crew.bSuccess[skill]}%</Table.Cell>
                                )}
                                {crew.skills.length > 1 && <Table.Cell>{crew.bPSuccess[0]}%</Table.Cell>}
                                {crew.skills.length > 2 && <Table.Cell>{crew.bPSuccess[1]}%</Table.Cell>}
                                {crew.skills.length > 2 && <Table.Cell>{crew.bPSuccess[2]}%</Table.Cell>}
                            </Table.Row>
                        </Table.Body>
                    </Table>}
                </List.Content>
            </List.Item>
            {isOnlyMobile && <table className="mobile-bases" style={{"backgroundColor":"transparent"}}>
                <thead>
                    <tr>
                        <td style={{"color":"white", "backgroundColor":"transparent"}}></td>
                        <td>rank</td>
                        <td>base</td>
                        <td>%</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>{crew.skills[0]}</th>
                        <th>#{crew.bRank[crew.skills[0]]}</th>
                        <th>{Math.floor(crew.baseScore[crew.skills[0]])}</th>
                        <th>{crew.bSuccess[crew.skills[0]]}%</th>
                    </tr>
                    {crew.skills.length > 1 && <tr>
                        <th>{crew.skills[1]}</th>
                        <th>#{crew.bRank[crew.skills[1]]}</th>
                        <th>{Math.floor(crew.baseScore[crew.skills[1]])}</th>
                        <th>{crew.bSuccess[crew.skills[1]]}%</th>
                    </tr>}
                    {crew.skills.length > 2 && <tr>
                        <th>{crew.skills[2]}</th>
                        <th>#{crew.bRank[crew.skills[2]]}</th>
                        <th>{Math.floor(crew.baseScore[crew.skills[2]])}</th>
                        <th>{crew.bSuccess[crew.skills[2]]}%</th>
                    </tr>}
                    {crew.skills.length > 1 && <tr>
                        <th>{crew.skills[0]}/{crew.skills[1]}</th>
                        <th>#{crew.bPRank[0]}</th>
                        <th>{Math.floor(crew.bPScore[0])}</th>
                        <th>{crew.bPSuccess[0]}%</th>
                    </tr>}
                    {crew.skills.length > 2 && <tr>
                        <th>{crew.skills[0]}/{crew.skills[2]}</th>
                        <th>#{crew.bPRank[1]}</th>
                        <th>{Math.floor(crew.bPScore[1])}</th>
                        <th>{crew.bPSuccess[1]}%</th>
                    </tr>}
                    {crew.skills.length > 2 && <tr>
                        <th>{crew.skills[1]}/{crew.skills[2]}</th>
                        <th>#{crew.bPRank[2]}</th>
                        <th>{Math.floor(crew.bPScore[2])}</th>
                        <th>{crew.bPSuccess[2]}%</th>
                    </tr>}
                </tbody>
            </table>}
        </List>)
  }

  export default ShuttleStats;