import { useEffect, useState, useRef } from 'react';
import { useBackground } from './BackgroundContext';

const BackgroundImageChanger = () => {
  const { backgroundImage, variant } = useBackground(); // Get the background image from context
  const [currentBackground, setCurrentBackground] = useState('');
  const [previousBackground, setPreviousBackground] = useState('');
  const [fade, setFade] = useState(false);
  const isTransitioning = useRef(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has scrolled all the way to the top
      if (window.scrollY < 20) {
        setScrolled(false);
      } else if (!scrolled) {
        setScrolled(true);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrolled) {
      const menuElement = document.querySelector('.customBG');
      menuElement?.classList.add('active'+(variant>0?variant:""));     
    } else {
      const menuElement = document.querySelector('.customBG');
      menuElement?.classList.remove('active');
      menuElement?.classList.remove('active2');
    }
  }, [scrolled]);

  useEffect(() => {
    setScrolled(false);
    const menuElement = document.querySelector('.customBG');
    menuElement?.classList.remove('active');
    menuElement?.classList.remove('active2');

    if (backgroundImage === '' && currentBackground !== '') {
      // Clear the background
      setPreviousBackground(currentBackground);
      setFade(true);

      // Start .ui.tabular.menu transition slightly after background fade begins
      //setTimeout(() => menuElement?.classList.add('active'), 100);

        setCurrentBackground('');
        setFade(false);
        isTransitioning.current = false;
    } else if (
      backgroundImage &&
      backgroundImage !== currentBackground &&
      !isTransitioning.current
    ) {
      setPreviousBackground(currentBackground);
      setFade(true);

      // Start .ui.tabular.menu transition slightly after background fade begins
      //setTimeout(() => menuElement?.classList.add('active'), 1000);

      isTransitioning.current = true; // Flag the transition as active

        setCurrentBackground(backgroundImage);
        setFade(false); // End the fade
        isTransitioning.current = false; // Reset transition flag
        //menuElement?.classList.remove('active'); // Reset after transition completes

      //return () => clearTimeout(timeout); // Clean up timeout
    }
  }, [backgroundImage, currentBackground]);

  return (
    <>
      {/* Previous Background Layer */}
      <div
        className={`background-layer${variant>0?variant:""} ${fade && previousBackground ? 'visible' : 'hidden'}`}
        style={{
          backgroundImage: previousBackground ? `url(${previousBackground})` : 'none',
        }}
      ></div>
      {/* Current Background Layer */}
      <div
        className={`background-layer${variant>0?variant:""} ${!fade && currentBackground ? 'visible' : 'hidden'}`}
        style={{
          backgroundImage: currentBackground ? `url(${currentBackground})` : 'none',
        }}
      ></div>
    </>
  );
};

export default BackgroundImageChanger;
