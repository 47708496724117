import React from 'react';
import { getAssetURL } from '../App';
import { Image } from 'semantic-ui-react';
import { Footer } from './Footer';

const NotFound = () => (
  <div className='footer center'>
    <br/>
    <h1>404 - Not Found</h1>
    <br/>
    <h3>Are you Displaced, laddie?</h3>
    <Image centered src={getAssetURL("crew_portraits_cm_scotty_tribbles_sm.png")}/>
    <br/><br/><br/>
    <Footer/>
  </div>
);

export default NotFound;