import { FeaturedCrewSkill } from "../components/FeaturedCrew";

export interface NamerCrew {
    symbol: string
    portrait: string
    fullBody: string
    background?: string
    name: string
    rarity: number
    archetype: number
    date?: Date
    ftm_name?: string
    ftm_date?: Date
    obtained?: string
    portal?: boolean
    quipment?: number[]
    legendaryQuipment: number
    nicknames?: {
      cleverThing: string
      creator?: string
    }[]
    flavor?: string
    fNote?: string
    fRank?: number
    fTier?: number
    flen?: number
    skills: string[]
    triplet: string
    skillset: string[]
    tier: number
    tierScore: number
    customTier: number
    tCustomScores: Map<string,number>
    tTotalCustomScore: number
    note: string
    crMention?: CRMention[]
    crNow?: number
    crLater?: number
    voyageScore: number
    voyageScoreBySkill: Map<string, number>
    voyageScoreByPair?: Map<string,Map<string,number>>
    voyagePercentile: number
    collections: string[]
    collection: number
    events: number
    traits: string[]
    traitsNamed: string[]
    trait: number
    hiddenTraits: string[]
    vSkillRanks: string[]
    vSeating: Map<string,SeatEval>
    vOutperfs: {
      name: string
      seats: string[]
    }[]
    vOutperfCov: number
    vAMRank?: number
    vAMChance: number
    vRank: number
    vRankR: number
    vScoreR: number
    vNRating: number
    vNRank: number
    vSetRank: number
    vTripletRank: number
    vRatio: number
    vRatioPow: number
    vSetRatio: number
    vSetRatioPow: number
    vTripletRatio: number
    vTripletRatioPow: number
    vProjections: VProjection[]
    vFirstRank: number
    gScoreBySkill: Map<string,number>
    gMinBySkill: Map<string,number>
    gMaxBySkill: Map<string,number>
    gRawMinBySkill: Map<string, number>
    gRawMaxBySkill: Map<string, number>
    gScore: number
    gNRank: number
    gRating: number
    gCritCount: number[]
    gTopPairs: GTopPair[]
    gMentions?: string[]
    gPairMentions?: string[]
    gSp: string[]
    gS: string[]
    gA: string[]
    gB: string[]
    gC: string[]
    gD: string[]
    gF: string[]

    baseScore: Map<string, number>
    baseRaw: Map<string, number>
    bRank: Map<string,number>
    bSuccess: Map<string,number>
    bPScore: number[]
    bPRank: number[]
    bPSuccess: number[]
    bRating: number
    bRatingRank: number
    bRatingR: number
    bRatingRRank: number
    bMentions: string[]

    eRating: number
    eRatingR: number
    eLog: {
      id: number
      f?: boolean
      v?: boolean
      t?: string
    }[]
    eCounts: Map<string,number>
    eTraits: string[]
    eTraitP: number[]
    eTraitR: number[]
    eBRanks: string[][]
    cNFinalRating: number
    cNFinalRank: number
    cNRating: number
    cNRatingR: number
    cCount: number[]
    cNPotentialRating: number
    cNPotentialRank: number
    cNPotentialTraits: string[]
    cStats: number
    cCrew: number
    cVanity: number
    cNPotentialCount: number
    aRating: number
    aRank: number
    aBoost: number
    aPhases: number
    aAbAmount: number
    aSearch: string[]
    ship_battle: {
      accuracy: number
      crit_bonus: number
      crit_chance: number
      evasion: number
    }
    ship_action: ShipAction
    eSuccess: Map<string,number>
    crit: number
    cmd: number
    cmdMin: number
    cmdMax: number
    dip: number
    dipMin: number
    dipMax: number
    eng: number
    engMin: number
    engMax: number
    sci: number
    sciMin: number
    sciMax: number
    sec: number
    secMin: number
    secMax: number
    med: number
    medMin: number
    medMax: number
    total: number
    totalMin: number
    totalMax: number
    priSec: number
    priTer: number
    secTer: number
    factions: number
    factgal: number
    galaxies: number
  }

  export interface Quipment {
    id: number
    symbol: string
    name: string
    icon: string
    flavor?: string
    rarity: number
    bases: Map<string,number>
    minProfs: Map<string,number>
    maxProfs: Map<string, number>
    duration: number
    max_rarity_requirement: number
    traits_requirement_operator?: string
    traits_requirement?: string[]
    traits_named?: string[]

    totalBases: number
    totalMinProfs: number
    totalMaxProfs: number
    totalProfs: number
    totalRoll: number
    rolls: Map<string, number>
    cmd: number
    dip: number
    eng: number
    sci: number
    med: number
    sec: number

    voyage: boolean
    gauntlet: boolean
    shuttle: boolean
  }

  export interface ShipAction {
    bonus_amount: number
    name: string
    symbol: string
    cooldown: number
    initial_cooldown: number
    duration: number
    uptime: number
    cycle: number
    bonus_type: number
    crew_archetype_id?: string
    special: boolean
    ability?: {
      amount: number
      type: number
      condition?: number
    }
    status?: number
    charge_phases?: {
      ability_amount: number
      bonus_amount: number
      penalty_amount: number
      charge_time: number
      cooldown: number
      duration: number
    }[]
    limit?: number
    penalty?: {
      amount: number
      type: number
    }
  }

  export interface ShipInfo {
    aSearch: string[]
    dps: number
    crit: number
    attack10: number
    cAttack: number
    cAccuracy: number
    cEvasion: number

    archetype_id: number
    symbol: string
    name: string
    rarity: number
    icon: {
      file: string
    }
    flavor: string
    model: string
    max_level: number
    actions: ShipAction[]
    shields: number
    hull: number
    attack: number
    evasion: number
    accuracy: number
    crit_chance: number
    crit_bonus: number
    attacks_per_second: number
    shield_regen: number
    traits: string[]
    traits_hidden: string[]
    antimatter: number
    schematic_id: number
    schematic_icon: {
      file: string
    }
    battle_stations: {
      skill: string
    }[]
  }

  export interface EventInfo {
    name: string
    start: Date
    type: string
    id?: number
    motd?: string
    description?: string
  }

  export interface BBCrew {
    symbol: string
    name: string
    rarity: number
    portrait: string
    tier: number
    type?: string
  }

  export interface FullEventInfo {
    id: number
    name: string
    start: Date
    description?: string
    end: Date
    featured?: BBCrew[]
    variants?: string[]
    traits?: string[]
    named_traits?: string[]
    factions?: string[]
    named_factions?: string[]
    type: string
    motd: string
    rewards?: BBCrew[]
  }

  export interface EventLeaderboard {
    id?: number
    date?: Date
    event?: EventInfo
    players?: PlayerRank[]
    fleets?: FleetRank[]
  }

  export interface PlayerRank {
    pid: number
    name: string
    vanity: string
    avatar?: string
    score: number
    rank: number
    fid?: number
    fname?: string
    fvanity?: string
    skirmishAvgPerHour?: number
    vpPerHour?: number
    macro?: boolean
  }

  export interface FleetRank {
    fid: number
    vanity: string
    name: string
    score: number
    rank: number
  }

  export interface GTopPair {
    rank: number
    pair: string
    count: number
  }

  export interface SeatEval {
    skillRanks: string[]
    ranks: Ranks
    chance: number
    nscore: number
  }
  
  export interface Ranks {
    primary: number
    primaryCount: number
    pair: number
    pairAny: number
    pairCount: number
    pairAnyCount: number
    triplet: number
    tripletAny: number
    tripletCount: number
    tripletAnyCount: number
  }
  
  export interface VProjection {
    min: number
    max: number
    avg: number
    velocity: number
  }

  export interface NamerGauntlet {
    raw: string
    topCrew: {
      symbol: string
      name: string
      rarity: number
      portrait: string
      crit: number
      score: number
      tier: string
      rolls: string[]
    }[]
    topRolls: Map<string, {
      symbol: string
      name: string
      rarity: number
      portrait: string
      crit: number
      roll: number
    }[]>
  }

  // export interface EventLeaderboard {
  //   id: number
  //   next: number
  //   prev: number
  //   nextN: string
  //   prevN: string
  //   name: string
  //   image: string
  //   type: string
  //   date: string
  //   fleets: {
  //     name: string
  //     rank: string
  //     admiral: string
  //     mcount: number
  //     mranked: number
  //   }[]
  // }

  export interface FeaturedCrewStats {
    symbol: string
    name: string
    rarity: number
    leftImg: boolean
    portrait: string
    fullBody: string
    note?: string
    bbLink?: string
    traits: string[]
    collections?: string[]
    primary: FeaturedCrewSkillInfo
    secondary?: FeaturedCrewSkillInfo
    tertiary?: FeaturedCrewSkillInfo
    rawPrimary: FeaturedCrewSkillInfo
    rawSecondary?: FeaturedCrewSkillInfo
    rawTertiary?: FeaturedCrewSkillInfo
    tier?: number
    voyage?: number
    voyageR?: number
    gauntlet?: number
    gauntletR?: number
    event?: number
    eventR?: number
    shuttle: number
    shuttleR: number
    coll: number
    collR: number
    arena: number
    arenaR: number
    crMention?: CRMention[]
    crNow?: number
    crLater?: number
    date?: Date
    obtained?: string
    nicknames?: {
      cleverThing: string
      creator?: string
    }[]
  }

  export interface CRMention {
    name: string
    symbol: string
    rarity: number
  }

  export interface FeaturedCrewSkillInfo {
    skill: FeaturedCrewSkill,
    base: number,
    min: number,
    max: number
  }
  
  export interface TierConfig {
    dist: Map<number,number>;
    scores: Map<string,{mean:number,weight:number}>
  }

  export interface Config {
    id: string
    value: Map<number, TierConfig> | Map<number, EventInfo>;
  }

  export interface CrewNote {
    id: string
    note?: string
    fNote?: string
    fTier?: number
    videoLink?: string
    audioLink?: string
    author?: string
    modified?: Date
  }

  export interface CaptainRanks {
    id: string
    vanity: string
    name: string
    ranks: number[]
    avatar: string
    fleet: string
    fleet_id?: number
    fleet_vanity?: string
    change: number
    last: Date

    overall: number
    invoverall: number
    top100: number
    r1: number
    r2: number
    r3: number
    r4: number
    r5: number
    r6: number
  }

  export interface FleetRanks {
    id: string
    vanity: string
    name: string
    ranks: number[]
    change: number
    last: Date
    score: number

    overall: number
    top100: number
    r1: number
    r2: number
    r3: number
    r4: number
    r5: number
    r6: number
    r7: number
  }

  export interface CaptainRanksSummary {
    ranks: number[]
    change: number
    last?: Date
    overall: number

    year: number
    invoverall: number
    top100: number
    r1: number
    r2: number
    r3: number
    r4: number
    r5: number
    r6: number
  }

  export interface CaptainHistory {
    name?: string
    avatar?: string
    fleet?: string
    fleet_id?: number
    fleet_vanity?: string
    summary: Map<number,CaptainRanksSummary>
    history: Map<number,number>
  }

  export interface FleetRanksSummary {
    ranks: number[]
    change: number
    last?: Date
    overall: number
    score: number

    year: number
    top100: number
    r1: number
    r2: number
    r3: number
    r4: number
    r5: number
    r6: number
    r7: number
  }

  export interface FleetHistory {
    name?: string
    summary: Map<number,FleetRanksSummary>
    history: Map<number,number>
  }  

  export interface Commendation {
    eventID: number
    eventName: string
    eventDate: string
    players: {
      pid: number
      vanity?: string
      name?: string
      avatar?: string
      fleet?: string
      fleet_id?: number
      fleet_vanity?: string
    }[]
  }

  export interface FTMer {
    name: string
    lastFTM: Date
    total: number
    totalByRarity: number[]
    r1?: number
    r2?: number
    r3?: number
    r4?: number
    r5?: number
  }

  export interface BBPodcast {
    id: number
    link: string
    title: string
    synopsis: string
    date: Date
    originaldate?: Date
    thumbnail: string
  }

export const vanityCaptainNames = new Map<string,string>([
  ["spock", "4496668"],
  ["auto", "6615736"],
  ["idol", "6260745"],
  ["frank", "1629885"],
  ["siete", "5436019"],
  ["cranky", "459172"],
  ["walt", "1677061"],
  ["nifty", "3756964"],
  ["jenos", "6131995"],
  ["dangevin", "1337776"],
  ["namer", "7487837"],
  ["graham", "1718992"],
  ["tahoe", "7371849"],
  ["brodie", "7662862"]
]);

export const vanityCaptainIDs = new Map<string,string>([
  ["4496668", "spock"],
  ["6615736", "auto"],
  ["6260745", "idol"],
  ["1629885", "frank"],
  ["5436019", "siete"],
  ["459172", "cranky"],
  ["1677061", "walt"],
  ["3756964", "nifty"],
  ["6131995", "jenos"],
  ["1337776", "dangevin"],
  ["7487837", "namer"],
  ["1718992", "graham"],
  ["7371849", "tahoe"],
  ["7662862", "brodie"]
]);

export const vanityFleetNames = new Map<string,string>([
  ["rotp", "879258244640768"],
  ["suncoast", "807761363562496"],
  ["ssr", "827113705938944"],
  ["gbp", "817665661091840"],
  ["spacejunkies", "789814651191296"],
  ["cab","754399810789376"],
  ["tta", "762604280426496"],
  ["spartacus", "785029667295232"],
  ["sparta", "858914980147201"],
]);

export const vanityFleetIDs = new Map<string,string>([
  ["879258244640768", "rotp"],
  ["807761363562496", "suncoast"],
  ["827113705938944", "ssr"],
  ["817665661091840", "gbp"],
  ["789814651191296", "spacejunkies"],
  ["754399810789376", "cab"],
  ["762604280426496", "tta"],
  ["785029667295232", "spartacus"],
  ["858914980147201", "sparta"],
]);

let namerCrew: NamerCrew[] | undefined = undefined;
let namerGauntlet: NamerGauntlet | undefined = undefined;
let leaderboard = new Map<number, EventLeaderboard>();
let onGoingLeaderboard: EventLeaderboard | undefined = undefined;
let eventInfo : Map<number, EventInfo> = undefined;
let fullEventInfo : FullEventInfo[] | undefined = undefined;
let shipInfo: Map<string, ShipInfo> = undefined;
let featureCrew: FeaturedCrewStats[] | undefined = undefined;
let tierConfig : Map<number, TierConfig> = undefined;
let crewNotes = new Map<string, CrewNote>();
let allNotes = new Map<string, CrewNote>();
let captainRanks: Map<number, CaptainRanks[]> = new Map();
let captainHistory: Map<string, CaptainHistory> = new Map();
let fleetRanks: Map<number, FleetRanks[]> = new Map();
let fleetHistory: Map<string, FleetHistory> = new Map();
let quipments: Quipment[] | undefined = undefined;
let FTMers: FTMer[] | undefined = undefined;
let commendation: Commendation = undefined;
let bbPods: BBPodcast[] | undefined = undefined;
export let knownMacro: Map<number, boolean> = new Map();

const getDataPath = (subpath: string) => {
  if (process.env.NODE_ENV === 'development') {
    return "https://bigbook.app/data/" + subpath;
  }
  return "/data/" + subpath;
} 

const getAPIPath = (subpath: string) => {
  if (process.env.NODE_ENV === 'development') {
    return "https://bigbook.app/api/" + subpath;
  }
  return "/api/" + subpath;
}

export function getCondensedStat(amount: number): number {
  if (amount <= 0) return 0;

  const condensed_to_raw = [
      0,
      1200,
      1560,
      2028,
      2636,
      3427,
      4455,
      5792,
      7530,
      9789,
      12726,
      15908,
      19885,
      24856,
      30076,
      36091,
      43309,
      51105,
      60304,
      71159,
      83968,
      97403,
      111039,
      125474,
      140531,
      155989
    ]
    for (let i=0; i<condensed_to_raw.length; i++) {
      if (condensed_to_raw[i] > amount) {
        return i-1;
      }
    }

    return condensed_to_raw.length-1;
}

export function getAttackWithBonus(base: number, amount: number): number {
  const condensedToRaw = [
    0,
    1200,
    1560,
    2028,
    2636,
    3427,
    4455,
    5792,
    7530,
    9789,
    12726,
    15908,
    19885,
    24856,
    30076,
    36091,
    43309,
    51105,
    60304,
    71159,
    83968,
    97403,
    111039,
    125474,
    140531,
    155989
  ];
  if (base >= condensedToRaw[condensedToRaw.length-1]) {
    return condensedToRaw[condensedToRaw.length-1];
  }
  for (let i=1;i<condensedToRaw.length;i++) {
    if (condensedToRaw[i]>=base) {
      const raw = condensedToRaw[Math.min(i+amount-1,condensedToRaw.length-1)] + 
        base - condensedToRaw[i-1];
        return raw;
    }
  }
}

export function getCritChance(amount: number): number {
  if (amount <= 0) return 0;
  if (amount >= 10000) return 50;

  const crit_chance_curve = [
      0,
      0,
      100,
      100,
      200,
      200,
      400,
      400,
      800,
      900,
      1200,
      1600,
      1800,
      2400,
      2400,
      3000,
      3000,
      3425,
      3600,
      3800,
      4800,
      4200,
      6000,
      4600,
      8000,
      4800,
      10000,
      5000
    ];
    for (let i=0; i<crit_chance_curve.length; i+=2) {
      if (crit_chance_curve[i] > amount) {
        let point = (amount - crit_chance_curve[i-2]) / (crit_chance_curve[i]-crit_chance_curve[i-2]);
        let p = Math.floor(crit_chance_curve[i-1] + point * (crit_chance_curve[i+1]-crit_chance_curve[i-1]))/100;
        //console.log("Crit:", amount, "%:", p, "point:", point);
        return p;
      }
    }
}

// export async function getLivestreamStatus(): Promise<boolean|undefined> {
//   try {
//       const response = await fetch('/api/stream');
//       const responseJson = await response.json();
//       if (responseJson && responseJson == true) {
//         return true;
//       }
//       return false;
//   } catch (error) {
//       console.error(error);
//   }
// }

// export async function getLivestreamLink(): Promise<string|undefined> {
//   try {
//     const response = await fetch(`https://youtube.com/channel/@TrekTime/live`)
//     const text = await response.text()
//     const html = parse(text)
//     const canonicalURLTag = html.querySelector('link[rel=canonical]')
//     const canonicalURL = canonicalURLTag.getAttribute('href')
//     const isStreaming = canonicalURL.includes('/watch?v=')
//     if (isStreaming) {
//         return canonicalURL.substring(9 + canonicalURL.indexOf('/watch?v='))
//     }
//     return undefined;
//   } catch (e) {
//     console.log(e)
//   }
// }

export async function getCrew(): Promise<NamerCrew[]|undefined> {
    if (namerCrew) {
      return namerCrew;
    }
    try {
        const url = getDataPath("crew.json");
        const response = await fetch(url);
        const responseJson = await response.json();
        if (responseJson && Array.isArray(responseJson)) {
          responseJson.forEach(x=>x.triplet = x.skills.join("/"));
          namerCrew = responseJson;
        }
        namerCrew.forEach(x=>{
          if (x.date) x.date = new Date(x.date);
        })
        return namerCrew;
    } catch (error) {
        console.error(error);
    }
}

export async function getFTMers(): Promise<FTMer[]|undefined> {
  if (FTMers) {
    return FTMers;
  }
  try {
      const url = getDataPath("ftm.json");
      const response = await fetch(url);
      const responseJson = await response.json();
      if (responseJson && Array.isArray(responseJson)) {
        FTMers = responseJson;
      }
      return FTMers;
  } catch (error) {
      console.error(error);
  }
}

export async function getAllCrewNotes(): Promise<Map<string,CrewNote>|undefined> {
  if (allNotes && allNotes.size > 0) {
    return allNotes;
  }
  try {
      const url = getDataPath("crew-notes.json");
      const response = await fetch(url);
      const responseJson = await response.json();
      if (responseJson && Array.isArray(responseJson)) {
        responseJson.forEach(x=>allNotes.set(x.id, x));
      }
      return allNotes;
  } catch (error) {
      console.error(error);
  }
}

export async function getCrewNotes(symbols: string[]): Promise<Map<string,CrewNote>|undefined> {
  let query = crewNotes && crewNotes.size > 0 ? [] : symbols;
  let result = new Map<string,CrewNote>();
  if (crewNotes && crewNotes.size > 0) {
    symbols.forEach(x=>{
      if (crewNotes.has(x)) result.set(x, crewNotes.get(x));
      else query.push(x);
    });
  }
  if (query.length != 0) {
    try {
        const url = getAPIPath("notes/"+query.join("+"));
        const response = await fetch(url);
        const responseJson = await response.json();
        if (responseJson && Array.isArray(responseJson)) {
          let tempMap = new Map<string,CrewNote>();
          responseJson.forEach(x=>{crewNotes.set(x.id, x);tempMap.set(x.id, x);});
          return new Map([...result, ...tempMap]);
        }
    } catch (error) {
        console.error(error);
    }
  } else {
    return result;
  }
}

export async function getCrewNote(id: string): Promise<CrewNote|undefined> {
  if (crewNotes && crewNotes.has(id)) {
    return crewNotes.get(id);
  }
  try {
    const url = getAPIPath('notes/'+id);
    const response = await fetch(url);
    const responseJson = await response.json();
    if (responseJson && Array.isArray(responseJson) && responseJson.length == 1) {
      crewNotes.set(responseJson[0].id, responseJson[0]);
      return responseJson[0];
    }
  } catch (error) {
      console.error(error);
  }
}

// export async function getFeaturedCrew(): Promise<FeaturedCrewStats[]|undefined> {
//   if (featureCrew) {
//     return featureCrew;
//   }
//   try {
//       const response = await fetch('https://raw.githubusercontent.com/marcelgcom/nnstats-data/main/featuredCrew.json');
//       const responseJson = await response.json();
//       if (responseJson && Array.isArray(responseJson)) {
//         featureCrew = responseJson;
//       }
//       return responseJson;
//   } catch (error) {
//       console.error(error);
//   }
// }

export async function getGauntlet(raw?: string): Promise<NamerGauntlet|undefined> {
  try {
      if (namerGauntlet && !raw) {
        return namerGauntlet;
      }
      if (raw) {
        raw = raw.toLowerCase();
        if (namerGauntlet && namerGauntlet.raw == raw) {
          return namerGauntlet;
        }
        let response = await fetch(getDataPath('gauntlets/'+raw+'.json'));
        let responseJson = await response.json();

        return responseJson;
      }
      //text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9
      const url = getAPIPath('gauntlet');
      let response = await fetch(url);
      let responseJson = await response.json();
      if (responseJson) {
        response = await fetch(getDataPath('gauntlets/'+responseJson.raw+'.json'));
        responseJson = await response.json();
        if (responseJson) {
          namerGauntlet = responseJson;
        }
        return responseJson;
      }
  } catch (error) {
      console.error(error);
  }
}

export async function getOngoingLeaderboard(fresh?: boolean): Promise<EventLeaderboard|undefined> {
  if (onGoingLeaderboard && !fresh) {
    return onGoingLeaderboard;
  }
  try {
      const url = getAPIPath('leaderboard');
      const response = await fetch(url);
      onGoingLeaderboard = await response.json();
      onGoingLeaderboard.players.forEach(x=>{
        x.vanity = vanityCaptainIDs.get(x.pid.toString())
        if (x.fid) x.fvanity = vanityFleetIDs.get(x.fid.toString());
      });
      onGoingLeaderboard.fleets.forEach(x=>{
        x.vanity = vanityFleetIDs.get(x.fid.toString());
      });
      return onGoingLeaderboard;
  } catch (error) {
      console.error(error);
  }
}

export async function getPodcasts(): Promise<BBPodcast[]|undefined> {
  const addDays = (date: Date, days: number) => {
      date.setDate(date.getDate() + days);
      return date;
  }

  if (bbPods) {
    return bbPods;
  }
  try {
      const url = getAPIPath('podcasts');
      const response = await fetch(url);
      bbPods = await response.json();
      bbPods.forEach(x=>{
        if (x.date) {
          x.originaldate = new Date(x.date);
          x.date = addDays(new Date(x.originaldate), 7);
        }
      })
      return bbPods;
  } catch (error) {
      console.error(error);
  }
}

export async function getLeaderboard(eventID?: number): Promise<EventLeaderboard|undefined> {
  if (leaderboard[eventID]) {
    return leaderboard[eventID];
  }
  try {
      if (!eventID) {
        eventID = 0;
      }
      const response = await fetch(getDataPath("leaderboards/"+eventID+".json"));
      const responseJson = await response.json();
      if (responseJson) {
        responseJson.players.forEach(x=>{
          x.vanity = vanityCaptainIDs.get(x.pid.toString())
          if (x.fid) x.fvanity = vanityFleetIDs.get(x.fid.toString());
        });
        responseJson.fleets.forEach(x=>{
          x.vanity = vanityFleetIDs.get(x.fid.toString());
        });
        leaderboard[eventID] = responseJson;
      }
      return responseJson;
  } catch (error) {
      console.error(error);
  }
}

export async function getEventInfo(): Promise<Map<number,EventInfo>|undefined> {
  if (eventInfo) {
    return eventInfo;
  }
  try {
      const response = await fetch(getDataPath("events.json"));
      const responseJson = await response.json();
      if (responseJson) {
          eventInfo = responseJson;
      }
      return eventInfo;
  } catch (error) {
      console.error(error);
  }
}

export async function getFullEventInfo(): Promise<FullEventInfo[]|undefined> {
  if (fullEventInfo) {
    return fullEventInfo;
  }
  try {
      const response = await fetch(getDataPath("events_full.json"));
      const responseJson = await response.json();
      if (responseJson) {
        fullEventInfo = responseJson;
        fullEventInfo.sort((a,b)=>(b.id-a.id));
        fullEventInfo.forEach(x=>{
          for (let i=0; i<(x.rewards?.length ?? 0); i++) {
            if (i>0) {
              x.rewards[i].type = "threshold"
            } else {
              x.rewards[i].type = "ranked"
            }
          }
          // for (let i=0; i<(x.featured?.length ?? 0); i++) {
          //   if (x.featured[i].type == "recurring") {
          //     (x.rewards ?? []).push(x.featured[i]);
          //   }
          // }
        })
      }
      return fullEventInfo;
  } catch (error) {
      console.error(error);
  }
}

export async function getShipInfo(): Promise<Map<string,ShipInfo>|undefined> {
  if (shipInfo) {
    return shipInfo;
  }
  try {
      const response = await fetch(getDataPath("ships.json"));
      const responseJson = await response.json();
      if (responseJson) {
        shipInfo = responseJson;
      }
      return shipInfo;
  } catch (error) {
      console.error(error);
  }
}

export async function getTierConfig(): Promise<Map<number,TierConfig>|undefined> {
  if (tierConfig) {
    return tierConfig;
  }
  try {
      const response = await fetch(getDataPath("tiers.json"));
      const responseJson = await response.json();
      if (responseJson) {
          tierConfig = responseJson;
      }
      return tierConfig;
  } catch (error) {
      console.error(error);
  }
}

export async function getCaptainRanks(year: number): Promise<CaptainRanks[]|undefined> {
  if (captainRanks && captainRanks.has(year)) {
    return captainRanks.get(year);
  }
  try {
      const response = await fetch(getDataPath('captains/captainRankings'+(year?year:'')+'.json'));
      const responseJson = await response.json();
      if (responseJson) {
        // let data = [];
        // if (year) {
        //   data = Array.from(Object.values(responseJson.value as Map<number,CaptainRanks>));
        // } else {
        let data = responseJson;
        // }
        let lastRank = 1;
        data.sort((a,b)=>b.ranks[0]-a.ranks[0]||b.ranks[1]-a.ranks[1]||b.ranks[2]-a.ranks[2]||b.ranks[3]-a.ranks[3]||b.ranks[4]-a.ranks[4]||b.ranks[5]-a.ranks[5]||(b.name>a.name?1:-1))
          .forEach((x,idx)=>{
              if (x.ranks && x.ranks.length > 0) {
                  x.r1 = x.ranks[0];
                  x.r2 = x.ranks[1];
                  x.r3 = x.ranks[2];
                  x.r4 = x.ranks[3];
                  if (x.ranks.length > 4) {
                    x.r5 = x.ranks[4]
                    if (x.ranks.length > 5) {
                      x.r6 = x.ranks[5]
                    }
                  }
                  x.top100 = x.r1 + x.r2 + x.r3 + x.r4 + x.r5 + x.r6;
              }
              if (idx > 0) {
                for (let i=0; i<data[idx-1].ranks.length;i++) {
                  if (data[idx-1].ranks[i] != data[idx].ranks[i]) {
                    lastRank = idx+1;
                    break;
                  }
                }
              }
              x.overall = lastRank;
              x.invoverall = 10000000 - x.overall;
              x.vanity = vanityCaptainIDs.get(x.id);
              if (x.fleet_id) x.fleet_vanity = vanityFleetIDs.get(x.fleet_id.toString());
          });
          captainRanks.set(year, data);
          return data;
      }
      return responseJson;
  } catch (error) {
      console.error(error);
  }
}

export async function getFleetRanks(year: number): Promise<FleetRanks[]|undefined> {
  if (fleetRanks && fleetRanks.has(year)) {
    return fleetRanks.get(year);
  }
  try {
      const response = await fetch(getDataPath('fleets/fleetRankings'+(year?year:'')+'.json'));
      const responseJson = await response.json();
      if (responseJson) {
        // let data = [];
        // if (year) {
        //   data = Array.from(Object.values(responseJson.value as Map<number,CaptainRanks>));
        // } else {
        let data = responseJson;
        // }
        let lastRank = 1;
        data.sort((a,b)=>b.ranks[0]-a.ranks[0]||b.ranks[1]-a.ranks[1]||b.ranks[2]-a.ranks[2]||b.ranks[3]-a.ranks[3]||b.ranks[4]-a.ranks[4]||b.ranks[5]-a.ranks[5]||(b.name>a.name?1:-1))
          .forEach((x,idx)=>{
              if (x.ranks && x.ranks.length > 0) {
                  x.r1 = x.ranks[0];
                  x.r2 = x.ranks[1];
                  x.r3 = x.ranks[2];
                  x.r4 = x.ranks[3];
                  if (x.ranks.length > 4) {
                    x.r5 = x.ranks[4]
                    if (x.ranks.length > 5) {
                      x.r6 = x.ranks[5]
                      if (x.ranks.length > 6) {
                        x.r7 = x.ranks[6]
                      }
                    }
                  }
                  x.top100 = x.r1 + x.r2 + x.r3 + x.r4 + x.r5 + x.r6 + x.r7;
              }
              if (idx > 0) {
                for (let i=0; i<data[idx-1].ranks.length;i++) {
                  if (data[idx-1].ranks[i] != data[idx].ranks[i]) {
                    lastRank = idx+1;
                    break;
                  }
                }
              }
              x.overall = lastRank;
              x.vanity = vanityFleetIDs.get(x.id.toString());
          });
          fleetRanks.set(year, data);
          return data;
      }
      return responseJson;
  } catch (error) {
      console.error(error);
  }
}

export async function getCaptainHistory(pid: string): Promise<CaptainHistory|undefined> {
  if (captainHistory && captainHistory.has(pid)) {
      return captainHistory.get(pid);
  }
  try {
      const response = await fetch(getDataPath('captains/'+pid+'.json'));
      const data = await response.json();
      if (data) {
          Object.keys(data.summary).forEach(year=>{
              let x = data.summary[year];
              if (x.ranks && x.ranks.length > 0) {
                  x.r1 = x.ranks[0];
                  x.r2 = x.ranks[1];
                  x.r3 = x.ranks[2];
                  x.r4 = x.ranks[3];
                  if (x.ranks.length > 4) {
                    x.r5 = x.ranks[4]
                    if (x.ranks.length > 5) {
                      x.r6 = x.ranks[5]
                    }
                  }
                  x.top100 = x.r1 + x.r2 + x.r3 + x.r4 + x.r5 + x.r6;
                  if (year == '0') year = '2100';
                  x.year = year;
              }
              x.invoverall = 10000000 - x.overall;
          });
          //data.vanity = vanityCaptainIDs.get(pid)
          if (data.fleet_id) data.fleet_vanity = vanityFleetIDs.get(data.fleet_id.toString()); 
          captainHistory.set(pid, data);
          return data;
      }
  } catch (error) {
    console.error(error);
  }
}

export async function getFleetHistory(fid: string): Promise<FleetHistory|undefined> {
  if (fleetHistory && fleetHistory.has(fid)) {
      return fleetHistory.get(fid);
  }
  try {
      const response = await fetch(getDataPath('fleets/'+fid+'.json'));
      const data = await response.json();
      if (data) {
          Object.keys(data.summary).forEach(year=>{
              let x = data.summary[year];
              if (x.ranks && x.ranks.length > 0) {
                  x.r1 = x.ranks[0];
                  x.r2 = x.ranks[1];
                  x.r3 = x.ranks[2];
                  x.r4 = x.ranks[3];
                  if (x.ranks.length > 4) {
                    x.r5 = x.ranks[4]
                    if (x.ranks.length > 5) {
                      x.r6 = x.ranks[5]
                      if (x.ranks.length > 6) {
                        x.r7 = x.ranks[6]
                      }
                    }
                  }
                  x.top100 = x.r1 + x.r2 + x.r3 + x.r4 + x.r5 + x.r6+x.r7;
                  if (year == '0') year = '2100';
                  x.year = year;
              }
          });
          fleetHistory.set(fid, data);
          return data;
      }
  } catch (error) {
    console.error(error);
  }
}

export async function getQuipments(): Promise<Quipment[]|undefined> {
    if (quipments) {
      return quipments;
    }
    try {
        const response = await fetch(getDataPath("quipment.json"));
        const responseJson = await response.json();
        if (responseJson) {
          quipments = responseJson;
          const cSkills = ["cmd","dip","eng","med","sci","sec"]
          quipments.forEach(x=>{
            for (const skill of cSkills) {
              if (!Object.keys(x.bases).includes(skill)) x.bases[skill] = 0;
              if (!Object.keys(x.minProfs).includes(skill)) x.minProfs[skill] = 0;
              if (!Object.keys(x.maxProfs).includes(skill)) x.maxProfs[skill] = 0;
            }
          });
        }
        return quipments;
    } catch (error) {
        console.error(error);
    }
  }

export async function getCommendations(): Promise<Commendation|undefined> {
  if (commendation) {
    return commendation;
  }
  try {
      const response = await fetch(getDataPath('captains/commendations.json'));
      const responseJson = await response.json();
      if (responseJson) {
        commendation = responseJson;
        commendation.players.forEach(x=>{
          x.vanity = vanityCaptainIDs.get(x.pid.toString());
          if (x.fleet_id) x.fleet_vanity = vanityFleetIDs.get(x.fleet_id.toString());
        });
        return commendation;
      }
  } catch (error) {
      console.error(error);
  }
}

export function rarityLabel(rarity: number): string {
  switch (rarity) {
    case 5: return "legendary";
    case 4: return "super rare";
    case 3: return "rare";
    case 2: return "uncommon";
    case 1: return "common";
  }
  return "";
}

// export async function getTwitchStream(): Promise<string|undefined> {
//   try {
//     const response = await fetch("https://secure-river-56948.herokuapp.com/https://api.twitch.tv/kraken/streams/23161357");
//     const responseJson = await response.json();
//     if (responseJson && responseJson.stream && responseJson.channel) {
//       return responseJson.channel.status
//     }
//   } catch (error) {
//     console.error(error);
//   }
// }