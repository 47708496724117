import '../assets/fomantic/dist/semantic.css';
import { Grid, Container, Header } from 'semantic-ui-react';
import { navigate } from "@reach/router";
import React from 'react';

import '../App.css';
import { EventLeaderboard, getLeaderboard } from '../services/NamerStats';
import { isMobile, isTablet } from 'react-device-detect';
import { Footer } from './Footer';

export const FAQ = (props:{
    id?: number;
    navigation: () => void;
}) => {

    React.useEffect(() => {
        props.navigation();
    }, [])

    return (<div>
        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        <div className='credits'>
            <h2>Coming soon</h2>
        </div>
        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        <Footer/>
    </div>);
}

export default FAQ;