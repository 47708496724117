import '../assets/fomantic/dist/semantic.css';
import { Label, Container, Table, LabelDetail } from 'semantic-ui-react';
import { Link } from '@reach/router';
import React from 'react';
import { Helmet } from 'react-helmet';

import '../App.css';
import { NamerCrew, getCrew } from '../services/NamerStats';
import { SearchableTable, ITableConfigRow } from './SearchableTable';
import { Footer } from '../components/Footer';
import GauntletStats from './CrewStats/GauntletStats';
import { logEvent, rarityClass, showThisCrew } from '../utils/utils';
import { getAssetURL } from '../App';

export const Gauntleteers = (props: {
    navigation: () => void;
}) => {
    const [crew, setCrew] = React.useState<NamerCrew[]|undefined>(undefined);

    React.useEffect(() => {
        props.navigation();
        if (crew) {
            return;
        }
        getCrew().then((data) => {
            if (data && data.length > 0) {
                // data = data.filter(x=>x.rarity>=4);
                data.forEach(x=> {
                    x.dip = x.gScoreBySkill["dip"]
                    x.dipMin = x.gMinBySkill["dip"]
                    x.dipMax = x.gMaxBySkill["dip"]
                    x.sec = x.gScoreBySkill["sec"]
                    x.secMin = x.gMinBySkill["sec"]
                    x.secMax = x.gMaxBySkill["sec"]
                    x.sci = x.gScoreBySkill["sci"]
                    x.sciMin = x.gMinBySkill["sci"]
                    x.sciMax = x.gMaxBySkill["sci"]
                    x.eng = x.gScoreBySkill["eng"]
                    x.engMin = x.gMinBySkill["eng"]
                    x.engMax = x.gMaxBySkill["eng"]
                    x.med = x.gScoreBySkill["med"]
                    x.medMin = x.gMinBySkill["med"]
                    x.medMax = x.gMaxBySkill["med"]
                    x.cmd = x.gScoreBySkill["cmd"]
                    x.cmdMin = x.gMinBySkill["cmd"]
                    x.cmdMax = x.gMaxBySkill["cmd"]
                    x.total = 0;
                    for (const prof of Object.values(x.gScoreBySkill)) {
                        x.total += prof
                    }
                    x.total = Math.floor(x.total);
                    x.totalMin = 0;
                    for (const prof of Object.values(x.gMinBySkill)) {
                        x.totalMin += prof
                    }
                    x.totalMin = Math.floor(x.totalMin);
                    x.totalMax = 0;
                    for (const prof of Object.values(x.gMaxBySkill)) {
                        x.totalMax += prof
                    }
                    x.totalMax = Math.floor(x.totalMax);

                    x.crit = x.gCritCount[0] * 10000 + x.gCritCount[1] * 100 + x.gCritCount[2]
                    x.triplet = x.skills.join("/");
                });
                data.sort((a,b) => ((a.rarity>3&&b.rarity>3)||(a.rarity<4&&b.rarity<4)) ? (a.gNRank-b.gNRank) : (b.rarity-a.rarity));
                setCrew(data);
            }            
        });
    }, [])

    if (!crew) {
        return <Container><div className="center ui active loader"/></Container>;
    }

    const tableConfig: ITableConfigRow[] = [
        { width: 3, column: 'name', title: 'Crew', pseudocolumns: ['name', 'crit'] },
        { width: 2, column: 'toprolls', title: 'Top rolls'},
        { width: 1, column: 'gRating', title: 'Rating', class: 'row-header'},
        { width: 1, column: 'total', title: 'Total', pseudocolumns: ['totalMin', 'totalMax', 'total'], class: 'row-header'},
        { width: 1, column: 'cmd', title: <img alt='C' width='14' src={getAssetURL('icons_icon_command_skill.png')}/>, class: 'row-header'},
        { width: 1, column: 'dip', title: <img alt='D' width='14' src={getAssetURL('icons_icon_diplomacy_skill.png')}/>, class: 'row-header'},
        { width: 1, column: 'eng', title: <img alt='E' width='14' src={getAssetURL('icons_icon_engineering_skill.png')}/>, class: 'row-header'},
        { width: 1, column: 'med', title: <img alt='M' width='14' src={getAssetURL('icons_icon_medicine_skill.png')}/>, class: 'row-header'},
        { width: 1, column: 'sci', title: <img alt='Sc' width='14' src={getAssetURL('icons_icon_science_skill.png')}/>, class: 'row-header'},
        { width: 1, column: 'sec', title: <img alt='Se' width='14' src={getAssetURL('icons_icon_security_skill.png')}/>, class: 'row-header'},        
    ]

    const descriptionLabel = (crew: NamerCrew) => {
        let desc = [];
        crew.gCritCount.forEach((x, idx)=> {
            if (x > 0 && idx < 3) {
                //desc.push(x + "x " + (idx == 0 ? "65%" : (idx == 1 ? "45%" : "25%"))); 
                desc.push(<span>{(idx === 0 ? "65%" : (idx === 1 ? "45%" : "25%"))} crit in {x} gauntlet{x>1?"s":""}</span>)
            }
        });
        return desc.map((x,idx)=><div style={{ fontSize: '80%'}} key={idx}>{x}</div>);
    }

    const topRolls = (crew: NamerCrew) => {
        let topPairs = [];
        let prevRank = 0;
        if (!crew.gTopPairs) {
            return;
        }
        crew.gTopPairs.forEach((x, idx)=>{
            if (x.rank === 1 || idx === 0 || prevRank === x.rank) {
                //topPairs.push(<span><b>#{x.rank} {x.pair}</b> <small style={{ fontSize: '80%' }}> x{x.count}</small></span>)
                topPairs.push(<Label key={idx} className="top-pair" horizontal size="small" basic>
                    #{x.rank} {x.pair}
                    <LabelDetail>
                            x{x.count<10?" ":""}{x.count}
                    </LabelDetail>
                </Label>);
                prevRank = x.rank;
            }
        });
        return topPairs.map(x => x)
    }

  const renderTableRow = (crew: NamerCrew, idx: number) => {
    return (
        <React.Fragment>
            <Table.Cell>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '60px auto',
                        gridTemplateAreas: `'icon stats' 'icon description'`,
                        gridGap: '1px'
                    }}
                >
                    <div style={{ gridArea: 'icon' }}>
                        <img alt={crew.name} width={48} src={getAssetURL(crew.portrait)} />
                    </div>
                    <div style={{ gridArea: 'stats' }} className={rarityClass(crew.rarity)}>
                        <Link className={rarityClass(crew.rarity)}
                            to={"/crew/"+crew.symbol}
                            style={{ fontWeight: 'bolder', fontSize: '1.25em' }}
                            onClick={()=>logEvent("CrewLink", "gauntlet", crew.symbol)}>
                            {crew.name}
                        </Link>
                    </div>
                    <div style={{ gridArea: 'description' }}>{descriptionLabel(crew)}</div>
                </div>
            </Table.Cell>
            <Table.Cell>
                {topRolls(crew)}
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>#{crew.gNRank}</b><br />
                <small style={{ fontSize: '100%' }}>{crew.gRating}</small>
            </Table.Cell>
            <Table.Cell key='total' textAlign='center'>
                <b>{crew.total}</b>
                <br />
                <small style={{ fontSize: '80%' }}>({crew.totalMin}-{crew.totalMax})</small>
            </Table.Cell>
            {crew.cmd > 0 ? (
                <Table.Cell key='cmd' textAlign='center'>
                    <b>{crew.cmd.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%' }}>({crew.cmdMin.toFixed(0)}-{crew.cmdMax.toFixed(0)})</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='cmd' />
            )}
            {crew.dip > 0 ? (
                <Table.Cell key='dip' textAlign='center'>
                    <b>{crew.dip.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%' }}>({crew.dipMin.toFixed(0)}-{crew.dipMax.toFixed(0)})</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='dip' />
            )}
            {crew.eng > 0 ? (
                <Table.Cell key='eng' textAlign='center'>
                    <b>{crew.eng.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%' }}>({crew.engMin.toFixed(0)}-{crew.engMax.toFixed(0)})</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='eng' />
            )}
            {crew.med > 0 ? (
                <Table.Cell key='med' textAlign='center'>
                    <b>{crew.med.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%' }}>({crew.medMin.toFixed(0)}-{crew.medMax.toFixed(0)})</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='med' />
            )}
            {crew.sci > 0 ? (
                <Table.Cell key='sci' textAlign='center'>
                    <b>{crew.sci.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%' }}>({crew.sciMin.toFixed(0)}-{crew.sciMax.toFixed(0)})</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='sci' />
            )}
            {crew.sec > 0 ? (
                <Table.Cell key='sec' textAlign='center'>
                    <b>{crew.sec.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%' }}>({crew.secMin.toFixed(0)}-{crew.secMax.toFixed(0)})</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='sec' />
            )}
            {/*cSkills.map(skill => {
                let index = -1;
                Object.keys(crew.gScoreBySkill).forEach((k, idx) => {
                    if (k == skill) {
                        index = idx;
                    }
                })
                return index > -1 ? (
                    <Table.Cell key={skill} textAlign='center'>
                        <b>{Math.floor(Object.values(crew.gScoreBySkill)[index])}</b>
                        <br />
                        ({Math.floor(Object.values(crew.gMinBySkill)[index])}-{Math.floor(Object.values(crew.gMaxBySkill)[index])})
                    </Table.Cell>
                ) : (
                    <Table.Cell key={skill} />
                )
            })*/}
        </React.Fragment>
    );
  }

  const renderExpandedRow = (crew: NamerCrew, idx: number) => {
    return (
        <React.Fragment>
            <Table.Cell colSpan={10} className="expanded-cell">
                <GauntletStats crew={crew} showAd={true}/>
            </Table.Cell>
        </React.Fragment>
    )
  }  

  const title = "Gauntlet ratings - The Big Book of Behold Advice";
  const link = "https://www.bigbook.app/ratings/gauntlet";
  const description = "Star Trek Timelines best gauntlet crew! Gauntlet utility ratings are based on existing gauntlet sets and competitive rolls.";

  return (
    <div>
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={link} />
            <meta name="description" content={description}/>
            <meta property="og:url" content={link}/>
            <meta property="og:description" content={description}/>
            <meta name="twitter:url" content={link}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
        </Helmet>
        <SearchableTable
            id="gauntlet_crew"
            data={crew}
            config={tableConfig}
            renderTableRow={(crew, idx) => renderTableRow(crew, idx)}
            renderExpandedRow={(crew, idx) => renderExpandedRow(crew, idx)}
            filterRow={showThisCrew}
            showFilterOptions={false}
            showSearchExplanation={true}
            querySuggestions={true}
        />
        <Footer/>
    </div>
  );
}

export default Gauntleteers;