import '../assets/fomantic/dist/semantic.css';
import { Container, Table } from 'semantic-ui-react';
import { Link } from '@reach/router';
import React from 'react';
import { Helmet } from 'react-helmet';

import '../App.css';
import { NamerCrew, getCrew } from '../services/NamerStats';
import { SearchableTable, ITableConfigRow } from './SearchableTable';
import { Footer } from './Footer';
import { logEvent, rarityClass, showThisCrew } from '../utils/utils';
import { getAssetURL } from '../App';

export const Collections = (props: {
    navigation: () => void;
}) => {
    const [crew, setCrew] = React.useState<NamerCrew[]|undefined>(undefined);

    React.useEffect(() => {
        props.navigation();
        if (!crew) {
            getCrew().then((data) => {
                if (data && data.length > 0) {
                    // data = data.filter(x=>x.rarity>=4);
                    data.forEach(x=> {
                        x.triplet = x.skills.join("/");
                        x.collection = x.collections ? x.collections.length : 0;
                        x.trait = x.traits ? x.traits.length : 0;
                        x.cStats = x.cCount[0]
                        x.cCrew = x.cCount[1]
                        x.cVanity = x.cCount[3]
                        x.cNPotentialCount = x.cNPotentialTraits ? x.cNPotentialTraits.length : 0;
                    });
                    data.sort((a,b) => b.rarity-a.rarity || a.cNFinalRank-b.cNFinalRank);
                    setCrew(data);
                }            
            });
        }
    }, [])

    if (crew == undefined) {
        return <Container><div className="center ui active loader"/></Container>;
    }

    const tableConfig: ITableConfigRow[] = [
        { width: 6, column: 'name', title: 'Crew' },
        { width: 1, column: 'cNFinalRank', title: 'Rating', class: 'row-header'},
        { width: 1, column: 'cNRatingR', title: 'Current', class: 'row-header'},
        { width: 1, column: 'cNPotentialRank', title: 'Potential', class: 'row-header'},
        { width: 1, column: 'collection', title: 'Total', secondaryColumn: 'cNFinalRating', class: 'row-header'},
        { width: 1, column: 'cStats', title: 'Stat', secondaryColumn: 'cNFinalRating', class: 'row-header'},
        { width: 1, column: 'cCrew', title: 'Crew', secondaryColumn: 'cNFinalRating', class: 'row-header'},
        { width: 1, column: 'cVanity', title: 'Vanity', secondaryColumn: 'cNFinalRating', class: 'row-header'},
        { width: 4, column: 'cNPotentialCount', title: 'Potential collections', secondaryColumn: 'cNPotentialRating' },
        // { width: 1, column: 'priSec', title: 'PriSec', class: 'row-header'},
        // { width: 1, column: 'priTer', title: 'PriTer', class: 'row-header'},
        // { width: 1, column: 'secTer', title: 'SecTer', class: 'row-header'},     
    ]

    const descriptionLabel = (crew: NamerCrew) => {
        return <span style={{fontSize:"small"}}>{crew.collections.map(x=>x.replaceAll("_", " ")).join(", ")}</span>
    }

  const renderTableRow = (crew: NamerCrew, idx: number) => {
    return (
        <React.Fragment>
            <Table.Cell>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '60px auto',
                        gridTemplateAreas: `'icon stats' 'icon description'`,
                        gridGap: '1px'
                    }}
                >
                    <div style={{ gridArea: 'icon' }}>
                        <img width={48} src={getAssetURL(crew.portrait)} />
                    </div>
                    <div style={{ gridArea: 'stats' }} className={rarityClass(crew.rarity)}>
                        <Link className={rarityClass(crew.rarity)}
                            to={"/crew/"+crew.symbol}
                            style={{ fontWeight: 'bolder', fontSize: '1.25em' }} 
                            onClick={()=>logEvent("CrewLink", "collection", crew.symbol)}>
                                {crew.name}
                        </Link>
                    </div>
                    <div style={{ gridArea: 'description' }}>{descriptionLabel(crew)}</div>
                </div>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>#{crew.cNFinalRank}</b><br />
                <small style={{ fontSize: '100%' }}>{crew.cNFinalRating}</small>
            </Table.Cell>   
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>#{crew.cNRatingR}</b><br />
                <small style={{ fontSize: '100%' }}>{crew.cNRating}</small>
            </Table.Cell>            
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>#{crew.cNPotentialRank}</b><br />
                <small style={{ fontSize: '100%' }}>{crew.cNPotentialRating}</small>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {crew.collection}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {crew.cStats}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {crew.cCrew}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {crew.cVanity}
            </Table.Cell>
            <Table.Cell>
                <small style={{ fontSize: '100%' }}>{crew.cNPotentialTraits ? crew.cNPotentialTraits.join(", ") : ""}</small>
            </Table.Cell>
        </React.Fragment>
    );
  }

  const title = "Collection ratings - The Big Book of Behold Advice";
  const link = "https://www.bigbook.app/ratings/collection";
  const description = "Star Trek Timelines crew collection data and ratings based on current and potential value";

  return (
    <div>
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={link} />
            <meta name="description" content={description}/>
            <meta property="og:url" content={link}/>
            <meta property="og:description" content={description}/>
            <meta name="twitter:url" content={link}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
        </Helmet>
        <SearchableTable
            id="collection_crew"
            data={crew}
            config={tableConfig}
            renderTableRow={(crew, idx) => renderTableRow(crew, idx)}
            //renderExpandedRow={(crew, idx) => renderExpandedRow(crew, idx)}
            filterRow={showThisCrew}
            showFilterOptions={false}
            showSearchExplanation={true}
            querySuggestions={true}
        />
        <Footer/>
    </div>
  );
}

export default Collections;