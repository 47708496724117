import '../assets/fomantic/dist/semantic.css';
import { Container, Table } from 'semantic-ui-react';
import React from 'react';
import { Link } from '@reach/router';
import { Helmet } from 'react-helmet';

import '../App.css';
import { NamerCrew, getCrew } from '../services/NamerStats';
import { SearchableTable, ITableConfigRow } from './SearchableTable';
import { Footer } from './Footer';
import { logEvent, rarityClass, showThisCrew } from '../utils/utils';
import { getAssetURL } from '../App';

export const Shuttles = (props: {
    navigation: () => void;
}) => {
    const [crew, setCrew] = React.useState<NamerCrew[]|undefined>(undefined);

    React.useEffect(() => {
        props.navigation();
        if (crew) {
            return;
        }
        getCrew().then((data) => {
            if (data && data.length > 0) {
                // data = data.filter(x=>x.rarity>=4);
                data.forEach(x=> {
                    x.triplet = x.skills.join("/");
                    x.dip = x.baseScore["dip"]
                    x.sec = x.baseScore["sec"]
                    x.sci = x.baseScore["sci"]
                    x.eng = x.baseScore["eng"]
                    x.med = x.baseScore["med"]
                    x.cmd = x.baseScore["cmd"]
                    x.priSec = x.bPScore[0]
                    x.priTer = x.bPScore[1]
                    x.secTer = x.bPScore[2]
                    x.total = 0;
                    for (const base of Object.values(x.baseScore)) {
                        x.total += base
                    }
                    x.total = Math.floor(x.total)
                });
                data.sort((a,b) => b.rarity-a.rarity || a.bRatingRank-b.bRatingRank);
                setCrew(data);
            }            
        });
    }, []);

    if (!crew) {
        return <Container><div className="center ui active loader"/></Container>;
    }

    const tableConfig: ITableConfigRow[] = [
        { width: 4, column: 'name', title: 'Crew' },
        { width: 1, column: 'bRatingRank', title: 'Rating', class: 'row-header'},
        { width: 1, column: 'bRatingRRank', title: 'Base rating', class: 'row-header'},
        { width: 1, column: 'total', title: 'Total', class: 'row-header'},
        { width: 1, column: 'cmd', title: <img alt='C' width='14' src={getAssetURL('icons_icon_command_skill.png')}/>, class: 'row-header'},
        { width: 1, column: 'dip', title: <img alt='D' width='14' src={getAssetURL('icons_icon_diplomacy_skill.png')}/>, class: 'row-header'},
        { width: 1, column: 'eng', title: <img alt='E' width='14' src={getAssetURL('icons_icon_engineering_skill.png')}/>, class: 'row-header'},
        { width: 1, column: 'med', title: <img alt='M' width='14' src={getAssetURL('icons_icon_medicine_skill.png')}/>, class: 'row-header'},
        { width: 1, column: 'sci', title: <img alt='Sc' width='14' src={getAssetURL('icons_icon_science_skill.png')}/>, class: 'row-header'},
        { width: 1, column: 'sec', title: <img alt='Se' width='14' src={getAssetURL('icons_icon_security_skill.png')}/>, class: 'row-header'},
        { width: 1, column: 'priSec', title: 'PriSec', class: 'row-header'},
        { width: 1, column: 'priTer', title: 'PriTer', class: 'row-header'},
        { width: 1, column: 'secTer', title: 'SecTer', class: 'row-header'},     
    ]

    const descriptionLabel = (crew: NamerCrew) => {
        // let desc = [];
        // crew.bMentions.forEach((x, idx)=> {
        //     let tokens = x.split(" "); 
        //     desc.push(<Label key={idx} className="top-pair" horizontal size='small' basic circular>
        //         {tokens[0]} {tokens[1]}
        //     </Label>)
        // });
        let desc = [];
        crew.bMentions.forEach((x, idx)=> {
            desc.push(<span className="nowrap" key={idx}>{x}{(idx!==crew.bMentions.length-1)?", ":""}{idx%2===1?<br/>:''}</span>)
        });
        return <div className="shuttles-topranks">{desc.map(x=>x)}</div>
    }

  const renderTableRow = (crew: NamerCrew, idx: number) => {
    return (
        <React.Fragment>
            <Table.Cell>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '60px auto',
                        gridTemplateAreas: `'icon stats' 'icon description'`,
                        gridGap: '1px'
                    }}
                >
                    <div style={{ gridArea: 'icon' }}>
                        <img alt={crew.name} width={48} src={getAssetURL(crew.portrait)} />
                    </div>
                    <div style={{ gridArea: 'stats' }} className={rarityClass(crew.rarity)}>
                        <Link className={rarityClass(crew.rarity)}
                            to={"/crew/"+crew.symbol}
                            style={{ fontWeight: 'bolder', fontSize: '1.25em' }}
                            onClick={()=>logEvent("CrewLink", "shuttle", crew.symbol)}>
                            {crew.name}
                        </Link>
                    </div>
                    <div style={{ gridArea: 'description' }}>{descriptionLabel(crew)}</div>
                </div>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>#{crew.bRatingRank}</b><br />
                <small style={{ fontSize: '100%' }}>{crew.bRating}</small>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>#{crew.bRatingRRank}</b><br />
                <small style={{ fontSize: '100%' }}>{crew.bRatingR}</small>
            </Table.Cell>
            <Table.Cell key='total' textAlign='center'>
                {crew.total}
            </Table.Cell>
            {crew.cmd > 0 ? (
                <Table.Cell key='cmd' textAlign='center'>
                    <b>{crew.cmd.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%', color: 'darkgray' }}>{crew.bSuccess["cmd"]}%<br/>#{crew.bRank["cmd"]}</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='cmd' />
            )}
            {crew.dip > 0 ? (
                <Table.Cell key='dip' textAlign='center'>
                    <b>{crew.dip.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%', color: 'darkgray' }}>{crew.bSuccess["dip"]}%<br/>#{crew.bRank["dip"]}</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='dip' />
            )}
            {crew.eng > 0 ? (
                <Table.Cell key='eng' textAlign='center'>
                    <b>{crew.eng.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%', color: 'darkgray' }}>{crew.bSuccess["eng"]}%<br/>#{crew.bRank["eng"]}</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='eng' />
            )}
            {crew.med > 0 ? (
                <Table.Cell key='med' textAlign='center'>
                    <b>{crew.med.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%', color: 'darkgray' }}>{crew.bSuccess["med"]}%<br/>#{crew.bRank["med"]}</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='med' />
            )}
            {crew.sci > 0 ? (
                <Table.Cell key='sci' textAlign='center'>
                    <b>{crew.sci.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%', color: 'darkgray' }}>{crew.bSuccess["sci"]}%<br/>#{crew.bRank["sci"]}</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='sci' />
            )}
            {crew.sec > 0 ? (
                <Table.Cell key='sec' textAlign='center'>
                    <b>{crew.sec.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%', color: 'darkgray' }}>{crew.bSuccess["sec"]}%<br/>#{crew.bRank["sec"]}</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='sec' />
            )}
            {crew.priSec > 0 ? (
                <Table.Cell key='priSec' textAlign='center'>
                    <b>{crew.priSec.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%', color: 'darkgray' }}>{crew.bPSuccess[0]}<br/>{crew.bPRank[0] !== 0 ? "#"+crew.bPRank[0] : ""}</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='priSec' />
            )}
            {crew.priTer > 0 ? (
                <Table.Cell key='priTer' textAlign='center'>
                    <b>{crew.priTer.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%', color: 'darkgray' }}>{crew.bPSuccess[1]}%<br/>{crew.bPRank[1] !== 0 ? "#"+crew.bPRank[1] : ""}</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='priTer' />
            )}
            {crew.secTer > 0 ? (
                <Table.Cell key='secTer' textAlign='center'>
                    <b>{crew.secTer.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%', color: 'darkgray' }}>{crew.bPSuccess[2]}%<br/>{crew.bPRank[2] !== 0 ? "#"+crew.bPRank[2] : ""}</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='secTer' />
            )}
            {/*cSkills.map(skill => {
                let index = -1;
                Object.keys(crew.gScoreBySkill).forEach((k, idx) => {
                    if (k == skill) {
                        index = idx;
                    }
                })
                return index > -1 ? (
                    <Table.Cell key={skill} textAlign='center'>
                        <b>{Math.floor(Object.values(crew.gScoreBySkill)[index])}</b>
                        <br />
                        ({Math.floor(Object.values(crew.gMinBySkill)[index])}-{Math.floor(Object.values(crew.gMaxBySkill)[index])})
                    </Table.Cell>
                ) : (
                    <Table.Cell key={skill} />
                )
            })*/}
        </React.Fragment>
    );
  }

  const title = "Shuttle ratings - The Big Book of Behold Advice";
  const link = "https://www.bigbook.app/ratings/shuttle";
  const description = "Star Trek Timelines daily shuttle utility ratings based on faction shuttles and seat frequency data";

  return (
    <div>
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={link} />
            <meta name="description" content={description}/>
            <meta property="og:url" content={link}/>
            <meta property="og:description" content={description}/>
            <meta name="twitter:url" content={link}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
        </Helmet>
        <SearchableTable
            id="shuttle_crew"
            data={crew}
            config={tableConfig}
            renderTableRow={(crew, idx) => renderTableRow(crew, idx)}
            filterRow={(crew, filters, filterType) => showThisCrew(crew, filters, filterType)}
            showFilterOptions={false}
            showSearchExplanation={true}
            querySuggestions={true}
        />
        <br/>
        <p>
            Note: These ratings take into account success chances and ranks on every seat of every shuttle for every faction, with a bias towards federation, section 31 and augment shuttles. The <i>base ratings</i> do not account for daily shuttle seat frequency data and simply assume all seats are equal.
        </p>
        <Footer/>
    </div>
  );
}

export default Shuttles;