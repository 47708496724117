import React from 'react';
import * as localForage from 'localforage';

interface StorageOptions {
	rememberForever?: boolean;	// We store in session unless told to remember forever
	useDefault?: boolean;	// Set to true to use default value as initial value instead of any stored value
	useAndStoreDefault?: boolean;	// Set to true to use default and store it immediately to avoid render loops
	storeAsMap?: boolean;
};

const StorageDefaultOptions: StorageOptions = {
	rememberForever: false,
	useDefault: false,
	useAndStoreDefault: false,
	storeAsMap: false
};

export const useStateWithStorage = (itemKey: string, itemDefault: any, options?: StorageOptions) => {
	if (!options) options = StorageDefaultOptions;

	const ref = React.useRef();

	// Start with default value in state
	const [value, setValue] = React.useState(itemDefault);

	// Update stored value when value changed in state
	//	Remove from store (or ignore) if non-initial value is undefined or default
	React.useEffect(() => {
		if (value === undefined || value == itemDefault) {
			if (ref.current != undefined) {
				removeStoredItem(itemKey, options.rememberForever);
			}
		}
		else {
			storeItem(itemKey, value, options.rememberForever, options.storeAsMap);
		}
		ref.current = value;
	}, [value]);

	React.useEffect(() => {
		// On component mount: override stored value if requested
		if (options.useAndStoreDefault) {
			setValue(itemDefault);
			storeItem(itemKey, value, options.rememberForever, options.storeAsMap);
		}
		// Otherwise update value with stored value
		else if (!options.useDefault) {
			getStoredItem(itemKey, itemDefault, options.rememberForever, options.storeAsMap).then((storedValue) => {
				setValue(storedValue);
			});
		}
	}, []);

	return [value, setValue];
};

const mapToObjectRec = (m) => {
    let lo = {}
    for(let[k,v] of m) {
        if(v instanceof Map) {
            lo[k] = mapToObjectRec(v)
        }
        else {
            lo[k] = v
        }
    }
    return lo
}

const objectToMap = (o) => {
    let m = new Map()
    for(let k of Object.keys(o)) {
		const key = isNaN(k as any) ? k : parseInt(k);
        if(o[k] instanceof Object) {
            m.set(key, objectToMap(o[k]))   
        }
        else {
            m.set(key, o[k])
        }    
    }
    return m
}

// Use JSON.stringify and JSON.parse to preserve item types when storing, getting
const storeItem = (itemKey: string, itemValue: any, useLocalStorage: boolean, storeAsMap: boolean) => {
	if (useLocalStorage) {
		if (!storeAsMap) {
			localForage.setItem(itemKey, JSON.stringify(itemValue));
		} else {
			var convertedMap = mapToObjectRec(itemValue);
			localForage.setItem(itemKey, JSON.stringify(convertedMap, null, ' '));
		}
	}
	else {
		sessionStorage.setItem(itemKey, JSON.stringify(itemValue));
	}
};
const getStoredItem = (itemKey: string, itemDefault: any, useLocalStorage: boolean,  storeAsMap: boolean) => {
	return new Promise((resolve, reject) => {
		if (useLocalStorage) {
			if (storeAsMap) {
				localForage.getItem(itemKey).then((localValue) => {
					if (!localValue) {
						resolve(itemDefault);
					}
					else {
						resolve(objectToMap(JSON.parse(localValue as string)));
					}
				});
			} else {
				localForage.getItem(itemKey).then((localValue) => {
					if (!localValue) {
						resolve(itemDefault);
					}
					else {
						resolve(localValue);
					}
				});
			}
		}
		else {
			let sessionValue = sessionStorage.getItem(itemKey);
			if (!sessionValue) {
				resolve(itemDefault);
			}
			else {
				resolve(JSON.parse(sessionValue));
			}
		}
	});
};
const removeStoredItem = (itemKey: string, useLocalStorage: boolean) => {
	if (useLocalStorage) {
		localForage.removeItem(itemKey);
	}
	else {
		sessionStorage.removeItem(itemKey);
	}
};