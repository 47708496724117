import '../assets/fomantic/dist/semantic.css';
import { Container, Grid, Header, Icon, Segment, Statistic, Table, Image, Popup } from 'semantic-ui-react';
import { Link } from '@reach/router';
import React from 'react';
import { Helmet } from 'react-helmet';
import ReactTooltip from 'react-tooltip';
import AdSense from 'react-adsense';
import ReactGA from 'react-ga4';

import '../App.css';
import { NamerCrew, getCrew, EventInfo, getEventInfo, CaptainRanks, getCaptainRanks, CaptainHistory, getCaptainHistory, CaptainRanksSummary, FleetHistory, getFleetHistory, FleetRanksSummary, vanityFleetNames } from '../services/NamerStats';
import { SearchableTable, ITableConfigRow } from './SearchableTable';
import { isMobile, isMobileOnly, isTablet, useMobileOrientation } from 'react-device-detect';
import { Footer } from './Footer';
import EventStats from './CrewStats/EventStats';
import { logEvent, rarityClass, showThisCrew, unityRichTextToCleanText, unityRichTextToHtml } from '../utils/utils';
import { getAssetURL } from '../App';
import CaptainEventStats from './CrewStats/CaptainEventStats';

export const FleetPage = (props: {
    events?: Map<number,EventInfo>;
    fid: string;
    navigation: () => void;
}) => {
    let mobileOrientation = useMobileOrientation();
    let isOnlyMobile = isMobile && !isTablet && mobileOrientation.isPortrait;
    const [history, setHistory] = React.useState<FleetHistory|undefined>(undefined);
    const [events, setEvents] = React.useState<undefined|Map<number,EventInfo>>(props.events);

    React.useEffect(() => {
        props.navigation();
        if (!events) {
            getEventInfo().then((data)=>setEvents(data));
        }
        let fid = vanityFleetNames.get(props.fid);
        getFleetHistory(fid ?? props.fid).then((data) => {
            if (data) {
                setHistory(data);
            }
        });
    }, []);

    if (history == undefined || events == undefined) {
        return <Container><div className="center ui active loader"/></Container>;
    }

    const tableConfig: ITableConfigRow[] = [
        { width: 1, column: 'year', title: 'Year', class: 'row-header'},
        { width: 1, column: 'overall', title: 'Overall', secondaryColumn: 'top100', class: 'row-header'},
        { width: 1, column: 'r1', title: 'Rank 1', secondaryColumn: '-overall', class: 'row-header'},
        { width: 1, column: 'r2', title: 'Rank 2', secondaryColumn: '-overall', class: 'row-header'},
        { width: 1, column: 'r3', title: 'Rank 3', secondaryColumn: '-overall', class: 'row-header'},
        { width: 1, column: 'r4', title: '4-10', secondaryColumn: '-overall', class: 'row-header'},
        { width: 1, column: 'r5', title: '11-25', secondaryColumn: '-overall', class: 'row-header'},
        { width: 1, column: 'r6', title: '26-50', secondaryColumn: '-overall', class: 'row-header'},
        { width: 1, column: 'r7', title: '51-100', secondaryColumn: '-overall', class: 'row-header'},
        { width: 1, column: 'top100', title: 'Top 100', secondaryColumn: '-overall', class: 'row-header'},
    ]

  const renderTableRow = (summary: CaptainRanksSummary, idx: number) => {
    return (
        <React.Fragment>
            <Table.Cell style={{ textAlign: 'center' }}>
                {summary.year == 2100 ? "All-Time" : summary.year}
            </Table.Cell>
            {summary.change != 0 && <Table.Cell style={{ textAlign: 'center' }} className="tier">
                <span>
                    <big><b>#{summary.overall}</b></big>
                    {summary.change && <small style={{ fontSize: '100%', marginLeft: '5px' }}>
                        {summary.change>0 && <span><Icon size="small" name="caret up" color="green" style={{marginRight:"0px"}}/>{summary.change}</span>}
                        {summary.change<0 && <span><Icon size="small" name="caret down" color="red" style={{marginRight:"0px"}}/>{-summary.change}</span>}
                        {/* {ranks.change===0 && <Icon size="small" name="minus" style={{marginLeft:"3px"}}/>} */}
                    </small>}
                </span>
            </Table.Cell>}
            {(summary.change == undefined || summary.change == 0) && <Table.Cell style={{ textAlign: 'center' }}>
                <big><b>#{summary.overall}</b></big>
            </Table.Cell>}
            {/* <Table.Cell style={{ textAlign: 'center' }}>
                {new Date(summary.last).toLocaleDateString("en-US", {month: "short", day: "2-digit", year: "numeric"})}
            </Table.Cell> */}
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>{summary.ranks[0]}</b>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>{summary.ranks[1]}</b>
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {summary.ranks[2]}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {summary.ranks[3]}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {summary.ranks[4]}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {summary.ranks[5]}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {summary.ranks[6]}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                <b>{summary.top100}</b>
            </Table.Cell>
        </React.Fragment>
    );
  }

  const renderExpandedRow = (summary: FleetRanksSummary, idx: number) => {
    if (summary.year == 2100) return;
    return (
        <React.Fragment>
            <Table.Cell colSpan={15} className="expanded-cell">
                <CaptainEventStats events={events} year={summary.year} history={history.history} hideRibbons={true}/>
            </Table.Cell>
        </React.Fragment>
    )
  }

  const title = unityRichTextToCleanText(history.name) + " - The Big Book of Behold Advice";
  const link = "https://www.bigbook.app/fleet/" + props.fid;
  const description = "Star Trek Timelines fleet details with historical event results and overall rankings";

  return (
    <div>
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={link} />
            <meta name="description" content={description}/>
            <meta property="og:url" content={link}/>
            <meta property="og:description" content={description}/>
            <meta name="twitter:url" content={link}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
        </Helmet>

        <div className="fleetProfileLayout">
            <div>
                {!isMobileOnly && <Statistic size="large">
                    <Statistic.Value><Icon name="trophy" size="small" color="yellow"/></Statistic.Value>
                    <Statistic.Label><big><b>#{history.summary[0].overall}</b></big></Statistic.Label>
                </Statistic>}
                {isMobileOnly && <Statistic>
                    <Statistic.Value><Icon name="trophy" size="tiny" color="yellow"/></Statistic.Value>
                    <Statistic.Label><big><b>#{history.summary[0].overall}</b></big></Statistic.Label>
                </Statistic>}
            </div>
            <h1><b>{history.name && <div className={isOnlyMobile?"capProfileNameM":"capProfileName"} dangerouslySetInnerHTML={{__html:unityRichTextToHtml(history.name)}}/>}</b></h1>
        </div>
        
        <SearchableTable
            id="captainHistory"
            data={Array.from(Object.values(history.summary)).sort((a,b)=>b.year-a.year)}
            config={tableConfig}
            renderTableRow={(ranks, idx) => renderTableRow(ranks, idx)}
            renderExpandedRow={(ranks, idx) => renderExpandedRow(ranks, idx)}
            filterRow={() => {return true;}}
            hideSearch={true}
            showFilterOptions={false}
            showSearchExplanation={false}
            hideRarityFilter={true}
            defaultPerPage={25}
            clearSearch={true}
            hideFooter={true}
        />
        <div className="adslot">
            {(window as any).adsbygoogle?.loaded && <span className='adlabel'>Advertisement</span>}
            {isMobileOnly && <AdSense.Google
                className="ad"
                client='ca-pub-5995456634193629'
                slot='4792731176'
                style={{ display: 'block'}}
                format='auto'
                responsive='true'
            />}
            {!isMobileOnly && <AdSense.Google
                className="ad adfixed"
                client='ca-pub-5995456634193629'
                slot='5899860916'
                style={{ display: 'inline-block', width: '970px', height: '250px', border: '1px solid #444444'}}
                format='fixed'
            />}
        </div>
        <Footer/>
    </div>
  );
}

export default FleetPage;
