import '../../assets/fomantic/dist/semantic.css';
import { Grid, Container, Header } from 'semantic-ui-react';
import { navigate } from "@reach/router";
import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie } from 'recharts';


import '../../App.css';
import { EventLeaderboard, getCrew, getLeaderboard, NamerCrew } from '../../services/NamerStats';
import { isMobile, isTablet } from 'react-device-detect';

export const TraitStats = () => {
    const [crew, setCrew] = React.useState<NamerCrew[]|undefined>(undefined);
    const [traitBarData, setTraitBarData] = React.useState(undefined);
    const [traitPieData, setTraitPieData] = React.useState(undefined);

    React.useEffect(() => {
        if (!crew || crew.length == 0) {
            getCrew().then((data) => {
                if (data && data.length > 0) {
                    setCrew(data);
                }            
            });
        }
    }, [])

    React.useEffect(() => {
        if (!crew || crew.length == 0) return;
        let traitCount = new Map<number,Map<number,number>>();
        for (let i=0;i<=5;i++) {
            traitCount.set(i, new Map());
        }
        let min=100000,max=0;
        let color = ["red","green","blue"]
        crew.forEach((x, idx) => {
            traitCount.get(0).set(x.traits.length, (traitCount.get(0).get(x.traits.length)??0)+1);
            traitCount.get(x.rarity).set(x.traits.length, (traitCount.get(x.rarity).get(x.traits.length)??0)+1);
            if (min > x.traits.length) {
                min = x.traits.length;
            }
            if (max < x.traits.length) {
                max = x.traits.length;
            }
        })
        let barData = [];
        let pieData = [], pieDataL = [], pieDataSR = [];
        for (let count=min; count<=max; count++) {
            for (let i=1;i<=5;i++) {
                if (traitCount.get(i).size>0) {
                    continue
                }
                traitCount.get(i).set(count, 0);
                pieDataL.push({
                    name: count + " traits",
                    value: traitCount.get(i).get(count),
                });
            }
            barData.push({
                name: count + " traits",
                count: count,
                legendary: traitCount.get(5).get(count),
                superrare: traitCount.get(4).get(count),
                rare: traitCount.get(3).get(count),
                uncommon: traitCount.get(2).get(count),
                common: traitCount.get(1).get(count),
            });
            pieData.push({
                name: count + " traits",
                value: traitCount.get(0).get(count),
            });
        }
        setTraitBarData(barData);
        setTraitPieData(pieData);
    }, [crew]);

    if (crew == undefined) {
        return <Container>
            <div className="center" style={{marginTop:"1em",marginBottom:"2em"}}>
                <h3>Total crew by trait count</h3>
                <div className="ui centered active inline loader"/>
            </div>
        </Container>;
    }

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
        return (
            <text x={x < cx ? x+35 : x-30} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${traitPieData[index].name}: `}
            {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (<div className="center" style={{marginTop:"1em",marginBottom:"2em"}}>
        <h3>Total crew by trait count</h3>
        <div className={isMobile?"statsPanelsm":"statsPanels"}>
            {traitBarData && <ResponsiveContainer width="100%">
                <BarChart
                    width={isMobile?800:400}
                    height={isMobile?200:400}
                    data={traitBarData}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                <CartesianGrid strokeDasharray="3 3" vertical={false}/>
                <XAxis dataKey="count"/>
                <YAxis />
                <Tooltip contentStyle={{background:'rgb(50,50,50)', borderColor:"black"}} cursor={{fill:"rgb(50,50,50,0.7)"}}/>
                <Legend />
                <Bar dataKey="legendary" stackId="a" fill="rgba(253, 210, 106, 1)"/>
                <Bar dataKey="superrare" stackId="a" fill="rgba(170, 45, 235, 1)"/>
                <Bar dataKey="rare" stackId="a" fill="rgba(90, 170, 255, 1)"/>
                <Bar dataKey="uncommon" stackId="a" fill="rgba(77, 163, 58)"/>
                <Bar dataKey="common" stackId="a" fill="rgba(111, 116, 128)"/>
                </BarChart>
            </ResponsiveContainer>}
            {traitPieData && <ResponsiveContainer width="100%">
                <PieChart width={400} height={400}>
                    <Pie
                        data={traitPieData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={150}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {traitPieData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip/>
                {/* <Pie dataKey="value" data={traitPieDataL} cx={500} cy={200} innerRadius={40} outerRadius={80} fill="rgba(253, 210, 106, 1)" />
                <Pie dataKey="value" data={traitPieDataSR} cx={500} cy={200} innerRadius={40} outerRadius={80} fill="rgba(170, 45, 235, 1)" /> */}
                </PieChart>
            </ResponsiveContainer>}
        </div>
    </div>);
}

export default TraitStats;