import '../../assets/fomantic/dist/semantic.css';
import { List } from 'semantic-ui-react';
import { Link } from '@reach/router';
import AdSense from 'react-adsense';

import '../../App.css';
import { NamerCrew } from '../../services/NamerStats';
import { logEvent } from '../../utils/utils';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';

export const CollectionStats = (props: {
    crew: NamerCrew;
    showAd?: boolean;
}) => {

    return (
        <List divided relaxed>
            <List.Item>
                <List.Icon name='box' size='large' verticalAlign='top' />
                <List.Content>
                    <List.Header>{props.crew.collections.length === 0 ? "No collections" : 
                        props.crew.collections.map((x,idx)=><span key={idx}>
                            <Link className="blueLink"
                                to={"/ratings/collection?search="+encodeURIComponent(x)}
                                onClick={()=>logEvent("SearchType", "collection", x)}>
                                {x}
                            </Link>
                            {idx===props.crew.collections.length-1?"":", "}
                        </span>)
                    }</List.Header>
                    <List.Description>
                        #{props.crew.cNFinalRank} by total collection rating
                    </List.Description>
                </List.Content>
            </List.Item>
            {props.crew.cNPotentialTraits && props.crew.cNPotentialTraits.length > 0 && <List.Item>
                <List.Icon name='question' size='large' verticalAlign='top' />
                <List.Content>
                    <List.Header>Potential collections:{' '}
                        {props.crew.cNPotentialTraits.map((x,idx)=><span key={idx}>
                            <Link className="blueLink"
                                to={"/ratings/collection?search="+encodeURIComponent(x)}
                                onClick={()=>logEvent("SearchType", "PotentialCollection", x)}>
                                {x}
                            </Link>
                            {idx===props.crew.cNPotentialTraits.length-1?"":", "}</span>)}
                    </List.Header>
                    <List.Description>#{props.crew.cNPotentialRank} by potential collection rating</List.Description>
                </List.Content>
            </List.Item>}
        </List>)
  }

  export default CollectionStats;