import '../assets/fomantic/dist/semantic.css';
import { Container, Icon, Segment, Table, Image, Grid } from 'semantic-ui-react';
import { Link } from '@reach/router';
import React from 'react';
import { Helmet } from 'react-helmet';
import AdSense from 'react-adsense';
import ReactGA from 'react-ga4';


import '../App.css';
import './Gauntlet.css';
import { NamerCrew, getCrew, EventInfo, getEventInfo, CaptainRanks, getCaptainRanks, getCommendations, Commendation } from '../services/NamerStats';
import { SearchableTable, ITableConfigRow } from './SearchableTable';
import { isMobile, isMobileOnly, isTablet, useMobileOrientation } from 'react-device-detect';
import { Footer } from './Footer';
import EventStats from './CrewStats/EventStats';
import { logEvent, rarityClass, showThisCrew, unityRichTextToHtml } from '../utils/utils';
import { getAssetURL } from '../App';

export const Captains = (props: {
    navigation: () => void;
}) => {
    let mobileOrientation = useMobileOrientation();
    let isOnlyMobile = isMobile && !isTablet && mobileOrientation.isPortrait;
    const [commendations, setCommendations] = React.useState<undefined|Commendation>(undefined);
    const [captains, setCaptains] = React.useState<CaptainRanks[]|undefined>(undefined);
    const [year, setYear] = React.useState<number>(2024);

    React.useEffect(() => {
        getCommendations().then((data)=>{
            let date1 = new Date(data.eventDate);
            let date2 = new Date();
            let diffTime = date2.getTime() - date1.getTime();
            let diffDays = diffTime / (1000 * 3600 * 24);
            if (diffDays < 11) {
                setCommendations(data);    
            }            
        });
        props.navigation();
    }, []);

    React.useEffect(() => {
        getCaptainRanks(year).then((data) => {
            if (data && data.length > 0) {
                setCaptains(data);
            }
        });
    }, [year]);

    if (captains == undefined) {
        return <Container><div className="center ui active loader"/></Container>;
    }

    const tableConfig: ITableConfigRow[] = [
        { width: 4, column: 'name', title: 'Captain' },
        { width: 1, column: 'overall', title: 'Overall', secondaryColumn: 'name', pseudocolumns: ["overall", "change"], class: 'row-header'},
        { width: 2, column: 'last', title: 'Last top 100', secondaryColumn: 'overall', class: 'row-header'},
        { width: 1, column: 'r1', title: 'Rank 1', secondaryColumn: 'invoverall', class: 'row-header'},
        { width: 1, column: 'r2', title: 'Rank 2-3', secondaryColumn: 'invoverall', class: 'row-header'},
        { width: 1, column: 'r3', title: 'Rank 4-15', secondaryColumn: 'invoverall', class: 'row-header'},
        { width: 1, column: 'r4', title: 'Rank 16-30', secondaryColumn: 'invoverall', class: 'row-header'},
        { width: 1, column: 'r5', title: 'Rank 31-50', secondaryColumn: 'invoverall', class: 'row-header'},
        { width: 1, column: 'r6', title: 'Rank 51-100', secondaryColumn: 'invoverall', class: 'row-header'},
        { width: 1, column: 'top100', title: 'Top 100', secondaryColumn: 'invoverall', class: 'row-header'},
    ]

  const renderTableRow = (ranks: CaptainRanks, idx: number) => {
    return (
        <React.Fragment>
            <Table.Cell>
            <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '60px auto',
                        gridTemplateAreas: `'icon stats' 'icon description'`,
                        gridGap: '1px'
                    }}
                >
                    <div style={{ gridArea: 'icon' }}>
                        <img width={48} src={getAssetURL((!ranks.avatar || ranks.avatar == "" ? "fleet_battle_scenarios_practice_space_bg.png" : ranks.avatar))} />
                    </div>
                    <div style={{ gridArea: 'stats' }}>
                        <Link className="captainLink" to={"/captain/"+(ranks.vanity ? ranks.vanity : ranks.id)}
                            style={{ fontWeight: 'bolder', fontSize: '1.25em' }}
                            onClick={()=>logEvent("CaptainLink", "captains", ranks.name)}>
                            {ranks.name}
                        </Link>
                        {//<big><b>{ranks.name}</b></big>
                        }
                    </div>
                    {ranks.fleet && <Link className="captainLink" to={"/fleet/"+(ranks.fleet_vanity ?? ranks.fleet_id)} style={{ gridArea: 'description' }}
                        onClick={()=>logEvent("FleetLink", "fleet", ranks.fleet)}>
                        <div dangerouslySetInnerHTML={{__html:unityRichTextToHtml(ranks.fleet)}}/>
                    </Link>}
                </div>
            </Table.Cell>
            {ranks.change != 0 && <Table.Cell style={{ textAlign: 'center' }} className="tier">
                <div className="captainRank">
                    <big><b>#{ranks.overall}</b></big>
                    {ranks.change && <small style={{ fontSize: '100%' }}>
                        {ranks.change>0 && <span><Icon size="small" name="caret up" color="green" style={{marginRight:"0px"}}/>{ranks.change}</span>}
                        {ranks.change<0 && <span><Icon size="small" name="caret down" color="red" style={{marginRight:"0px"}}/>{-ranks.change}</span>}
                        {/* {ranks.change===0 && <Icon size="small" name="minus" style={{marginLeft:"3px"}}/>} */}
                    </small>}
                </div>
            </Table.Cell>}
            {(ranks.change == undefined || ranks.change == 0) && <Table.Cell style={{ textAlign: 'center' }}>
                <big><b>#{ranks.overall}</b></big>
            </Table.Cell>}
            <Table.Cell style={{ textAlign: 'center' }}>
                {new Date(new Date(ranks.last).getTime() + new Date(ranks.last).getTimezoneOffset() * 60000).toLocaleDateString("en-US", {month: "short", day: "2-digit", year: "numeric"})}
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {ranks.ranks[0]}
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {ranks.ranks[1]}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {ranks.ranks[2]}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {ranks.ranks[3]}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {ranks.ranks[4]}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {ranks.ranks[5]}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                <b>{ranks.top100}</b>
            </Table.Cell>
        </React.Fragment>
    );
  }

  function showThisCaptain(ranks: CaptainRanks, filters: any[], filterType: string, arena?: boolean): boolean {
    let result = true;  
    for (const filter of filters) {
        for (const segment of filter.textSegments) {
            segment.text = segment.text.toLowerCase();
            result = result && (ranks.name.toLowerCase().includes(segment.text) || (ranks.fleet && ranks.fleet.toLowerCase().includes(segment.text)));
        }
    }
    return result;
  }

  const title = "Captain rankings - The Big Book of Behold Advice";
  const link = "https://www.bigbook.app/leaderboards/captain";
  const description = "Star Trek Timelines player historical event results and overall rankings";

  return (
    <div>
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={link} />
            <meta name="description" content={description}/>
            <meta property="og:url" content={link}/>
            <meta property="og:description" content={description}/>
            <meta name="twitter:url" content={link}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
        </Helmet>
        {commendations && <div className={isOnlyMobile?"commAwardM":"commAward"}>
            <span className={isOnlyMobile?"commTextM":"commText"}>
                Service commendations have been awarded to the <b>top 3</b> ranked players in the event <i>
                    <Link className="blueLink" to={"/event/"+commendations.eventID}
                        onClick={()=>logEvent("EventLink", "events", commendations.eventName)}>
                        {commendations.eventName}
                    </Link>
                </i>{' '}
                ({new Date(new Date(commendations.eventDate).getTime() + new Date(commendations.eventDate).getTimezoneOffset() * 60000).toLocaleDateString("en-US", {month:"short", day:"2-digit", year:"numeric"})})
            </span>
            <Image className="ribbonLarge" size='small' src={getAssetURL("ribbon("+commendations.eventID+").webp", false, true)}/>
            <div className={isOnlyMobile?"commAwardPlayersM":"commAwardPlayers"}>
                {commendations.players.map((x,idx)=>
                    <div className="commAwardPlayer" key={idx}>
                        <div className={"commAwardPlayerAvatar"}>
                            <div className={"gtopcrew-image-b"} style={{  position: 'relative', display: 'inline-block' }}>
                                <div className={"shadow-b"} style={{ position: 'relative', display: 'inline-block' }}>
                                    <img className={"gauntlet-image-c"} src={getAssetURL(x.avatar)} height={100} />
                                </div>
                            </div>
                        </div>
                        <div className={"commAwardPlayerName"}>
                            <Link className="captainLink" to={"/captain/"+(x.vanity ?? x.pid)}
                                style={{ fontWeight: 'bolder', fontSize: '1.25em' }}
                                onClick={()=>logEvent("CaptainLink", "captains", x.name)}>
                                {x.name}
                            </Link>
                        </div>
                        <div className="commAwardPlayerFleet">
                            {x.fleet && <Link className="captainLink" to={"/fleet/"+(x.fleet_vanity??x.fleet_id)}
                                onClick={()=>logEvent("FleetLink", "fleet", x.fleet)}>
                                <div dangerouslySetInnerHTML={{__html:unityRichTextToHtml(x.fleet)}}/>
                            </Link>}
                        </div>
                    </div>)}
            </div>
        </div>}
        <div className="rarityfilter">
            <span className={"tierFilter"+(year==0?" tierFilterSelected":"")} onClick={()=>setYear(0)}>All-time</span>|
            <span className={"tierFilter"+(year==2024?" tierFilterSelected":"")} onClick={()=>setYear(2024)}>2024</span>
            <span className={"tierFilter"+(year==2023?" tierFilterSelected":"")} onClick={()=>setYear(2023)}>2023</span>
            <span className={"tierFilter"+(year==2022?" tierFilterSelected":"")} onClick={()=>setYear(2022)}>2022</span>
            <span className={"tierFilter"+(year==2021?" tierFilterSelected":"")} onClick={()=>setYear(2021)}>2021</span>
            <span className={"tierFilter"+(year==2020?" tierFilterSelected":"")} onClick={()=>setYear(2020)}>2020</span>
            <span className={"tierFilter"+(year==2019?" tierFilterSelected":"")} onClick={()=>setYear(2019)}>2019</span>
            <span className={"tierFilter"+(year==2018?" tierFilterSelected":"")} onClick={()=>setYear(2018)}>2018</span>
            <span className={"tierFilter"+(year==2017?" tierFilterSelected":"")} onClick={()=>setYear(2017)}>2017</span>
            <span className={"tierFilter"+(year==2016?" tierFilterSelected":"")} onClick={()=>setYear(2016)}>2016</span>
        </div>
        <SearchableTable
            id="captains"
            data={captains}
            config={tableConfig}
            renderTableRow={(ranks, idx) => renderTableRow(ranks, idx)}
            //renderExpandedRow={(crew, idx) => renderExpandedRow(crew, idx)}
            filterRow={showThisCaptain}
            showFilterOptions={false}
            showSearchExplanation={false}
            hideRarityFilter={true}
            defaultPerPage={10}
            clearSearch={true}
        />
        <br/>
        <p style={{color:'silver'}}>
            <b>Account event history merging</b> is allowed for abandoned and rerolled accounts but not alts played simultaneously. Automatic checks are in place to ensure there is no event history overlap between the merged accounts, both in the past and going forward. Account merge requests can be made on Discord. Similarly, account history forks are allowed for accounts that changed ownership.
        </p>
        <div className="adslot">
            {(window as any).adsbygoogle?.loaded && <span className='adlabel'>Advertisement</span>}
            {isMobileOnly && <AdSense.Google
                className="ad"
                client='ca-pub-5995456634193629'
                slot='6708448076'
                style={{ display: 'block'}}
                format='auto'
                responsive='true'
            />}
            {!isMobileOnly && <AdSense.Google
                className="ad adfixed"
                client='ca-pub-5995456634193629'
                slot='4921821160'
                style={{ display: 'inline-block', width: '970px', height: '250px', border: '1px solid #444444'}}
                format='fixed'
            />}
        </div>
        <Footer/>
    </div>
  );
}

export default Captains;