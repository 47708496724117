import '../../assets/fomantic/dist/semantic.css';
import { List, Image, Table, Statistic, Divider } from 'semantic-ui-react';
import React from 'react';
import { Link } from '@reach/router';
import AdSense from 'react-adsense';
import ReactGA from 'react-ga4';

import '../../App.css';
import { NamerCrew, VProjection } from '../../services/NamerStats';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { logEvent } from '../../utils/utils';
import { getAssetURL } from '../../App';

export const VoyageStats = (props: {
    crew: NamerCrew;
    showAd?: boolean;
}) => {
    const isOnlyMobile = isMobile && !isTablet;
    const projectionLabels: string[] = [
        "6 mo", "1 yr", "2 yrs", "3 yrs", "4 yrs"
    ]
    const traitIcon = (trait:string) => {
        if (trait === "physician") {
            return getAssetURL("items_keystones_doctor.png")
        } else {
            return getAssetURL("items_keystones_"+trait+".png")
        }
    }

    const getSearchLink = (text: string, trait?: string) => {
        let tokens = text.split(' ');
        let suffixIndex = 0;
        const getFirstPart = () => {
            if (tokens.length > 2) {
                let skillSearch = tokens[1]
                if (skillSearch.indexOf(',') > 0) {
                    skillSearch = skillSearch.substring(0, skillSearch.length-1);
                    tokens[1] = skillSearch;
                }
                if (tokens.length > 3 && tokens[3] == "any-order") {
                    skillSearch = skillSearch.replaceAll("/", "|");
                    suffixIndex++;
                }
                if (skillSearch.length == 3) {
                    skillSearch = "p:" + skillSearch;
                } else if (skillSearch.length == 7) {
                    skillSearch += "/"
                }
                suffixIndex += 2;
                return <a target="_self" className="blueLink"
                    href={"/ratings/voyage?search="+(trait?encodeURIComponent(trait)+" ":"")+skillSearch}
                    onClick={()=>logEvent("SearchType", "VoyageSkill", skillSearch)}>
                    {tokens[1]}
                </a>
            }
        }
        const getSecondPart = () => {
            if (tokens.length > 4 && tokens[2][0] == '#') {
                let skillSearch = tokens[3]
                if (skillSearch.length == 7) {
                    skillSearch += "/"
                }
                suffixIndex += 2;
                return <a className="blueLink" target="_self"
                    href={"/ratings/voyage?search="+(trait?encodeURIComponent(trait)+" ":"")+skillSearch}
                    onClick={()=>logEvent("SearchType", "VoyageSkill", skillSearch)}>
                    {tokens[3]}
                </a>   
            }
        }
        const first = getFirstPart();
        const second = getSecondPart();
        let suffixFiller = ""
        for (let i=suffixIndex; i<tokens.length; i++) {
            suffixFiller += tokens[i] + " ";
        }
        
        return <span>{tokens[0]+' '} {first} {second?', '+tokens[2]:''} {second} {' '} {suffixFiller}<br/></span> 
    }

    const getSkillset = () => {
        let style = {}
        if (isOnlyMobile) {
            style = {
                color: "#999"
            }
        }
        return <div style={style}>
            {getSearchLink(props.crew.vSkillRanks[0])}
            {props.crew.vSkillRanks.length > 1 && getSearchLink(props.crew.vSkillRanks[1])}
            {props.crew.vSkillRanks.length > 2 && getSearchLink(props.crew.vSkillRanks[2])}
        </div>
    }

    const getSeatings = () => {
        return <React.Fragment>
            {Object.keys(props.crew.vSeating).map((trait, idx) => {
                let seating = Object.values(props.crew.vSeating)[idx];
                return (
                    <List.Item key={idx+"_4"}>
                        {!isOnlyMobile && <Image avatar src={traitIcon(trait)} />}
                        <List.Content>
                            <List.Header>{isOnlyMobile && <Image avatar src={traitIcon(trait)} />}
                                <a className="blueLink" target="_self"
                                    href={"/ratings/voyage?search="+encodeURIComponent(trait)+" "+props.crew.skills.join(" ")}
                                    onClick={()=>logEvent("SearchType", "VoyageTrait", trait)}>
                                    {trait}
                                </a> (chance: {(seating.chance*100).toFixed(2)}%)
                            </List.Header>
                            <List.Description>
                                {getSearchLink(seating.skillRanks[0], trait)}
                                {seating.skillRanks.length > 1 && getSearchLink(seating.skillRanks[1], trait)}
                                {seating.skillRanks.length > 2 && getSearchLink(seating.skillRanks[2], trait)}
                            </List.Description>
                        </List.Content>
                    </List.Item>)
            })}
        </React.Fragment>
    }

    return (<List divided relaxed>
        <List.Item>
            <List.Icon name='chart bar outline' size='large' verticalAlign='top' />
            <List.Content>
                <List.Header>Voyage ranks</List.Header>
                <Statistic.Group inverted size="mini">
                    <Statistic>
                        <Statistic.Label>Rating</Statistic.Label>
                        <Statistic.Value>{props.crew.vNRating}</Statistic.Value>
                        <Statistic.Label>#{props.crew.vNRank}</Statistic.Label>
                    </Statistic>
                    <Divider/>
                    <Statistic>
                        <Statistic.Label>Score</Statistic.Label>
                        <Statistic.Value>{props.crew.voyageScore.toFixed(0)}</Statistic.Value>
                        <Statistic.Label>#{props.crew.vRank}</Statistic.Label>
                    </Statistic>
                    <Divider/>
                    <Statistic>
                        <Statistic.Label>Unboosted</Statistic.Label>
                        <Statistic.Value>{props.crew.vScoreR.toFixed(0)}</Statistic.Value>
                        <Statistic.Label>#{props.crew.vRankR}</Statistic.Label>
                    </Statistic>
                    {props.crew.vFirstRank && <React.Fragment>
                        <Divider/>
                        <Statistic>
                            <Statistic.Label>Intro rank</Statistic.Label>
                            <Statistic.Value>#{props.crew.vFirstRank}</Statistic.Value>
                        </Statistic>    
                    </React.Fragment>}
                </Statistic.Group>
            </List.Content>
        </List.Item>

        {props.crew.vProjections && <List.Item>
            <List.Icon name='chart line' size='large' verticalAlign='top' />
            <List.Content>
                {!isMobile && <Table definition basic='very' compact celled collapsing style={{"backgroundColor":"transparent"}}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{"color":"white", "backgroundColor":"transparent"}}><b>Voy rank<br/>projections</b></Table.HeaderCell>
                            <Table.HeaderCell>6 mo</Table.HeaderCell>
                            <Table.HeaderCell>1 yr</Table.HeaderCell>
                            <Table.HeaderCell>2 yrs</Table.HeaderCell>
                            <Table.HeaderCell>3 yrs</Table.HeaderCell>
                            <Table.HeaderCell>4 yrs</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell style={{"color":"white"}}>med (min-max)</Table.Cell>
                            {props.crew.vProjections.map((p: VProjection, pIdx) =>
                                <Table.Cell key={pIdx+"_1"}>#{p.avg}  <small style={{ fontSize: '80%' }}>({p.min}-{p.max})</small></Table.Cell>
                            )}
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{"color":"white"}}>velocity</Table.Cell>
                            {props.crew.vProjections.map((p: VProjection, pIdx) =>
                                <Table.Cell key={pIdx+"_2"}>{p.velocity.toFixed(2)}/mo</Table.Cell>
                            )}
                        </Table.Row>
                    </Table.Body>
                </Table>}
                {isOnlyMobile && <List.Header>Voyage rank projections</List.Header>}
            </List.Content>
            {isOnlyMobile && <table className="mobile-bases">
                <thead>
                    <tr>
                        <td/>
                        <td>med</td>
                        <td>min</td>
                        <td>max</td>
                        <td>velocity</td>
                    </tr>
                </thead>
                <tbody>
                    {props.crew.vProjections.map((p: VProjection, pIdx) =>
                        <tr key={pIdx+"_3"}>
                            <td><b>{projectionLabels[pIdx]}</b></td>
                            <td>#{p.avg}</td>
                            <td>#{p.min}</td>
                            <td>#{p.max}</td>
                            <td>{p.velocity.toFixed(2)}/mo</td>
                        </tr>
                    )}
                </tbody>
            </table>}
        </List.Item>}
    
        <List.Item>
            <List.Icon name='briefcase' size='large' verticalAlign='top' />
            <List.Content>
                <List.Header>Skillset</List.Header>
                <List.Description>
                    {!isOnlyMobile && getSkillset()}
                </List.Description>
            </List.Content>
            {isOnlyMobile && getSkillset()}
        </List.Item>

        <List.Item>
            <List.Icon name='space shuttle' size='large' verticalAlign='top' />
            <List.Content>
                <List.Header>Voyage priority seating</List.Header>
                <List.Description>
                    Overall AM bonus chance: #{props.crew.vAMRank} at {(props.crew.vAMChance*100).toFixed(2)}%
                    <List divided relaxed>
                        {!isOnlyMobile && getSeatings()}
                    </List>
                </List.Description>
            </List.Content>
            {isOnlyMobile && getSeatings()}
        </List.Item>

        {props.crew.vOutperfs && props.crew.vOutperfs.length > 0 && <List.Item>
            <List.Icon name='hand rock' size='large' verticalAlign='top' />
            <List.Content>
                <List.Header>Outperformers</List.Header>
                <List.Description>
                    Total coverage: {(props.crew.vOutperfCov / Object.keys(props.crew.vSeating).length * 100).toFixed(0)}%
                    <List divided relaxed>
                        {props.crew.vOutperfs.map((outp, idx) => {
                            if (!outp.seats || !outp.seats.length) {
                                return <List.Item key={idx}>
                                    <List.Content>
                                        <List.Header>{outp.name}</List.Header>
                                    </List.Content>
                                    </List.Item>
                            }
                            return <List.Item key={idx}>
                                <List.Content>
                                    <List.Header>{outp.name} ({outp.seats.length} of {Object.keys(props.crew.vSeating).length})</List.Header>
                                    <List.Description>
                                        {outp.seats.join(", ")}
                                    </List.Description>
                                </List.Content>
                            </List.Item>})
                        }
                    </List>
                </List.Description>
            </List.Content>
        </List.Item>}
        {props.showAd && <div className="adslot">
            {(window as any).adsbygoogle?.loaded && <span className='adlabel'>Advertisement</span>}
            {isMobileOnly && <AdSense.Google
                className="ad"
                client='ca-pub-5995456634193629'
                slot='9104066187'
                style={{ display: 'block'}}
                format='auto'
                responsive='true'
            />}
            {!isMobileOnly && <AdSense.Google
                className="ad adfixed"
                client='ca-pub-5995456634193629'
                slot='6036147198'
                style={{ display: 'inline-block', width: '970px', height: '250px', border: '1px solid #444444'}}
                format='fixed'
            />}
        </div>}
    </List>)
  }

  export default VoyageStats;