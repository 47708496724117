import '../../assets/fomantic/dist/semantic.css';
import { Icon, List } from 'semantic-ui-react';
import AdSense from 'react-adsense';

import '../../App.css';
import { CrewNote, NamerCrew } from '../../services/NamerStats';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';

export const FlavorStats = (props: {
    crew: NamerCrew;
    note?: CrewNote;
    showAd?: boolean;
}) => {

    return (
        <List divided relaxed>
            <p><Icon size="large" style={{marginRight:'10px'}} className="quote right icon"/>{' '}{props.crew.flavor ? <i><big>{props.crew.flavor}</big></i> : <i>No flavor text</i>}</p>
            <List.Item>
                <List.Icon name='chart bar outline' size='large' verticalAlign='top' />
                <List.Content>
                    <List.Header>{props.note && props.note.fTier ? <p>Tier {props.note.fTier}</p> : "Tier unknown"}</List.Header>
                    <List.Description>
                        #{props.crew.fRank} by tier and length
                        {props.crew.flavor && <span><br/>Length: {props.crew.flavor.length}<br/>Words: {props.crew.flavor.split(" ").length}</span>}
                    </List.Description>
                </List.Content>
            </List.Item>
            {props.note && props.note.fNote && <List.Item>
                <List.Icon name='edit outline' size='large' verticalAlign='top' />
                <List.Content>
                    <List.Header>Editorial review</List.Header>
                    <List.Description>
                        <p className="fNote">{props.note.fNote}</p>
                    </List.Description>
                </List.Content>
            </List.Item>}
        </List>)
  }

  export default FlavorStats;