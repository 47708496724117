import '../assets/fomantic/dist/semantic.css';
import { Grid, Container, Header, Image } from 'semantic-ui-react';
import { navigate, Link } from "@reach/router";
import React from 'react';
import { Helmet } from 'react-helmet';
import AdSense from 'react-adsense';
import ReactGA from 'react-ga4';

import '../App.css';
import './Gauntlet.css';
import { NamerGauntlet, getGauntlet } from '../services/NamerStats';
import { ITableConfigRow } from './SearchableTable';
import { CircularLabel } from '../CircularLabel';
import { Footer } from '../components/Footer';
import { logEvent, rarityClass } from '../utils/utils';
import { getAssetURL } from '../App';
import { isMobile, isMobileOnly } from 'react-device-detect';

export const Gauntlet = (props:{
    raw?: string;
    navigation: () => void;
}) => {
    // const countries = ["US", "CA", "DE", "UK", "IT", "IN", "AU", "AT", "BE", "BR", "CN", "CH",
    //     "IE", "JP", "LU", "MX", "MY", "NL", "PL", "PT", "SA", "SG", "SP", "SW", "PH", "TH", "TR"];
    const [gauntlet, setGauntlet] = React.useState<NamerGauntlet|undefined>(undefined);
    const [error, setError] = React.useState<string|undefined>(undefined);
    //const [isPrimeEligible, setIsPrimeEligible] = React.useState<boolean>(false);

    React.useEffect(() => {
        props.navigation();
        // fetch("https://ipapi.co/json/").then((data) => data.json().then((json) => {
        //     setIsPrimeEligible(countries.some(x=>x == json.country));
        // }));
        getGauntlet(props.raw).then((data) => {
            if (data && data.raw) {
                setGauntlet(data);
            } else {
                setError("Oops, I have not seen this gauntlet before.");
            }         
        }).catch(() => setError("Oops, I have not seen this gauntlet before."));
    }, [])

    if (gauntlet == undefined) {
        return <Container>
            {error ? <div className="center">{error}<div className="center blueLink">{props.raw && <a onClick={() => navigate("/gauntlet")}>Go to today's gauntlet</a>}</div></div> : <div className="center ui active loader"/>}
        </Container>;
    }

    const cSkills = ["cmd","dip","eng","med","sci","sec"]

    const tableConfig: ITableConfigRow[] = [
        { width: 4, column: 'name', title: 'Crew', pseudocolumns: ['name', 'crit'] },
        { width: 4, column: 'toprolls', title: 'Top rolls'},
        { width: 1, column: 'gNRating', title: 'Rating', class: 'row-header'},
        { width: 1, column: 'cmd', title: <img width='14' src={getAssetURL('icons_icon_command_skill.png')}/>, class: 'row-header'},
        { width: 1, column: 'dip', title: <img width='14' src={getAssetURL('icons_icon_diplomacy_skill.png')}/>, class: 'row-header'},
        { width: 1, column: 'eng', title: <img width='14' src={getAssetURL('icons_icon_engineering_skill.png')}/>, class: 'row-header'},
        { width: 1, column: 'med', title: <img width='14' src={getAssetURL('icons_icon_medicine_skill.png')}/>, class: 'row-header'},
        { width: 1, column: 'sci', title: <img width='14' src={getAssetURL('icons_icon_science_skill.png')}/>, class: 'row-header'},
        { width: 1, column: 'sec', title: <img width='14' src={getAssetURL('icons_icon_security_skill.png')}/>, class: 'row-header'},
    ]

    const getSkillImg = (skill: string) => {
        if (skill == "cmd") return getAssetURL("icons_icon_command_skill.png");
        else if (skill == "sci") return getAssetURL("icons_icon_science_skill.png");
        else if (skill == "dip") return getAssetURL("icons_icon_diplomacy_skill.png");
        else if (skill == "eng") return getAssetURL("icons_icon_engineering_skill.png");
        else if (skill == "med") return getAssetURL("icons_icon_medicine_skill.png");
        else if (skill == "sec") return getAssetURL("icons_icon_security_skill.png");
    }
    
    let commaIndex = gauntlet.raw.indexOf(",");
    let skillImg = getSkillImg(gauntlet.raw.substring(commaIndex-3, commaIndex));
    let skills = gauntlet.raw.slice(commaIndex+1).split(",").map(x => {
        x = x[0].toUpperCase() + x.slice(1);
        x = x.replace("_", " ");
        let space = x.indexOf(" ");
        if (space > 0) {
            x = x.slice(0, space) + " " + x[space+1].toUpperCase() + x.slice(space+2);
        }
        return x;
    })

    const title = "Today's gauntlet - The Big Book of Behold Advice";
  const link = "https://www.bigbook.app/gauntlet";
  const description = "Star Trek Timelines gauntlet calculator! Top performing crew in todays gauntlet and lists of top rolls in each skill pair";

  return (
    <div>
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={link} />
            <meta name="description" content={description}/>
            <meta property="og:url" content={link}/>
            <meta property="og:description" content={description}/>
            <meta name="twitter:url" content={link}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
        </Helmet>

        {props.raw && <div className="center"><a className="blueLink" onClick={() => navigate("/gauntlet")}>Go to today's gauntlet</a></div>}
        <Header className="gauntlet-header">
            <img width='16' src={skillImg}/>
            {skills.join(",  ")}
        </Header>

        <Header className="center">Top performers</Header>
        <Grid stackable columns="5" padded relaxed centered>
            {gauntlet.topCrew.map((crew, idx) => {
                if (idx > 4) {
                    return;
                }
                let tierText = crew.tier.split("-"); 
                return (
                    <div className="gtopcrew" key={"top"+idx}>
                        <div className={rarityClass(crew.rarity) + " gtopcrew-name"}>
                            <Link className={rarityClass(crew.rarity)} to={"/crew/"+crew.symbol+"?stat=gauntlet"} onClick={()=>logEvent("CrewLink", "gauntletPage", crew.symbol)}>{crew.name}</Link>
                        </div>       
                        <div className="gtopcrew-image" style={{  position: 'relative', display: 'inline-block' }}>
                            <div className="shadow" style={{ position: 'relative', display: 'inline-block' }}>
                                <img className="gauntlet-image" src={getAssetURL(crew.portrait)} height={200} />
                            </div>
                            <div style={{ position: 'absolute', right: '95%', top: '95%' }}>
                                <CircularLabel percent={crew.crit*100} />
                            </div>
                        </div>
                        <div className="gtopcrew-ranks">
                            <div className="gtopcrew-tier"><big><b>{tierText[0]}</b></big>-<small>{tierText[1]}</small></div>
                            <div className="gauntlet-top-crew small-font darker-font">
                                {crew.rolls.map((topRoll, index) => {
                                    return (
                                        <span key={idx+"-"+index}>{topRoll}</span>
                                    )
                                })}
                            </div>
                        </div>
                    </div>)
            })}
        </Grid>

        <Grid stackable centered relaxed>
            {gauntlet.topCrew.map((crew, idx) => {
                if (idx < 5) {
                    return;
                }
                let tierText = crew.tier.split("-"); 
                return (
                    <div className="gtopcrew-b" key={"mid"+idx} style={isMobileOnly?{width:"180px"}:{}}>
                       <div className="gtopcrew-image-b" style={{  position: 'relative', display: 'inline-block' }}>
                            <div className="shadow-b" style={{ position: 'relative', display: 'inline-block' }}>
                                <img className="gauntlet-image-b" src={getAssetURL(crew.portrait)} />
                            </div>
                            <div style={{ position: 'absolute', right: '95%', top: '95%' }}>
                                <CircularLabel percent={crew.crit*100} />
                            </div>
                        </div>
                        <div className={rarityClass(crew.rarity)+" gtopcrew-name-b"+(isMobileOnly?" w120px":"")}>
                            <Link className={rarityClass(crew.rarity)} to={"/crew/"+crew.symbol+"?stat=gauntlet"} onClick={()=>logEvent("CrewLink", "gauntletPage", crew.symbol)}>{crew.name}</Link>
                        </div>       
                        <div className="gtopcrew-tier-b"><big><b>{tierText[0]}</b></big>-<small>{tierText[1]}</small></div>
                        <div className={"gtopcrew-ranks-b small-font darker-font"+(isMobileOnly?" w120px":"")}>
                            {crew.rolls && crew.rolls.join(", ")}
                        </div>
                    </div>)
            })}
        </Grid>
        <br/>
        <div className="adslot">
            {(window as any).adsbygoogle?.loaded && <span className='adlabel'>Advertisement</span>}
            {isMobileOnly && <AdSense.Google
                className="ad"
                client='ca-pub-5995456634193629'
                slot='7453170648'
                style={{ display: 'block'}}
                format='auto'
                responsive='true'
            />}
            {!isMobileOnly && <AdSense.Google
                className="ad adfixed"
                client='ca-pub-5995456634193629'
                slot='8135696926'
                style={{ display: 'inline-block', width: '970px', height: '250px', border: '1px solid #444444'}}
                format='fixed'
            />}
        </div>
        {/* {isPrimeEligible && <a href="https://amzn.to/43K5WAX" target="_blank">
            {!isMobileOnly && <Image style={{marginTop:"1em"}} centered src={getAssetURL("audiblebounty.jpg")}/>}
            {isMobileOnly && <Image style={{marginTop:"1em"}} centered src={getAssetURL("audiblemobile.png")}/>}
        </a>} */}
        <Header className="center">Top average rolls</Header>
        <br/>
        <Grid stackable columns="5" relaxed centered>
            {Object.keys(gauntlet.topRolls).map(key => {
                let rolls = gauntlet.topRolls[key];
                let skills = key.split("/");
                
                return (
                    <div key={key}>
                        <div className='ui label big group-header'>
                            <img className="toprolls-skill" src={getSkillImg(skills[0])} />
                            <img className="toprolls-skill" src={getSkillImg(skills[1])} />
                        </div>
                        <div className="ui segment toprolls-list-container">
                            {rolls.map((roll, index) => {
                                return <div key={key+index} className={(index%2==0) ? "toprolls-list toprolls-list-odditem" : "toprolls-list"}>
                                    <img src={getAssetURL(roll.portrait)} className="toprolls-image"/>
                                    <div className={rarityClass(roll.rarity) + " toprolls-name"}>
                                        <Link className={rarityClass(roll.rarity)} to={"/crew/"+roll.symbol+"?stat=gauntlet"} onClick={()=>logEvent("CrewLink", "gauntletPage", roll.symbol)}>{roll.name}</Link>
                                    </div>
                                    <CircularLabel percent={roll.crit*100} noposition={true}/>
                                    <div className="toprolls-roll">{Math.floor(roll.roll)}</div>
                                </div>
                            })}
                        </div>
                    </div>
                );
            })}
        </Grid>
        <Footer/>
    </div>
  );
}

export default Gauntlet;