import '../assets/fomantic/dist/semantic.css';
import { Container, Icon, Segment, Table, Image, Grid } from 'semantic-ui-react';
import { Link } from '@reach/router';
import React from 'react';
import { Helmet } from 'react-helmet';
import AdSense from 'react-adsense';
import ReactGA from 'react-ga4';

import '../App.css';
import './Gauntlet.css';
import { NamerCrew, getCrew, EventInfo, getEventInfo, CaptainRanks, getCaptainRanks, getCommendations, Commendation, FTMer, getFTMers } from '../services/NamerStats';
import { SearchableTable, ITableConfigRow } from './SearchableTable';
import { isMobile, isMobileOnly, isTablet, useMobileOrientation } from 'react-device-detect';
import { Footer } from './Footer';
import EventStats from './CrewStats/EventStats';
import { logEvent, rarityClass, showThisCrew, unityRichTextToHtml } from '../utils/utils';
import { getAssetURL } from '../App';

export const FTM = (props: {
    navigation: () => void;
}) => {
    let mobileOrientation = useMobileOrientation();
    let isOnlyMobile = isMobile && !isTablet && mobileOrientation.isPortrait;
    const [FTMers, setFTMers] = React.useState<FTMer[]|undefined>(undefined);

    React.useEffect(() => {
        getFTMers().then((data)=>{
            for (let i=0; i<data.length; i++) {
                data[i].r1 = data[i].totalByRarity[0];
                data[i].r2 = data[i].totalByRarity[1];
                data[i].r3 = data[i].totalByRarity[2];
                data[i].r4 = data[i].totalByRarity[3];
                data[i].r5 = data[i].totalByRarity[4];
            }
            setFTMers(data);           
        });
        props.navigation();
    }, []);

    if (FTMers == undefined) {
        return <Container><div className="center ui active loader"/></Container>;
    }

    const tableConfig: ITableConfigRow[] = [
        { width: 3, column: 'name', title: 'Captain' },
        { width: 2, column: 'lastFTM', title: 'Last FTM', secondaryColumn: 'name', class: 'row-header'},
        { width: 1, column: 'total', title: 'Total', secondaryColumn: 'lastFTM', class: 'row-header'},
        { width: 1, column: 'r5', title: 'Legendary', secondaryColumn: 'total', class: 'row-header'},
        { width: 1, column: 'r4', title: 'Super Rare', secondaryColumn: 'total', class: 'row-header'},
        { width: 1, column: 'r3', title: 'Rare', secondaryColumn: 'total', class: 'row-header'},
        { width: 1, column: 'r2', title: 'Uncommon', secondaryColumn: 'total', class: 'row-header'},
        { width: 1, column: 'r1', title: 'Common', secondaryColumn: 'total', class: 'row-header'},
    ]

  const renderTableRow = (ftm: FTMer, idx: number) => {
    return (
        <React.Fragment>
            <Table.Cell>
                {ftm.name}
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {new Date(new Date(ftm.lastFTM).getTime() + new Date(ftm.lastFTM).getTimezoneOffset() * 60000).toLocaleDateString("en-US", {month: "short", day: "2-digit", year: "numeric"})}
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {ftm.total}
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {ftm.r5}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {ftm.r4}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {ftm.r3}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {ftm.r2}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {ftm.r1}
            </Table.Cell>
        </React.Fragment>
    );
  }

  function showThisFTM(ftm: FTMer, filters: any[], filterType: string, arena?: boolean): boolean {
    let result = true;  
    for (const filter of filters) {
        for (const segment of filter.textSegments) {
            segment.text = segment.text.toLowerCase();
            result = result && ftm.name.toLowerCase().includes(segment.text);
        }
    }
    return result;
  }

  const title = "FTM rankings - The Big Book of Behold Advice";
  const link = "https://www.bigbook.app/leaderboards/ftm";
  const description = "Star Trek Timelines players ranked by total number of FTMs. First To Max is the first player to immortalize a specific card.";

  return (
    <div>
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={link} />
            <meta name="description" content={description}/>
            <meta property="og:url" content={link}/>
            <meta property="og:description" content={description}/>
            <meta name="twitter:url" content={link}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
        </Helmet>
        <SearchableTable
            id="ftmers"
            data={FTMers}
            config={tableConfig}
            renderTableRow={(ranks, idx) => renderTableRow(ranks, idx)}
            //renderExpandedRow={(crew, idx) => renderExpandedRow(crew, idx)}
            filterRow={showThisFTM}
            showFilterOptions={false}
            showSearchExplanation={false}
            hideRarityFilter={true}
            defaultPerPage={10}
            clearSearch={true}
        />
        <div className="adslot">
            {(window as any).adsbygoogle?.loaded && <span className='adlabel'>Advertisement</span>}
            {isMobileOnly && <AdSense.Google
                className="ad"
                client='ca-pub-5995456634193629'
                slot='7742645374'
                style={{ display: 'block'}}
                format='auto'
                responsive='true'
            />}
            {!isMobileOnly && <AdSense.Google
                className="ad adfixed"
                client='ca-pub-5995456634193629'
                slot='3037893859'
                style={{ display: 'inline-block', width: '970px', height: '250px', border: '1px solid #444444'}}
                format='fixed'
            />}
        </div>
        <Footer/>
    </div>
  );
}

export default FTM;