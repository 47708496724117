import '../assets/fomantic/dist/semantic.css';
import { Grid, Container, Header } from 'semantic-ui-react';
import { navigate } from "@reach/router";
import React from 'react';

import '../App.css';
import { EventLeaderboard, getLeaderboard } from '../services/NamerStats';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { Footer } from './Footer';
import { rarityClass, logEvent } from '../utils/utils';
import {Link} from '@reach/router';
import { getAssetURL } from '../App';
import { useBackground } from '../BackgroundContext';

export const About = (props:{
    id?: number;
    navigation: () => void;
}) => {
    const { setBackgroundImage, setVariant } = useBackground();
    const isOnlyMobile = isMobile && !isTablet;

    React.useEffect(() => {
        props.navigation();

        const checkBackgroundImage = (url) => {
            return new Promise((resolve, reject) => {
              const img = new window.Image();
              img.src = url;
      
              img.onload = () => resolve(true); // Image loaded successfully
              img.onerror = () => reject(false); // Image failed to load
            });
          };
        
        const image = getAssetURL("aboutog.png");
        if (!isOnlyMobile && image) {
            document.body.classList.add('customBG');
            //let backgroundColor = document.body.style.backgroundColor;
            //image = getAssetURL(image.replace("motd_banner", "events").replace("motd", "event"), true);
            //image = "https://bigbook.app/images/"+image.replace("motd_banner", "events").replace("motd", "event");
            checkBackgroundImage(image).then(() => {
                // document.body.style.backgroundColor = 'rgba(34, 34, 34, 0.7)';
                // document.body.style.backgroundSize = 'cover';
                // document.body.style.backgroundRepeat = 'no-repeat';
                // document.body.style.backgroundPosition = 'center';
                // document.body.style.backgroundAttachment = 'fixed';
                // document.body.style.backgroundBlendMode = 'overlay';
                setVariant(2);
                setBackgroundImage(image);
            }).catch(() => {
                document.body.classList.remove('customBG');
                document.body.classList.remove('active2');
                setBackgroundImage('');
                //setCustomBG(false);
            });

            // Clean up on component unmount
            return () => {
                console.log("removing bg image", image);
                setBackgroundImage('');
                document.body.classList.remove('customBG');
                document.body.classList.remove('active2');
                //document.body.style.backgroundColor = backgroundColor;
            };
        }
    }, [])

    return (<div>
        <div className='credits'>
            <br/>
            <h1>The Big Book of Behold Advice</h1>
            <br/>
            
            <p>
                <h2>Author</h2>
                <Link className={rarityClass(5)} to={"/staff/auto"} 
                    onClick={()=>logEvent("AuthorLink", "variantList", "auto")}>Automaton_2000</Link>
            </p>

            <p>
                <h2>Ratings and systems</h2>
                <Link className={rarityClass(4)} to={"/staff/namer"} 
                    onClick={()=>logEvent("AuthorLink", "variantList", "namer")}>NoNameNamer</Link>
            </p>

            <p>
                <h2>Contributors</h2>
                <Link className={rarityClass(5)} to={"/staff/idol"} 
                    onClick={()=>logEvent("AuthorLink", "variantList", "idol")}>Captain Idol</Link>
                <br/>
                <Link className={rarityClass(5)} to={"/staff/stars"} 
                    onClick={()=>logEvent("AuthorLink", "variantList", "stars")}>StarsAndGarters</Link>
                <br/>
                <Link className={rarityClass(5)} to={"/staff/big"} 
                    onClick={()=>logEvent("AuthorLink", "variantList", "big")}>BigMcLargeHuge</Link>
                <br/>
                <Link className={rarityClass(5)} to={"/staff/jenos"} 
                    onClick={()=>logEvent("AuthorLink", "variantList", "jenos")}>Jenos Idanian</Link>
                <br/>
                <Link className={rarityClass(5)} to={"/staff/nifty"} 
                    onClick={()=>logEvent("AuthorLink", "variantList", "nifty")}>Nifty Mittens</Link>
                <br/>
                <Link className={rarityClass(5)} to={"/staff/frank"} 
                    onClick={()=>logEvent("AuthorLink", "variantList", "frank")}>Frank</Link>
                <br/>
                <Link className={rarityClass(5)} to={"/staff/garak"} 
                    onClick={()=>logEvent("AuthorLink", "variantList", "garak")}>PlainSimpleGarak</Link>
            </p>
            {!isMobileOnly && <br/>}
        </div>
        <Footer ads={false}/>
    </div>);
}

export default About;