import '../../assets/fomantic/dist/semantic.css';
import { List, Statistic, Divider, Image } from 'semantic-ui-react';
import React from 'react';
import ReactGA from "react-ga4";
import { Link } from "@reach/router";

import '../../App.css';
import { NamerCrew, EventInfo, getEventInfo } from '../../services/NamerStats';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { logEvent } from '../../utils/utils';
import { getAssetURL } from '../../App';

export const CaptainEventStats = (props: {
    events?: Map<number,EventInfo>;
    year: number;
    history: Map<number,number>;
    hideRibbons?: boolean;
}) => {
    const [events, setEvents] = React.useState<undefined|Map<number,EventInfo>>(props.events);

    React.useEffect(() => {
        if (!events) {
            getEventInfo().then((data) => setEvents(data));
        }
    }, []);
 
    if (!events || events.size == 0) {
        return <p>Loading event data...</p>
    }

    let filteredEvents: EventInfo[] = [];
    if (props.history) {
        Object.keys(props.history).forEach(eventID=>{
                if (events[eventID] === undefined) {
                    return;
                }
                let endDate = new Date(events[eventID].start);
                endDate.setDate(endDate.getDate() + 4); 
                let year = endDate.getFullYear();
                if (year == props.year) {
                    events[eventID].id  = eventID;
                    filteredEvents.push(events[eventID]);
                }
        });
    }
    if (filteredEvents.length == 0) {
        return <span><i>No applicable events</i></span>;
    }

    filteredEvents.sort((a,b)=>(b.id-a.id));
    return (
        <List divided relaxed>
            <List.Item>
                <List.Content>
                    <List.Description>
                        {filteredEvents.map((event,idx) => {
                            let evtInfo = events[event.id];
                            const rank = props.history[event.id];
                            return (<div key={"de"+idx} style={{fontSize:"larger"}}>
                                <div className="mono inline">
                                    <b>#{rank}</b>
                                    {(props.hideRibbons || rank>3) && <div className="r2">{rank<10?' - ':'- '}</div>}
                                    {!props.hideRibbons && rank<4 && <img className="ribbonMini" src={getAssetURL("ribbon("+event.id+").webp", false, true)}/>}
                                    {new Date(evtInfo.start).toLocaleDateString("en-US", {month:"short", day:"2-digit"})}{' - '}
                                </div>
                                <Link className="blueLink" to={"/event/"+event.id}
                                    onClick={()=>logEvent("EventLink", "events", event.id.toString())}>
                                    {evtInfo.name}
                                </Link>
                                {/* <a className="blueLink" href={"https://sttwiki.org/wiki/"+encodeURIComponent(evtInfo.name)} target="_blank" rel="noreferrer noopener"
                                    onClick={() => {ReactGA.event({category:"ExternalLink",action:"WikiEvent",label:evtInfo.name})}}>
                                    {evtInfo.name}
                                </a> ({evtInfo.type}) */}
                            </div>)         
                        })}
                    </List.Description>
                </List.Content>
            </List.Item>
        </List>)
  }

  export default CaptainEventStats;