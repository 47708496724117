import '../assets/fomantic/dist/semantic.css';
import { Container, Icon, Segment, Table, Image, Grid } from 'semantic-ui-react';
import { Link } from '@reach/router';
import React from 'react';
import { Helmet } from 'react-helmet';
import AdSense from 'react-adsense';
import ReactGA from 'react-ga4';


import '../App.css';
import './Gauntlet.css';
import { NamerCrew, getCrew, EventInfo, getEventInfo, CaptainRanks, getCaptainRanks, getCommendations, Commendation, FleetRanks, getFleetHistory, getFleetRanks } from '../services/NamerStats';
import { SearchableTable, ITableConfigRow } from './SearchableTable';
import { isMobile, isMobileOnly, isTablet, useMobileOrientation } from 'react-device-detect';
import { Footer } from './Footer';
import EventStats from './CrewStats/EventStats';
import { logEvent, rarityClass, showThisCrew, unityRichTextToHtml } from '../utils/utils';
import { getAssetURL } from '../App';

export const Fleets = (props: {
    navigation: () => void;
}) => {
    let mobileOrientation = useMobileOrientation();
    let isOnlyMobile = isMobile && !isTablet && mobileOrientation.isPortrait;
    const [fleets, setFleets] = React.useState<FleetRanks[]|undefined>(undefined);
    const [year, setYear] = React.useState<number>(2024);

    React.useEffect(() => {
        props.navigation();
    }, []);

    React.useEffect(() => {
        getFleetRanks(year).then((data) => {
            if (data && data.length > 0) {
                setFleets(data);
            }
        });
    }, [year]);

    if (fleets == undefined) {
        return <Container><div className="center ui active loader"/></Container>;
    }

    const tableConfig: ITableConfigRow[] = [
        { width: 4, column: 'name', title: 'Fleet' },
        { width: 1, column: 'overall', title: 'Overall', secondaryColumn: 'name', pseudocolumns: ["overall", "change"], class: 'row-header'},
        { width: 2, column: 'last', title: 'Last top 100', secondaryColumn: 'overall', class: 'row-header'},
        { width: 1, column: 'r1', title: 'Rank 1', secondaryColumn: 'overall', class: 'row-header'},
        { width: 1, column: 'r2', title: 'Rank 2', secondaryColumn: 'overall', class: 'row-header'},
        { width: 1, column: 'r3', title: 'Rank 3', secondaryColumn: 'overall', class: 'row-header'},
        { width: 1, column: 'r4', title: '4-10', secondaryColumn: 'overall', class: 'row-header'},
        { width: 1, column: 'r5', title: '11-25', secondaryColumn: 'overall', class: 'row-header'},
        { width: 1, column: 'r6', title: '26-50', secondaryColumn: 'overall', class: 'row-header'},
        { width: 1, column: 'r7', title: '51-100', secondaryColumn: 'overall', class: 'row-header'},
        { width: 1, column: 'top100', title: 'Top 100', secondaryColumn: 'overall', class: 'row-header'},
        { width: 1, column: 'score', title: 'Total VP', secondaryColumn: 'overall', class: 'row-header'},
    ]

  const renderTableRow = (ranks: FleetRanks, idx: number) => {
    return (
        <React.Fragment>
            <Table.Cell>
                <div>
                    <Link className="captainLink" to={"/fleet/"+(ranks.vanity ?? ranks.id)}
                        style={{ fontWeight: 'bolder', fontSize: '1.25em' }}
                        onClick={()=>logEvent("FleetLink", "fleets", ranks.name)}>
                        <span dangerouslySetInnerHTML={{__html:unityRichTextToHtml(ranks.name)}}/>
                    </Link>
                </div>
            </Table.Cell>
            {ranks.change != 0 && <Table.Cell style={{ textAlign: 'center' }} className="tier">
                <div className="captainRank">
                    <big><b>#{ranks.overall}</b></big>
                    {ranks.change && <small style={{ fontSize: '100%' }}>
                        {ranks.change>0 && <span><Icon size="small" name="caret up" color="green" style={{marginRight:"0px"}}/>{ranks.change}</span>}
                        {ranks.change<0 && <span><Icon size="small" name="caret down" color="red" style={{marginRight:"0px"}}/>{-ranks.change}</span>}
                        {/* {ranks.change===0 && <Icon size="small" name="minus" style={{marginLeft:"3px"}}/>} */}
                    </small>}
                </div>
            </Table.Cell>}
            {(ranks.change == undefined || ranks.change == 0) && <Table.Cell style={{ textAlign: 'center' }}>
                <big><b>#{ranks.overall}</b></big>
            </Table.Cell>}
            <Table.Cell style={{ textAlign: 'center' }}>
                {new Date(new Date(ranks.last).getTime() + new Date(ranks.last).getTimezoneOffset() * 60000).toLocaleDateString("en-US", {month: "short", day: "2-digit", year: "numeric"})}
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {ranks.ranks[0]}
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {ranks.ranks[1]}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {ranks.ranks[2]}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {ranks.ranks[3]}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {ranks.ranks[4]}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {ranks.ranks[5]}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {ranks.ranks[6]}
            </Table.Cell>
            <Table.Cell textAlign='center'>
                <b>{ranks.top100}</b>
            </Table.Cell>
            <Table.Cell textAlign='center'>
                <b>{ranks.score.toLocaleString()}</b>
            </Table.Cell>
        </React.Fragment>
    );
  }

  function showThisFleet(ranks: FleetRanks, filters: any[], filterType: string, arena?: boolean): boolean {
    let result = true;  
    for (const filter of filters) {
        for (const segment of filter.textSegments) {
            segment.text = segment.text.toLowerCase();
            result = result && (ranks.name.toLowerCase().includes(segment.text));
        }
    }
    return result;
  }

  const title = "Fleet rankings - The Big Book of Behold Advice";
  const link = "https://www.bigbook.app/leaderboards/fleet";
  const description = "Star Trek Timelines fleet historical event results and overall rankings";

  return (
    <div>
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={link} />
            <meta name="description" content={description}/>
            <meta property="og:url" content={link}/>
            <meta property="og:description" content={description}/>
            <meta name="twitter:url" content={link}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
        </Helmet>
        <div className="rarityfilter">
            <span className={"tierFilter"+(year==0?" tierFilterSelected":"")} onClick={()=>setYear(0)}>All-time</span>|
            <span className={"tierFilter"+(year==2024?" tierFilterSelected":"")} onClick={()=>setYear(2024)}>2024</span>
            <span className={"tierFilter"+(year==2023?" tierFilterSelected":"")} onClick={()=>setYear(2023)}>2023</span>
            <span className={"tierFilter"+(year==2022?" tierFilterSelected":"")} onClick={()=>setYear(2022)}>2022</span>
            <span className={"tierFilter"+(year==2021?" tierFilterSelected":"")} onClick={()=>setYear(2021)}>2021</span>
            <span className={"tierFilter"+(year==2020?" tierFilterSelected":"")} onClick={()=>setYear(2020)}>2020</span>
            <span className={"tierFilter"+(year==2019?" tierFilterSelected":"")} onClick={()=>setYear(2019)}>2019</span>
            <span className={"tierFilter"+(year==2018?" tierFilterSelected":"")} onClick={()=>setYear(2018)}>2018</span>
            <span className={"tierFilter"+(year==2017?" tierFilterSelected":"")} onClick={()=>setYear(2017)}>2017</span>
        </div>
        <SearchableTable
            id="fleets"
            data={fleets}
            config={tableConfig}
            renderTableRow={(ranks, idx) => renderTableRow(ranks, idx)}
            //renderExpandedRow={(crew, idx) => renderExpandedRow(crew, idx)}
            filterRow={showThisFleet}
            showFilterOptions={false}
            showSearchExplanation={false}
            hideRarityFilter={true}
            defaultPerPage={10}
            clearSearch={true}
        />
        <br/>
        <p style={{color:'silver'}}>
            Fleets were introduced in 2017 and event #66 was the first one with a fleet leaderboard (Homestead -- August, 2017). 
        </p>
        <div className="adslot">
            {(window as any).adsbygoogle?.loaded && <span className='adlabel'>Advertisement</span>}
            {isMobileOnly && <AdSense.Google
                className="ad"
                client='ca-pub-5995456634193629'
                slot='3803400367'
                style={{ display: 'block'}}
                format='auto'
                responsive='true'
            />}
            {!isMobileOnly && <AdSense.Google
                className="ad adfixed"
                client='ca-pub-5995456634193629'
                slot='2961091989'
                style={{ display: 'inline-block', width: '970px', height: '250px', border: '1px solid #444444'}}
                format='fixed'
            />}
        </div>
        <Footer/>
    </div>
  );
}

export default Fleets;