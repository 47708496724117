import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the shape of the context
interface BackgroundContextType {
    backgroundImage: string;
    variant: number;
    setBackgroundImage: (image: string) => void;
    setVariant: (variant: number) => void;
}

// Create a Context with an empty default value
const BackgroundContext = createContext<BackgroundContextType | undefined>(undefined);

// Provider component to wrap around your app
export const BackgroundProvider = ({ children }: { children: ReactNode }) => {
    const [backgroundImage, setBackgroundImage] = useState<string>('');
    const [variant, setVariant] = useState<number>(0);

    return (
        <BackgroundContext.Provider value={{ backgroundImage, variant, setBackgroundImage, setVariant }}>
            {children}
        </BackgroundContext.Provider>
    );
};

// Custom hook to use the BackgroundContext
export const useBackground = () => {
    const context = useContext(BackgroundContext);
    if (!context) {
        throw new Error('useBackground must be used within a BackgroundProvider');
    }
    return context;
};
