import '../assets/fomantic/dist/semantic.css';
import { Label, Container, Table, LabelDetail, Icon, Statistic, Segment, Checkbox, Button } from 'semantic-ui-react';
import React from 'react';
import { Link } from '@reach/router';
import ReactGA from "react-ga4";
import { Slider, Grid, Input } from '@material-ui/core';
import * as localForage from 'localforage';

import { Helmet } from 'react-helmet';

import '../App.css';
import { NamerCrew, getCrew, getTierConfig, TierConfig, rarityLabel } from '../services/NamerStats';
import { SearchableTable, ITableConfigRow } from './SearchableTable';
import { Footer } from './Footer';
import { getColorForPercentage, logEvent, rarityClass, showThisCrew } from '../utils/utils';
import { StatType } from './FeaturedCrew';
import { isMobile } from 'react-device-detect';
import { getAssetURL } from '../App';

let customTierCount = 0;

export const Overall = (props: {
    navigation: () => void;
}) => {
    const statTypeFromStringMap = new Map<StatType, string>([ [StatType.Undefined, "undefined"],
        [StatType.Tier, "tier"], [StatType.Voyage, "voyage"], [StatType.Gauntlet, "gauntlet"], [StatType.Event, "event"],
        [StatType.Shuttle, "shuttle"], [StatType.Collection, "collection"], [StatType.Arena, "arena"], [StatType.Flavor, "flavor"]
    ]);
    const pageLinkFromStatMap = new Map<StatType, string>([ [StatType.Undefined, "/"],
        [StatType.Tier, "/"], [StatType.Voyage, "/ratings/voyage"], [StatType.Gauntlet, "/ratings/gauntlet"], [StatType.Event, "/ratings/event"],
        [StatType.Shuttle, "/ratings/shuttle"], [StatType.Collection, "/ratings/collection"], [StatType.Arena, "/ratings/arena"], [StatType.Flavor, "ratings/flavor"]
    ]);
    const [crew, setCrew] = React.useState<NamerCrew[]|undefined>(undefined);
    const [tierConfig, setTierConfig] = React.useState<Map<number,TierConfig>>(new Map<number,TierConfig>());
    const scoreTypes = ["voyage", "gauntlet", "event", "shuttle", "collection", "arena"];
    const [appliedTierWeights, setAppliedTierWeights] = React.useState<Map<number,Map<string,number>>>(new Map([[5,undefined],[4,undefined],[3,undefined],[2,undefined],[1,undefined]]));
    const [customTiers, setCustomTiers] = React.useState<boolean>(false);
    const [tierWeight, setTierWeight] = React.useState<Map<number,Map<string,number>>>();
    const [rarity, setRarity] = React.useState<number>(5);
    const [weightChanged, setWeightChanged] = React.useState<boolean>(false);
    const [weightsValid, setWeightsValid] = React.useState<boolean>(true);
    const [weightsApplied, setWeightsApplied] = React.useState<boolean>(false);
    const [weightLocked, setWeightLocked] = React.useState<Map<string,boolean>>(new Map(
        [["voyage", false], ["gauntlet", false], ["event", false], ["shuttle", false], ["collection", false], ["arena", false]]
    ));
    const [prevChangedWeight, setPrevChangedWeight] = React.useState<string>();
    const [prevWeightMap, setPrevWeightMap] = React.useState<Map<number,Map<string,number>>>();
    const [filterOnCustomTiers, setFilterOnCustomTiers] = React.useState<boolean>(false);

    React.useEffect(() => {
        props.navigation();
        // const appliedWeights = appliedTierWeights ?? new Map([[5,undefined],[4,undefined]]);
        // setAppliedTierWeights(appliedWeights);
        if (!crew) {
            getTierConfig().then((data) => {
                setTierConfig(data);
                
                if (!tierWeight) {
                    let weights = new Map([
                        [1, new Map([
                                ["voyage", data[1].scores["voyage"].weight*100],
                                ["gauntlet", data[1].scores["gauntlet"].weight*100],
                                ["event", data[1].scores["event"].weight*100],
                                ["shuttle", data[1].scores["shuttle"].weight*100],
                                ["collection", data[1].scores["collection"].weight*100],
                                ["arena", data[1].scores["arena"].weight*100],
                            ])
                        ],                    
                        [2, new Map([
                                ["voyage", data[2].scores["voyage"].weight*100],
                                ["gauntlet", data[2].scores["gauntlet"].weight*100],
                                ["event", data[2].scores["event"].weight*100],
                                ["shuttle", data[2].scores["shuttle"].weight*100],
                                ["collection", data[2].scores["collection"].weight*100],
                                ["arena", data[2].scores["arena"].weight*100],
                            ])
                        ],
                        [3, new Map([
                                ["voyage", data[3].scores["voyage"].weight*100],
                                ["gauntlet", data[3].scores["gauntlet"].weight*100],
                                ["event", data[3].scores["event"].weight*100],
                                ["shuttle", data[3].scores["shuttle"].weight*100],
                                ["collection", data[3].scores["collection"].weight*100],
                                ["arena", data[3].scores["arena"].weight*100],
                            ])
                        ],
                        [4, new Map([
                                ["voyage", data[4].scores["voyage"].weight*100],
                                ["gauntlet", data[4].scores["gauntlet"].weight*100],
                                ["event", data[4].scores["event"].weight*100],
                                ["shuttle", data[4].scores["shuttle"].weight*100],
                                ["collection", data[4].scores["collection"].weight*100],
                                ["arena", data[4].scores["arena"].weight*100],
                            ])
                        ],
                        [5, new Map([
                                ["voyage", data[5].scores["voyage"].weight*100],
                                ["gauntlet", data[5].scores["gauntlet"].weight*100],
                                ["event", data[5].scores["event"].weight*100],
                                ["shuttle", data[5].scores["shuttle"].weight*100],
                                ["collection", data[5].scores["collection"].weight*100],
                                ["arena", data[5].scores["arena"].weight*100],
                            ])
                        ]
                    ]);
                    setTierWeight(weights);
                    setPrevChangedWeight(undefined);
                }

                return data;})
                .then((tierConfig) =>
                    getCrew().then((data) => {
                        if (data && data.length > 0) {
                            // data = data.filter(x=>x.rarity>=4);
                            updateWeights(data, tierConfig);
                            data.sort((a,b) => (b.rarity - a.rarity) || ((appliedTierWeights.get(a.rarity) ? (b.tTotalCustomScore-a.tTotalCustomScore) : (b.tierScore-a.tierScore))));
                            setCrew(data);
                            getStoredWeights().then((weights: any) => {
                                if (weights && weights.size > 0) {
                                    setAppliedTierWeights(new Map(weights));
                                }
                            }).catch((e)=>{console.log(e)});
                        }            
            }));
        }
    }, []);

    const updateWeights = (data, tierConfig) => {
        data.forEach(x=> {
            x.customTier = 0;
            x.triplet = x.skills.join("/");
            x.collection = x.collections ? x.collections.length : 0;
            x.tCustomScores = new Map<string,number>();
            if (x.rarity != 4) {
                x.tCustomScores["voyage"] = Math.pow(x.vNRating,4) / Math.pow(5, 2);
                x.tCustomScores["gauntlet"] = Math.pow(x.gRating,4) / Math.pow(5, 2);
                x.tCustomScores["event"] = Math.pow(x.eRating,4) / Math.pow(5, 2);
                x.tCustomScores["shuttle"] = Math.pow(x.bRating,4) / Math.pow(5, 2);
                x.tCustomScores["collection"] = Math.pow(x.cNFinalRating,4) / Math.pow(5, 2);
                x.tCustomScores["arena"] = Math.pow(x.aRating,4) / Math.pow(5, 2);
            } else {
                x.tCustomScores["voyage"] = Math.pow(x.vNRating,4) / Math.pow(tierConfig[x.rarity].scores["voyage"].mean, 2);
                x.tCustomScores["gauntlet"] = Math.pow(x.gRating,4) / Math.pow(tierConfig[x.rarity].scores["gauntlet"].mean, 2);
                x.tCustomScores["event"] = Math.pow(x.eRating,4) / Math.pow(tierConfig[x.rarity].scores["event"].mean, 2);
                x.tCustomScores["shuttle"] = Math.pow(x.bRating,4) / Math.pow(tierConfig[x.rarity].scores["shuttle"].mean, 2);
                x.tCustomScores["collection"] = Math.pow(x.cNFinalRating,4) / Math.pow(tierConfig[x.rarity].scores["collection"].mean, 2);
                x.tCustomScores["arena"] = Math.pow(x.aRating,4) / Math.pow(tierConfig[x.rarity].scores["arena"].mean, 2);
            }
            x.tTotalCustomScore = 0;
            for (const score of scoreTypes) {
                if (appliedTierWeights.get(x.rarity)) {
                    x.tTotalCustomScore += x.tCustomScores[score] * appliedTierWeights.get(x.rarity).get(score)/100;
                } else {
                    x.tTotalCustomScore += x.tCustomScores[score] * tierConfig[x.rarity].scores[score].weight;
                }
            }
            for (const score of scoreTypes) {
                if (appliedTierWeights.get(x.rarity)) {
                    x.tCustomScores[score] = Math.floor(x.tCustomScores[score] * appliedTierWeights.get(x.rarity).get(score)/100/x.tTotalCustomScore*100);
                } else {
                    x.tCustomScores[score] = Math.floor(x.tCustomScores[score] * tierConfig[x.rarity].scores[score].weight /x.tTotalCustomScore*100);
                }
            }
        });
    }

    const updateCustomTiers = data => {
        data.sort((a,b) => (b.rarity - a.rarity) || ((appliedTierWeights.get(a.rarity) ? ((b.tTotalCustomScore-a.tTotalCustomScore) || (a.name > b.name)) : (b.tierScore-a.tierScore))));

        const updateCustomTiersPerRarity = r => {
            let currentTier = 1;
            let totalCurrentTier = 0;
            let popSize = data.filter(x=>x.rarity === r).length;
            data.forEach(x=>{
                if (x.rarity === r) {
                    if (!appliedTierWeights.get(r)) {
                        x.customTier = 0;
                    } else {
                        totalCurrentTier++;
                        if (totalCurrentTier > tierConfig[r]["dist"][currentTier]) {
                            totalCurrentTier = 1;
                            currentTier++;
                        }
                        x.customTier = currentTier;
                        
                    }
                }
            });
        };

        updateCustomTiersPerRarity(4);
        updateCustomTiersPerRarity(5);
        updateCustomTiersPerRarity(3);
        updateCustomTiersPerRarity(2);
        updateCustomTiersPerRarity(1);
    }

    const getStoredWeights = () => {
        return new Promise((resolve, reject) => {
            localForage.getItem("newTierWeights").then((localValue) => {
                if (!localValue) {
                    reject();
                }
                else {
                    const objectToMap = (o) => {
                        let m = new Map()
                        for(let k of Object.keys(o)) {
                            const key = isNaN(k as any) ? k : parseInt(k);
                            if(o[k] instanceof Object) {
                                m.set(key, objectToMap(o[k]))   
                            }
                            else {
                                m.set(key, o[k])
                            }    
                        }
                        return m
                    }
                    resolve(objectToMap(JSON.parse(localValue as string)));
                }
            });
        });
    };

    React.useEffect(() => {
        customTierCount++;
        let firstTimeTrue = (customTierCount === 1 && customTiers);
        if (customTierCount === 2 || firstTimeTrue) {
            if (firstTimeTrue) {
                customTierCount++;
            }
            ReactGA.event({category:"TierType", action:customTiers?"custom":"official"});
        }
    }, [customTiers]);

    React.useEffect(() => {
    
        if (!crew) {
            return;
        }
        setCustomTiers(appliedTierWeights.size > 0 && (appliedTierWeights.get(5) !== undefined || appliedTierWeights.get(4) !== undefined 
            || appliedTierWeights.get(3) !== undefined || appliedTierWeights.get(2) !== undefined || appliedTierWeights.get(1) !== undefined));
        const mapToObjectRec = (m) => {
            let lo = {}
            for(let[k,v] of m) {
                if(v instanceof Map) {
                    lo[k] = mapToObjectRec(v)
                }
                else {
                    lo[k] = v
                }
            }
            return lo
        }
        var convertedMap = mapToObjectRec(appliedTierWeights);
        localForage.setItem("newTierWeights", JSON.stringify(convertedMap, null, ' '));

        const data = [...crew];
        updateWeights(data, tierConfig);
        updateCustomTiers(data);

        if (appliedTierWeights.get(5)) {
            tierWeight.set(5, appliedTierWeights.get(5));
        }
        if (appliedTierWeights.get(4)) {
            tierWeight.set(4, appliedTierWeights.get(4));
        }
        if (appliedTierWeights.get(3)) {
            tierWeight.set(3, appliedTierWeights.get(3));
        }
        if (appliedTierWeights.get(2)) {
            tierWeight.set(2, appliedTierWeights.get(2));
        }
        if (appliedTierWeights.get(1)) {
            tierWeight.set(1, appliedTierWeights.get(1));
        }
        setTierWeight(new Map(tierWeight));

        setCrew(data);
    }, [appliedTierWeights]);

    if (!crew) {
        return <Container><div className="center ui active loader"/></Container>;
    }

    const tableConfig: ITableConfigRow[] = [
        { width: 4, column: 'name', title: 'Crew' },
        { width: 1, column: 'customTier', title: 'Tier', secondaryColumn: 'nTierScore', class: 'row-header'},
        { width: 1, column: 'vNRank', title: 'Voyage', secondaryColumn: 'nTierScore', class: 'row-header'},
        { width: 1, column: 'gNRank', title: 'Gauntlet', secondaryColumn: 'nTierScore', class: 'row-header'},
        { width: 1, column: 'eRatingR', title: 'Event', secondaryColumn: 'nTierScore', class: 'row-header'},
        { width: 1, column: 'bRatingRank', title: 'Shuttle', secondaryColumn: 'nTierScore', class: 'row-header'},
        { width: 1, column: 'cNFinalRating', title: 'Collection', secondaryColumn: 'nTierScore', class: 'row-header'},
        { width: 1, column: 'aRank', title: 'Arena', secondaryColumn: 'nTierScore', class: 'row-header'}
    ]

    const descriptionLabel = (crew: NamerCrew) => {
        return <span style={{fontSize:"small"}}>
            <Label mini="true" basic horizontal className="mono">
                {crew.triplet}
            </Label>
            <Label mini="true" basic horizontal className="miniLD">events<LabelDetail className="miniLD">{crew.events}</LabelDetail></Label>
            <Label mini="true" basic horizontal className="miniLD">collections<LabelDetail className="miniLD">{crew.collection}</LabelDetail></Label>
        </span>
    }

    const getScorecardLink = (crew: NamerCrew, stat: StatType) => {
        return pageLinkFromStatMap.get(stat)+"?loc="+crew.symbol;
    }

    const logScorecard = (crew: NamerCrew, stat: StatType) => {
        logEvent("CrewScorecardOverall", statTypeFromStringMap.get(stat), crew.symbol);
    }

    const renderTableRow = (crew: NamerCrew, idx: number) => {
    return (
        <React.Fragment>
            <Table.Cell>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '60px auto',
                        gridTemplateAreas: `'icon stats' 'icon description'`,
                        gridGap: '1px'
                    }}
                >
                    <div style={{ gridArea: 'icon' }}>
                        <img alt={crew.name} width={48} src={getAssetURL(crew.portrait)} />
                    </div>
                    <div style={{ gridArea: 'stats' }} className={rarityClass(crew.rarity)}>
                        <Link className={rarityClass(crew.rarity)}
                            to={"/crew/"+crew.symbol}
                            style={{ fontWeight: 'bolder', fontSize: '1.25em' }}
                            onClick={()=>logEvent("CrewLink", "overall", crew.symbol)}>
                            {crew.name}
                        </Link>
                    </div>
                    <div style={{ gridArea: 'description' }}>{descriptionLabel(crew)}</div>
                </div>
            </Table.Cell>
            {crew.customTier > 0 && crew.customTier != crew.tier && <Table.Cell style={{ textAlign: 'center' }} className="tier">
                <div className="tierInner">
                    {crew.customTier > 0 ? crew.customTier : crew.tier}
                    {crew.customTier > 0 && <small style={{ fontSize: '60%' }}>
                        {crew.customTier<crew.tier && <span><Icon size="small" name="caret up" color="green" style={{marginRight:"0px"}}/>{crew.tier-crew.customTier}</span>}
                        {crew.customTier>crew.tier && <span><Icon size="small" name="caret down" color="red" style={{marginRight:"0px"}}/>{crew.customTier-crew.tier}</span>}
                        {crew.customTier===crew.tier && <Icon size="small" name="minus" style={{marginLeft:"3px"}}/>}
                    </small>}
                </div>
            </Table.Cell>}
            {(crew.customTier == 0 || crew.customTier == crew.tier) && <Table.Cell style={{ textAlign: 'center' }}>
                <span className="largeFont">{(!crew.portal && crew.obtained == "WebStore") ? "$" : crew.tier}</span>
            </Table.Cell>}            
            <Table.Cell style={{ textAlign: 'center' }}>
                <Statistic inverted size="tiny">
                    <Statistic.Label><b>#{crew.vNRank}</b></Statistic.Label>
                    <Statistic.Value><Link to={getScorecardLink(crew, StatType.Voyage)}
                            onClick={()=>logScorecard(crew, StatType.Voyage)} 
                            style={{ fontSize: '100%', cursor:"pointer", color: getColorForPercentage((crew.vNRating ?? 0)/10, StatType.Voyage, crew.rarity) }}>
                            {crew.vNRating}
                        </Link>
                    </Statistic.Value>
                    <Statistic.Label><b>{crew.tCustomScores["voyage"]}%</b></Statistic.Label>
                </Statistic>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <Statistic inverted size="tiny">
                    <Statistic.Label><b>#{crew.gNRank}</b></Statistic.Label>
                    <Statistic.Value><Link to={getScorecardLink(crew, StatType.Gauntlet)}
                            onClick={()=>logScorecard(crew, StatType.Gauntlet)} 
                            style={{ fontSize: '100%', cursor:"pointer", color: getColorForPercentage((crew.gRating ?? 0)/10, StatType.Gauntlet, crew.rarity) }}>
                            {crew.gRating}
                        </Link>
                    </Statistic.Value>
                    <Statistic.Label><b>{crew.tCustomScores["gauntlet"]}%</b></Statistic.Label>
                </Statistic>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <Statistic inverted size="tiny">
                    <Statistic.Label><b>#{crew.eRatingR}</b></Statistic.Label>
                    <Statistic.Value><Link to={getScorecardLink(crew, StatType.Event)}
                            onClick={()=>logScorecard(crew, StatType.Event)} 
                            style={{ fontSize: '100%', cursor:"pointer", color: getColorForPercentage((crew.eRating ?? 0)/10, StatType.Event, crew.rarity) }}>
                            {crew.eRating}
                        </Link>
                    </Statistic.Value>
                    <Statistic.Label><b>{crew.tCustomScores["event"]}%</b></Statistic.Label>
                </Statistic>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <Statistic inverted size="tiny">
                    <Statistic.Label><b>#{crew.bRatingRank}</b></Statistic.Label>
                    <Statistic.Value><Link to={getScorecardLink(crew, StatType.Shuttle)}
                            onClick={()=>logScorecard(crew, StatType.Shuttle)} 
                            style={{ fontSize: '100%', cursor:"pointer", color: getColorForPercentage((crew.bRating ?? 0)/10, StatType.Shuttle, crew.rarity) }}>
                            {crew.bRating}
                        </Link>
                    </Statistic.Value>
                    <Statistic.Label><b>{crew.tCustomScores["shuttle"]}%</b></Statistic.Label>
                </Statistic>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <Statistic inverted size="tiny">
                    <Statistic.Label><b>#{crew.cNFinalRank}</b></Statistic.Label>
                    <Statistic.Value><Link to={getScorecardLink(crew, StatType.Collection)}
                            onClick={()=>logScorecard(crew, StatType.Collection)} 
                            style={{ fontSize: '100%', cursor:"pointer", color: getColorForPercentage((crew.cNFinalRating ?? 0)/10, StatType.Collection, crew.rarity) }}>
                            {crew.cNFinalRating}
                        </Link>
                    </Statistic.Value>
                    <Statistic.Label><b>{crew.tCustomScores["collection"]}%</b></Statistic.Label>
                </Statistic>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
            <Statistic inverted size="tiny">
                    <Statistic.Label><b>#{crew.aRank}</b></Statistic.Label>
                    <Statistic.Value><Link to={getScorecardLink(crew, StatType.Arena)}
                            onClick={()=>logScorecard(crew, StatType.Arena)} 
                            style={{ fontSize: '100%', cursor:"pointer", color: getColorForPercentage((crew.aRating ?? 0)/10, StatType.Arena, crew.rarity) }}>
                            {crew.aRating}
                        </Link>
                    </Statistic.Value>
                    <Statistic.Label><b>{crew.tCustomScores["arena"]}%</b></Statistic.Label>
                </Statistic>
            </Table.Cell> 
        </React.Fragment>
    );
    }

    

    const changeWeight = (value, r, type) => {
        if (!value) {
            value = 0;
        }
        if (value < 0) {
            value = 0;
        }
        if (value > 100) {
            value = 100;
        }

        let prevWeights = prevWeightMap;
        if (!prevWeights || type !== prevChangedWeight) {
            prevWeights = new Map([[r, new Map(tierWeight.get(r))]]);
            setPrevChangedWeight(type);
            setPrevWeightMap(prevWeights);
        }
        
        let tierWeightR = tierWeight.get(r);
        let prevTierWeightR = prevWeights.get(r);
        let totalWeights = 0;
        let totalPrevUnlockedWeights = 0;
        let unlockedCount = 0;

        for (const stype of scoreTypes) {
            if (type !== stype) {
                if (!weightLocked.get(stype)) {
                    totalPrevUnlockedWeights += prevTierWeightR.get(stype);
                    unlockedCount++;
                }
            }
            totalWeights += prevTierWeightR.get(stype);
        }

        let delta = value - prevTierWeightR.get(type);
        if (delta > 0 && delta > totalPrevUnlockedWeights) {
            delta = totalPrevUnlockedWeights;
            value = prevTierWeightR.get(type) + delta;
            if (totalPrevUnlockedWeights === 0) {
                return;
            }
        } else if (delta < 0 && unlockedCount === 0) {
            delta = 0;
            value = tierWeight.get(r);
            return;
        }

        tierWeightR.set(type, value);
        
        let newTotalWeights = 0;
        for (const stype of scoreTypes) {
            if (type !== stype && !weightLocked.get(stype)) {
                if (totalPrevUnlockedWeights !== 0) { 
                    tierWeightR.set(stype, Math.floor(prevTierWeightR.get(stype) * (totalPrevUnlockedWeights - delta) / totalPrevUnlockedWeights));
                }
            }
            newTotalWeights += tierWeightR.get(stype);
        }

        for (const stype of scoreTypes) {
            if (type !== stype && !weightLocked.get(stype)) {
                let val = tierWeightR.get(stype);
                if (newTotalWeights < 100 && val < 100) {
                    tierWeightR.set(stype, val + 1);
                    newTotalWeights += 1;
                } else if (newTotalWeights > 100 && val > 0) {
                    tierWeightR.set(stype, val - 1);
                    newTotalWeights -= 1;
                }
            }
        }

        totalWeights=0;
        let totalOtherWeights=0;
        for (const stype of scoreTypes) {
            totalWeights += tierWeightR.get(stype);
            if (stype !== type) {
                totalOtherWeights += tierWeightR.get(stype);
            }
        }

        if (totalWeights !== 100) {
            tierWeightR.set(type, 100-totalOtherWeights);
            totalWeights = 100;
        }
        
        setTierWeight(new Map(tierWeight));
        setWeightChanged(true);
        setWeightsApplied(false);
        setWeightsValid(totalWeights === 100);
    }

    const handleValueChange = (e, r, type) => {
        let value = parseInt(e.target.value);
        changeWeight(value, r, type);
    };

    const applyWeights = (r) => {
        if (appliedTierWeights && appliedTierWeights.size > 0) {
            appliedTierWeights.set(r, tierWeight.get(r));
            setAppliedTierWeights(new Map(appliedTierWeights));
        } else {
            let newWeights = new Map([[r, tierWeight.get(r)]]);
            setAppliedTierWeights(new Map(newWeights));
        }
        setWeightChanged(false);
        setWeightsApplied(true);
    };

    const resetWeights = (r) => {
        let isCustom = appliedTierWeights.size > 0 && appliedTierWeights.get(r);
        if (isCustom) {
            tierWeight.set(r, appliedTierWeights.get(r));
            setTierWeight(new Map(tierWeight));
        } else {
            for (const score of scoreTypes) {
                tierWeight.get(r).set(score, tierConfig[r].scores[score].weight*100);
                setTierWeight(new Map(tierWeight));
                
            }
        }
        setPrevChangedWeight(undefined);
        setWeightChanged(false);
        setWeightsApplied(false);
        setWeightsValid(true);
        setWeightLocked(new Map(
            [["voyage", false], ["gauntlet", false], ["event", false], ["shuttle", false], ["collection", false], ["arena", false]]
        ));
    }

    function titleCase(string){
        return string[0].toUpperCase() + string.slice(1).toLowerCase();
    }

    const getSliders = r => {
        return scoreTypes.map((type, idx) =>
        <Grid container spacing={2} alignItems="center" key={idx} className="weightSlider" style={isMobile ? {width:"350px"} : {width:"700px"}}>
            <Grid item className="sliderLabel">
                {titleCase(type)}
                <small style={{ fontSize: '100%' }}>&mu;={tierConfig[r].scores[type].mean}</small>
            </Grid>
            <Grid item>
                {weightLocked.get(type) && <Icon className="lockSlider" name="lock" color="grey" onClick={()=>{weightLocked.set(type, false); setWeightLocked(new Map(weightLocked));}}/>}
                {!weightLocked.get(type) && <Icon className="lockSlider" name="unlock" onClick={()=>{weightLocked.set(type, true); setWeightLocked(new Map(weightLocked));}}/>}
            </Grid>
            <Grid item xs>
                <Slider
                    disabled={weightLocked.get(type)}
                    value={tierWeight && tierWeight.get(r) ? tierWeight.get(r).get(type) : 0}
                    onChange={(_, value) => changeWeight(value, r, type)}
                    aria-labelledby="input-slider"
                    marks={[{value:tierConfig[r].scores[type].weight*100}]}
                />
            </Grid>
            <Grid item>
            <Input
                disabled={weightLocked.get(type)}
                className="sliderInput"
                value={tierWeight && tierWeight.get(r) ? tierWeight.get(r).get(type) : 0}
                onChange={(e) => handleValueChange(e, r, type)}
                inputProps={{
                    step: 1,
                    min: 0,
                    max: 100,
                    type: 'number',
                    'aria-labelledby': 'input-slider',
                }}
            />%
            </Grid>
        </Grid>
    )};

    /*return scoreTypes.map((type, idx) => 
<div key={"slider"+idx} className="weightSlider">
    <Input className="sliderInput" labelPosition='right' type='text'>
        <Label basic style={{width:"90px"}}>{titleCase(type)}</Label>
        <input style={{width:"55px"}} value={tierWeight && tierWeight.get(r) ? tierWeight.get(r).get(type) : 0} onInput={(e) => handleValueChange(e, r, type)}/>
        <Label>%</Label>
    </Input>
    <Slider value={tierWeight && tierWeight.get(r) ? tierWeight.get(r).get(type) : 0} min={0} max={100} onChange={(_, value) => changeWeight(value, r, type)}/>
</div>*/
           
  const title = "Overall ratings - The Big Book of Behold Advice";
  const link = "https://www.bigbook.app/ratings/overall";
  const description = "Star Trek Timelines crew rankings with tiers derived from utility ratings in all areas of the game. Custom tiers can be calculated by adjusting a set of weights, one for each rating";

  return (
    <div>
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={link} />
            <meta name="description" content={description}/>
            <meta property="og:url" content={link}/>
            <meta property="og:description" content={description}/>
            <meta name="twitter:url" content={link}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
        </Helmet>
        <SearchableTable
            id="overall_crew"
            data={filterOnCustomTiers ? crew.filter(x=>x.customTier != x.tier && x.customTier != 0) : crew}
            config={tableConfig}
            renderTableRow={(crew, idx) => renderTableRow(crew, idx)}
            filterRow={showThisCrew}
            showFilterOptions={false}
            showSearchExplanation={true}
            showTierFilter={true}
            querySuggestions={true}
        />
        <br />
        <Checkbox toggle checked={customTiers} onClick={()=>{
                setTierWeight(new Map([
                    [1, new Map([
                            ["voyage", tierConfig[1].scores["voyage"].weight*100],
                            ["gauntlet", tierConfig[1].scores["gauntlet"].weight*100],
                            ["event", tierConfig[1].scores["event"].weight*100],
                            ["shuttle", tierConfig[1].scores["shuttle"].weight*100],
                            ["collection", tierConfig[1].scores["collection"].weight*100],
                            ["arena", tierConfig[1].scores["arena"].weight*100],
                        ])
                    ],
                    [2, new Map([
                            ["voyage", tierConfig[2].scores["voyage"].weight*100],
                            ["gauntlet", tierConfig[2].scores["gauntlet"].weight*100],
                            ["event", tierConfig[2].scores["event"].weight*100],
                            ["shuttle", tierConfig[2].scores["shuttle"].weight*100],
                            ["collection", tierConfig[2].scores["collection"].weight*100],
                            ["arena", tierConfig[2].scores["arena"].weight*100],
                        ])
                    ],
                    [3, new Map([
                            ["voyage", tierConfig[3].scores["voyage"].weight*100],
                            ["gauntlet", tierConfig[3].scores["gauntlet"].weight*100],
                            ["event", tierConfig[3].scores["event"].weight*100],
                            ["shuttle", tierConfig[3].scores["shuttle"].weight*100],
                            ["collection", tierConfig[3].scores["collection"].weight*100],
                            ["arena", tierConfig[3].scores["arena"].weight*100],
                        ])
                    ],
                    [4, new Map([
                            ["voyage", tierConfig[4].scores["voyage"].weight*100],
                            ["gauntlet", tierConfig[4].scores["gauntlet"].weight*100],
                            ["event", tierConfig[4].scores["event"].weight*100],
                            ["shuttle", tierConfig[4].scores["shuttle"].weight*100],
                            ["collection", tierConfig[4].scores["collection"].weight*100],
                            ["arena", tierConfig[4].scores["arena"].weight*100],
                        ])
                    ],
                    [5, new Map([
                            ["voyage", tierConfig[5].scores["voyage"].weight*100],
                            ["gauntlet", tierConfig[5].scores["gauntlet"].weight*100],
                            ["event", tierConfig[5].scores["event"].weight*100],
                            ["shuttle", tierConfig[5].scores["shuttle"].weight*100],
                            ["collection", tierConfig[5].scores["collection"].weight*100],
                            ["arena", tierConfig[5].scores["arena"].weight*100],
                        ])
                    ]
                ]));
                if (customTiers) {
                    setPrevChangedWeight(undefined);
                    setAppliedTierWeights(new Map([[5,undefined],[4,undefined],[3,undefined],[2,undefined],[1,undefined]]));
                }
                setRarity(5);
                setWeightLocked(new Map(
                    [["voyage", false], ["gauntlet", false], ["event", false], ["shuttle", false], ["collection", false], ["arena", false]]
                ));
                setCustomTiers(!customTiers);
                setFilterOnCustomTiers(false);
            }} 
            label="Personalize score weights to calculate custom tiers"/>
        {customTiers && <Segment compact basic disabled={!customTiers}>
            
            <div className="rarityfilterSlider">
				<span>Adjust weights for:{' '}</span>
				<span className={"rarityfilter-5"+(rarity===5?" rarityselected":"")} 
                    onClick={()=>{ if (rarity===5) {return;} resetWeights(5); setRarity(5); }}>Legendary</span>
				<span className={"rarityfilter-4"+(rarity===4?" rarityselected":"")}
                    onClick={()=>{ if (rarity===4) {return;} resetWeights(4); setRarity(4); }}>Super Rare</span>
                <span className={"rare rarityfilter-3"+(rarity===3?" rarityselected":"")}
                    onClick={()=>{ if (rarity===3) {return;} resetWeights(3); setRarity(3); }}>Rare</span>
                <span className={"uncommon rarityfilter-2"+(rarity===2?" rarityselected":"")}
                    onClick={()=>{ if (rarity===2) {return;} resetWeights(2); setRarity(2); }}>Uncommon</span>
                <span className={"common rarityfilter-1"+(rarity===1?" rarityselected":"")}
                    onClick={()=>{ if (rarity===1) {return;} resetWeights(1); setRarity(1); }}>Common</span>
			</div>
            {rarity === 5 && getSliders(5)}
            {rarity === 4 && getSliders(4)}
            {rarity === 3 && getSliders(3)}
            {rarity === 2 && getSliders(2)}
            {rarity === 1 && getSliders(1)}
            <div className="sliderButtons">
                <Button compact disabled={!weightChanged || !weightsValid} onClick={()=>applyWeights(rarity)}>
                    Apply weights for {rarityLabel(rarity)} crew
                </Button>
                {weightsApplied && <Icon name="checkmark" size="small"/>}
            </div>
            <Checkbox style={{marginLeft:"10px",marginTop:"15px"}} toggle label="Only show crew with a custom tier different than the default tier" checked={filterOnCustomTiers} onClick={() =>{
                setFilterOnCustomTiers(!filterOnCustomTiers)
            }}/>
        </Segment>}
        <Footer/>
    </div>
  );
}

export default Overall;