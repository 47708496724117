import '../assets/fomantic/dist/semantic.css';
import { Container, Table } from 'semantic-ui-react';
import React from 'react';
import { Link } from '@reach/router';
import { Helmet } from 'react-helmet';

import '../App.css';
import { NamerCrew, getCrew } from '../services/NamerStats';
import { SearchableTable, ITableConfigRow } from '../components/SearchableTable';
import { Footer } from './Footer';
import VoyageStats from './CrewStats/VoyageStats';
import { logEvent, rarityClass, showThisCrew } from '../utils/utils';
import { getAssetURL } from '../App';

export const Voyagers = (props: {
    navigation: () => void;
}) => {
    const [crew, setCrew] = React.useState<NamerCrew[]|undefined>(undefined);

    React.useEffect(() => {
        props.navigation();
        getCrew().then((data) => {
            if (data && data.length > 0) {
                // data = data.filter(x=>x.rarity>=4);
                data.forEach(x=> {
                    if (!x.portrait || x.portrait === "") {
                        x.portrait = "fleet_battle_scenarios_practice_space_bg.webp";
                    }
                    x.triplet = x.skills.join("/");
                    x.collection = x.collections ? x.collections.length : 0;
                });
                //data.sort((a,b) => (a.rarity != b.rarity) ? (b.rarity-a.rarity) : (a.vNRank-b.vNRank));
                data.sort((a,b) => b.rarity-a.rarity || a.vNRank - b.vNRank);
                setCrew(data);
            }            
        });
    }, []);

    if (!crew) {
        return <Container><div className="center ui active loader"/></Container>;
    }

    const tableConfig: ITableConfigRow[] = [
        { width: 3, column: 'name', title: 'Crew', pseudocolumns: ['name', 'events', 'collection'] },
        { width: 3, column: 'bestseats', title: 'Best seats'},
        { width: 1, column: 'vNRank', title: 'Rating', class: 'row-header'},
        { width: 1, column: 'voyageScore', title: 'Voy Rank', class: 'row-header'},
        { width: 1, column: 'vRankR', title: 'Unboosted', class: 'row-header'},
        { width: 1, column: 'vRank', title: '1yr Voy Rank', class: 'row-header'},
        { width: 1, column: 'vAMChance', title: 'AM chance', class: 'row-header', secondaryColumn: 'vNRank'},
    ]

    const descriptionLabel = (crew: NamerCrew) => {
        let desc = crew.events + " event" + (crew.events !== 1 ? "s" : "") 
        if (crew.collections) {
            desc += ", " + crew.collections.length + " collection" + (crew.collections.length !== 1 ? "s" : ""); 
        }
        return desc;
    }

    const bestSeats = (crew: NamerCrew) => {
        if (crew.skills.length < 3) {
            return "none"
        }
        let bestRank = 10000;
        let traits: string[] = [];
        for (const trait of  Object.keys(crew.vSeating)) {
            let seat = crew.vSeating[trait];
            if (seat.ranks.triplet < bestRank) {
                bestRank = seat.ranks.triplet;
                traits = [];
            }
            if (seat.ranks.triplet === bestRank) {
                traits.push(trait);
            }
        }

        if (bestRank === 10000) {
            return "none"
        }
    
        return (<div>
            {"#" + bestRank + " " + crew.triplet}<br/>
            <small style={{ fontSize: '85%' }}>{traits.join(", ")}</small>
        </div>)
    }

  const renderTableRow = (crew: NamerCrew, idx: number) => {
    return (
        <React.Fragment>
            <Table.Cell>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '60px auto',
                        gridTemplateAreas: `'icon stats' 'icon description'`,
                        gridGap: '1px'
                    }}
                >
                    <div style={{ gridArea: 'icon' }}>
                        <img alt={crew.name} width={48} src={getAssetURL(crew.portrait)} />
                    </div>
                    <div style={{ gridArea: 'stats' }} className={rarityClass(crew.rarity)}>
                        <Link className={rarityClass(crew.rarity)}
                            to={"/crew/"+crew.symbol}
                            style={{ fontWeight: 'bolder', fontSize: '1.25em' }}
                            onClick={()=>logEvent("CrewLink", "voyage", crew.symbol)}>
                            {crew.name}
                        </Link>
                    </div>
                    <div style={{ gridArea: 'description' }}>{descriptionLabel(crew)}</div>
                </div>
            </Table.Cell>
            <Table.Cell>
                {bestSeats(crew)}
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>#{crew.vNRank}</b><br />
                <small style={{ fontSize: '100%' }}>{crew.vNRating}</small>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>#{crew.vRank}</b><br />
                <small style={{ fontSize: '80%' }}>{crew.voyageScore.toFixed(0)}</small>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>#{crew.vRankR}</b><br />
                <small style={{ fontSize: '80%' }}>{crew.vScoreR.toFixed(0)}</small>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {crew.vProjections && <React.Fragment>
                    <b>#{crew.vProjections ? crew.vProjections[1].avg : "n/a"}</b><br />
                    {crew.vProjections && <small style={{ fontSize: '80%' }}>({crew.vProjections[1].min}-{crew.vProjections[1].max})</small>}
                </React.Fragment>}
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                 <b>#{crew.vAMRank}</b><br />
                <small style={{ fontSize: '80%' }}>{(crew.vAMChance*100).toFixed(2)}%</small>
            </Table.Cell>
        </React.Fragment>
    );
  }

  const renderExpandedRow = (crew: NamerCrew, idx: number) => {
    return (
        <React.Fragment>
            <Table.Cell colSpan={10} className="expanded-cell">
                <VoyageStats crew={crew} showAd={true}/>    
            </Table.Cell>
        </React.Fragment>
    )
  }  

  const title = "Voyage ratings - The Big Book of Behold Advice";
  const link = "https://www.bigbook.app/ratings/voyage";
  const description = "Star Trek Timelines voyage utility ratings based on skill set, order, voyage traits and antimatter bonus chance";

  return (
    <div>
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={link} />
            <meta name="description" content={description}/>
            <meta property="og:url" content={link}/>
            <meta property="og:description" content={description}/>
            <meta name="twitter:url" content={link}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
        </Helmet>

        <SearchableTable
            id="voyager_crew"
            data={crew}
            config={tableConfig}
            renderTableRow={(crew, idx) => renderTableRow(crew, idx)}
            renderExpandedRow={(crew, idx) => renderExpandedRow(crew, idx)}
            filterRow={showThisCrew}
            showFilterOptions={false}
            showSearchExplanation={true}
            querySuggestions={true}
        />
        <Footer/>
    </div>
  );
}

export default Voyagers;