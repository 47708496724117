import '../../assets/fomantic/dist/semantic.css';
import { Icon, List, Statistic, StatisticGroup } from 'semantic-ui-react';
import AdSense from 'react-adsense';

import '../../App.css';
import { getCondensedStat, ShipAction } from '../../services/NamerStats';
import { getAbilityNameAndIcon, getShipAbilityNameAndIcon, getShipBoostElement, getShipChargePhases, getShipTriggerNameAndIcon } from '../../utils/utils';
import { getAssetURL } from '../../App';
import { prototype } from 'stream';

export const ShipActionInfo = (props: {
    action: ShipAction;
}) => {
    const getShipAbility = () => {
        if (props.action.bonus_amount === 0) {
            return;
        }
        const [icon, desc] = getShipAbilityNameAndIcon(props.action, props.action.ability.amount);
        return <div className="sbAbility">
            <img style={{maxHeight:"25px",maxWidth:"25px"}} src={getAssetURL(icon+".png")} alt=""/>
            <span>{desc}</span>
        </div>
    }

    const [sIcon, sAlt, sTitle] = getShipTriggerNameAndIcon(props.action.status);

    return <div className={"sbCharge"+props.action.bonus_type+" shipAction"}>
            <div className="shipA1"><b>{props.action.name}</b></div>
            <div className="shipA2">{getShipBoostElement(
                props.action.bonus_amount, props.action.bonus_type)}</div>
            {props.action.ability && <div className="shipA3">{getShipAbility()}</div>}
            {props.action.status && <div className="shipA4">
                Grants
                <img style={{maxWidth:"16px", maxHeight:"16px"}} src={getAssetURL(sIcon)} alt={sAlt} title={sTitle}/>
                {sTitle}
            </div>}
            <div className="shipA5">{getShipChargePhases(props.action)}</div>
            <div className="shipA6">
                <b>Init</b>:{props.action.initial_cooldown}{' '}
                <b>Dur</b>:{props.action.duration}{' '}
                <b>CD</b>:{props.action.cooldown}{' '}
                <b>Cy</b>:{props.action.cycle}{' '}
                {props.action.limit!=1 && <span><b>Up</b>:{props.action.uptime}{'% '}</span>}
                {props.action.limit > 0 && <span style={{border:"1px solid gray"}}>
                    <b>Limit</b>:{props.action.limit}{' '}
                </span>}
                {props.action.penalty && props.action.penalty.amount > 0 && <span>
                    <b>Penalty</b>:{getShipBoostElement(-props.action.penalty.amount, props.action.penalty.type)}{' '}
                </span>}
            </div>
        </div>
  }

  export default ShipActionInfo;