import '../assets/fomantic/dist/semantic.css';
import { Container, Grid, Icon, List, Popup, Table } from 'semantic-ui-react';
import { Link } from '@reach/router';
import React from 'react';
import { Helmet } from 'react-helmet';
import AdSense from 'react-adsense';
import ReactGA from 'react-ga4';

import '../App.css';
import { NamerCrew, getCrew, CrewNote, ShipInfo, getShipInfo, getCondensedStat, getCritChance, getAttackWithBonus } from '../services/NamerStats';
import { SearchableTable, ITableConfigRow } from './SearchableTable';
import { Footer } from './Footer';
import { getAbilityNameAndIcon, getShipAbilityNameAndIcon, getShipBoostElement, getShipTriggerNameAndIcon, logEvent, rarityClass, showThisCrew, showThisShip } from '../utils/utils';
import { getAssetURL } from '../App';
import { ShipSearchableTable } from './ShipSearchableTable';
import ShipActionInfo from './ShipStats/ShipAction';
import { isMobileOnly } from 'react-device-detect';

export const Ships = (props: {
    navigation: () => void;
}) => {
    const [ships, setShips] = React.useState<ShipInfo[]|undefined>(undefined);
    
    React.useEffect(() => {
        props.navigation();
        if (!ships) {
            getShipInfo().then(data=>{
                let list = Array.from(Object.values(data));
                list.forEach((x:ShipInfo) => {
                    x.cAttack = getCondensedStat(x.attack);
                    x.cEvasion = getCondensedStat(x.evasion);                    
                    x.cAccuracy = getCondensedStat(x.accuracy);
                    x.crit = getCritChance(x.crit_chance)
                    x.attack10 = getAttackWithBonus(x.attack, 10); 
                    x.dps = Math.floor(x.attacks_per_second * x.attack10 * (
                        (1-x.crit/100) +
                        (1+x.crit_bonus/10000)*x.crit/100))
                    x.aSearch = [];
                    x.aSearch.push(...x.traits);
                    for (let i=0; i<x.actions.length; i++) {
                        x.aSearch.push(x.actions[i].name);
                        if (x.actions[i].status) {
                            if (!x.aSearch.find(x=>x=="status"))
                                x.aSearch.push("status");
                            const [_, alt, __] = getShipTriggerNameAndIcon(x.actions[i].status);
                            x.aSearch.push(alt);
                        }
                        if (x.actions[i].penalty && x.actions[i].penalty.amount > 0) {
                            if (!x.aSearch.find(x=>x=="penalty"))
                                x.aSearch.push("penalty");
                        }
                        if (x.actions[i].limit > 0) {
                            if (!x.aSearch.find(x=>x=="limit"))
                                x.aSearch.push("limit");
                        }
                        if (x.actions[i].ability && x.actions[i].ability.amount > 0) {
                            const [_, desc] = getShipAbilityNameAndIcon(x.actions[i], x.actions[i].ability.amount);
                            x.aSearch.push(desc);
                        }
                        if (x.actions[i].charge_phases && x.actions[i].charge_phases.length > 0) {
                            if (!x.aSearch.find(x=>x=="charges")) x.aSearch.push("charges");
                            if (!x.aSearch.find(x=>x=="phases")) x.aSearch.push("phases");
                            for (const charge of x.actions[i].charge_phases) {
                                const [_, desc] = getShipAbilityNameAndIcon(x.actions[i], charge.ability_amount);
                                x.aSearch.push(desc);
                            }
                        }
                        x.actions[i].uptime = Math.round(100*(x.actions[i].duration / (x.actions[i].duration + x.actions[i].cooldown)))
                        x.actions[i].cycle = x.actions[i].duration + x.actions[i].cooldown;
                    }
                });
                list.sort((a,b)=>(b.dps-a.dps));
                setShips(list);
            });
        }
    }, []);

    if (!ships) {
        return <Container><div className="center ui active loader"/></Container>;
    }

    const DPSExplanation = () => (
        <Popup wide trigger={<Icon className="infoIcon" size='small' name="info" />} header={'Damage per second'}
            content={'The DPS is calculated assuming a +10 ATT boost crew is active and it takes into account the ship\'s attack, crit chance and crit bonus.'}/>
      );

    const tableConfig: ITableConfigRow[] = [
        { width: 4, column: 'name', title: 'Ship' },
        { width: 1, column: 'attacks_per_second', title: 'Attacks', secondaryColumn: 'dps', class: 'row-header'},
        { width: 1, column: 'dps', title: <span>DPS <DPSExplanation/></span>, class: 'row-header'},
        { width: 1, column: 'attack', title: 'ATT', secondaryColumn: 'dps', class: 'row-header'},
        { width: 1, column: 'evasion', title: 'EV', secondaryColumn: 'dps', class: 'row-header'},
        { width: 1, column: 'accuracy', title: 'ACC', secondaryColumn: 'dps', class: 'row-header'},
        { width: 1, column: 'hull', title: 'Hull', secondaryColumn: 'dps', class: 'row-header'},  
        { width: 1, column: 'shields', title: 'Shields', secondaryColumn: 'dps', class: 'row-header'}, 
        { width: 1, column: 'shield_regen', title: 'ShRegen', secondaryColumn: 'dps', class: 'row-header'}, 
        { width: 1, column: 'crit_chance', title: 'Crit Rating', secondaryColumn: 'dps', class: 'row-header'}, 
        { width: 1, column: 'crit_bonus', title: 'Crit Bonus', secondaryColumn: 'dps', class: 'row-header'}, 
        { width: 1, column: 'antimatter', title: 'AM', secondaryColumn: 'dps', class: 'row-header'}, 
        { width: 4, column: 'battle_stations.length', title: 'Stations', secondaryColumn: 'dps', class: 'row-header'}, 
    ]

    const descriptionLabel = (ship: ShipInfo) => {
        return ""; // <span>{ship.traits.join(", ")}{ship.traits_hidden.length>0?(<i>{" "+ship.traits_hidden.join(", ")}</i>):""}</span>
    }

    const renderExpandedRow = (ship: ShipInfo, idx: number) => {
        return (
            <React.Fragment>
                <Table.Cell colSpan={19} className="expanded-cell">
                    <Grid stackable className="shipAbs">
                        {ship.actions.map((x,idx)=><div><ShipActionInfo action={x} key={idx}/></div>)}
                    </Grid>
                    {ship.flavor && <p><br/><Icon className="quote right icon"/>{' '}{ship.flavor}</p>}
                    <span><Icon style={{marginLeft:"-4px"}} size="large" name='heartbeat' verticalalign='top'/>
                        {ship.traits.map((x,idx)=><span key={"trait"+idx}>{x}{idx===ship.traits.length-1?"":", "}
                        </span>)}
                    </span>
                    <div className="adslot">
                        {(window as any).adsbygoogle?.loaded && <span className='adlabel'>Advertisement</span>}
                        {isMobileOnly && <AdSense.Google
                            className="ad"
                            client='ca-pub-5995456634193629'
                            slot='7560981858'
                            style={{ display: 'block'}}
                            format='auto'
                            responsive='true'
                        />}
                        {!isMobileOnly && <AdSense.Google
                            className="ad adfixed"
                            client='ca-pub-5995456634193629'
                            slot='6892835811'
                            style={{ display: 'inline-block', width: '970px', height: '250px', border: '1px solid #444444'}}
                            format='fixed'
                        />}
                    </div>
                </Table.Cell>
            </React.Fragment>
        )
      }

  const renderTableRow = (ship: ShipInfo, idx: number) => {
    return (
        <React.Fragment>
            <Table.Cell>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '60px auto',
                        gridTemplateAreas: `'icon stats' 'icon description'`,
                        gridGap: '1px'
                    }}
                >
                    <div style={{ gridArea: 'icon' }}>
                        <img width={48} src={getAssetURL(ship.icon.file)} alt="Preview"/>
                    </div>
                    <div style={{ gridArea: 'stats' }} className={rarityClass(ship.rarity)}>
                        <span style={{ fontWeight: 'bolder', fontSize: '1.25em' }}>
                            {ship.name}
                        </span>
                    </div>
                    <div style={{ gridArea: 'description' }}>{descriptionLabel(ship)}</div>
                </div>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>{ship.attacks_per_second.toLocaleString()}</b><br/>
                <small style={{ fontSize: '90%' }}>/sec</small>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>{ship.dps.toLocaleString()}</b>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {getShipBoostElement(ship.cAttack, 0, true)}
                <small style={{ fontSize: '90%' }}>{ship.attack.toLocaleString()}</small>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {getShipBoostElement(ship.cEvasion, 1, true)}
                <small style={{ fontSize: '90%' }}>{ship.evasion.toLocaleString()}</small>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {getShipBoostElement(ship.cAccuracy, 2, true   )}
                <small style={{ fontSize: '90%' }}>{ship.accuracy.toLocaleString()}</small>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>{ship.hull.toLocaleString()}</b>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>{ship.shields.toLocaleString()}</b>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>{ship.shield_regen.toLocaleString()}</b>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>{ship.crit_chance.toLocaleString()}</b><br/>
                <small style={{ fontSize: '90%' }}>{ship.crit.toLocaleString()}%</small>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>{ship.crit_bonus}</b><br/>
                <small style={{ fontSize: '90%' }}>{(ship.crit_bonus/100).toLocaleString()}%</small>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>{ship.antimatter.toLocaleString()}</b>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {ship.battle_stations.map((x,idx)=>
                    <img key={idx} alt='S' width='10' height='14' style={{marginLeft:'2px'}} src={getAssetURL('icons_icon_'+x.skill +'.png')}/>
                )}
            </Table.Cell>
        </React.Fragment>
    );
  }

  const title = "Ships - The Big Book of Behold Advice";
  const link = "https://www.bigbook.app/ratings/ships";
  const description = "Star Trek Timelines ship data and stats";

  return (
    <div>
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={link} />
            <meta name="description" content={description}/>
            <meta property="og:url" content={link}/>
            <meta property="og:description" content={description}/>
            <meta name="twitter:url" content={link}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
        </Helmet>
        <ShipSearchableTable
            id="ships"
            data={ships}
            config={tableConfig}
            renderTableRow={(ship, idx) => renderTableRow(ship, idx)}
            renderExpandedRow={(crew, idx) => renderExpandedRow(crew, idx)}
            filterRow={showThisShip}
            showFilterOptions={false}
            showSearchExplanation={true}
        />
        <Footer/>
    </div>
  );
}

export default Ships;