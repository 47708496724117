import React from 'react';
import { Table, Input, Pagination, Dropdown, Popup, Icon, Button } from 'semantic-ui-react';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import ReactGA from "react-ga4";

import {navigate } from "@reach/router";

import { IConfigSortData, IResultSortDataBy, sortDataBy } from '../utils/datasort';
import { useStateWithStorage } from '../utils/storage';
import { ITableConfigRow } from './SearchableTable';
import { ShipInfo } from '../services/NamerStats';

const SearchString = require('search-string');

const filterTypeOptions = [
    { key : '0', value : 'Exact', text : 'Exact match only' },
    { key : '1', value : 'Whole word', text : 'Whole word only' },
    { key : '2', value : 'Any match', text : 'Match any text' }
];

const pagingOptions = [
	{ key: '0', value: '10', text: '10' },
	{ key: '1', value: '25', text: '25' },
	{ key: '2', value: '50', text: '50' },
	{ key: '3', value: '100', text: '100' }
];

type SearchableTableProps = {
	id?: string;
	data: any[];
	explanation?: React.ReactNode;
	config: ITableConfigRow[];
	renderTableRow: (row: any, idx?: number) => JSX.Element;
	renderExpandedRow?: (row: any, idx?: number) => JSX.Element;
	filterRow: (ship: ShipInfo, filter: any, filterType?: string) => boolean;
    showFilterOptions: boolean;
	showSearchExplanation?: boolean;
	showTierFilter?: boolean;
	hideRarityFilter?: boolean;
	defaultPerPage?: number;
	clearSearch?: boolean;
};

export const ShipSearchableTable = (props: SearchableTableProps) => {

	const tableId = props.id ? props.id : '';

	// Ignore stored searchFilter if search parameter found
	let defaultSearch = '', useAndStoreDefault = false;
	let urlParams = new URLSearchParams(window.location.search);
	if (urlParams.has('search')) {
		defaultSearch = urlParams.get('search') ?? "";
		useAndStoreDefault = true;
	}

	const [pagination_rows, setPaginationRows] = useStateWithStorage(tableId+'paginationRows', props.defaultPerPage ?? pagingOptions[0].value);

	let locPage: number | undefined;
	let locIndex: number | undefined;
	if (urlParams.has("loc")) {
		let symbol = urlParams.get("loc");
		let index = props.data.findIndex(x=>x.symbol == symbol);
		if (index >= 0) {
			locPage = Math.ceil((index+1) / pagination_rows) ;
			let totalPages = Math.ceil(props.data.length / pagination_rows);
			if (locPage > totalPages) locPage = totalPages;
			locIndex = index - (locPage-1) * pagination_rows;
			//console.log("Found", symbol, "at", index, "locpage", locPage, "locindex", locIndex)
			defaultSearch = "";
			useAndStoreDefault = true;
		}
	}

	const [newRarity, setRarity] = useStateWithStorage('newRarity', 0, {useAndStoreDefault});
	const [tier, setTierFilter] = useStateWithStorage(tableId+'tier', 0, {useAndStoreDefault: useAndStoreDefault});

	const isRaritySelected = (r: number) => {
		if (r==0) return newRarity==0;
		return ((newRarity as number)&(2<<r))>0;
	}

	const toggleRarity = (r: number) => {
		if (r==0) setRarity(0);
		else setRarity(newRarity^(2<<r));
	}

	let data = isRaritySelected(0) ? [...props.data] : props.data.filter(x=>isRaritySelected(x.rarity));
	data = tier == 0 ? data : data.filter(x=>x.customTier > 0 ? x.customTier == tier : x.tier == tier);

	const [loggedSearch, setLoggedSearch] = React.useState(false);
	const [searchFilter, setSearchFilter] = useStateWithStorage('searchFilter', props.clearSearch ? "" : defaultSearch, {useAndStoreDefault: props.clearSearch ?? useAndStoreDefault});
	const [filterType, setFilterType] = useStateWithStorage(tableId+'filterType', 'Any match');
	const [column, setColumn] = useStateWithStorage(tableId+'column', null, {useAndStoreDefault});
	const [direction, setDirection] = useStateWithStorage(tableId+'direction', null);
	const [pagination_page, setPaginationPage] = useStateWithStorage(tableId+'paginationPage', locPage === undefined ? 1 : locPage, {useAndStoreDefault: locPage !== undefined ? true : useAndStoreDefault});
	const [selectedIndex, setSelectedIndex] = useStateWithStorage(tableId+'selIdx', locIndex === undefined ? -1 : locIndex, {useAndStoreDefault: locIndex !== undefined ? true : useAndStoreDefault});
	const [secondaryColumn, setSecondaryColumn] = React.useState<string|undefined>(undefined);

	const setTier = (tier: number) => {
		setPaginationPage(1);
		setTierFilter(tier);
	}

	// We only sort here to store requested column and direction in state
	//	Actual sorting of full dataset will occur on next render before filtering and pagination
	function handleSort(clickedColumn, pseudocolumns, secondaryColumn?) {
		let sortConfig: IConfigSortData = {
			field: clickedColumn,
			direction: direction
		};

		if (secondaryColumn) {
			sortConfig.secondary = {
				field: secondaryColumn,
				direction: 'descending'
			}
		}

		if(pseudocolumns) {
			if(pseudocolumns.includes(column)) {
				sortConfig.field = column;
			} else {
				sortConfig.direction = null;
			}
			sortConfig.rotateFields = pseudocolumns;
		} else {
			if(clickedColumn !== column) {
				// sort newRarity and skills descending first by default
				sortConfig.direction = 'ascending';
			}
		}

		const sorted: IResultSortDataBy = sortDataBy(data, sortConfig);

		setSelectedIndex(locIndex === undefined ? -1 : locIndex);
		setSecondaryColumn(secondaryColumn);
		setColumn(sorted.field);
		setDirection(sorted.direction);
		setPaginationPage(1);
	}

	function onChangeFilter(value) {
		setSearchFilter(value);
		setPaginationPage(1);
	}

	function renderTableHeader(column: any, direction: 'descending' | 'ascending' | null): JSX.Element {
		return (
			<Table.Row>
				{props.config.map((cell, idx) => (
					<Table.HeaderCell
						key={idx+"h"}
						width={cell.width as any}
						sorted={((cell.pseudocolumns && cell.pseudocolumns.includes(column)) || (column === cell.column)) ? direction : null}
						className={cell.class}
						onClick={() => handleSort(cell.column, cell.pseudocolumns, cell.secondaryColumn)}
					>
						{cell.title}{cell.pseudocolumns?.includes(column) && <><br/><small>{column}</small></>}
					</Table.HeaderCell>
				))}
			</Table.Row>
		);
	}

	// Sorting
	if (column) {
		let sortConfig: IConfigSortData = {
			field: column,
			direction: direction,
			keepSortOptions: true
		};
		if (secondaryColumn) {
			sortConfig.secondary = {
				field: secondaryColumn,	
				direction: "descending"
			}
		}
		// Use original dataset for sorting
		const sorted: IResultSortDataBy = sortDataBy([...props.data], sortConfig);
		data = isRaritySelected(0) ? sorted.result : sorted.result.filter(x=>isRaritySelected(x.rarity));
		data = tier == 0 ? data : data.filter(x=>x.tier == tier);
	}

	// Filtering
	let filters = [];
	if (searchFilter) {
		let grouped = searchFilter.split(/\s+OR\s+/i);
		grouped.forEach(group => {
			let cheat = group.split(" ");
			for (let i=0; i<cheat.length; i++) {
				const old = cheat[i];
				if (!cheat[i].includes(":")) {
					cheat[i] = cheat[i].replace("<",":<").replace(">",":>");
					if (cheat[i] == old) {
						cheat[i] = cheat[i].replace("=", ":");
					}
				}
			}
			filters.push(SearchString.parse(cheat.join(" ")));
		});
	}
	data = data.filter(row => props.filterRow(row, filters, filterType));

	// Pagination
	let activePage = pagination_page;
	let totalPages = Math.ceil(data.length / pagination_rows);
	if (activePage > totalPages) activePage = totalPages;
	data = data.slice(pagination_rows * (activePage - 1), pagination_rows * activePage);

	React.useEffect(() => {
		if (loggedSearch) {
			return;
		}
		setLoggedSearch(true);
		let urlParams = new URLSearchParams(window.location.search);
		if (urlParams.has('search')) {
			let search = urlParams.get('search');
			if (search) {
				ReactGA.event({category:"Search", action:search});
			}
		}
	}, []);

	React.useEffect(() => {
		setTimeout(() => {
			if (selectedIndex == -1) {
				return
			}
			let element = document.getElementById("row"+selectedIndex);
			if (element) {
				let top = element.getBoundingClientRect().top;
				let expandedElement = document.getElementById("row"+selectedIndex+"e");
				if (top > 60 && (!expandedElement || expandedElement.getBoundingClientRect().bottom < window.innerHeight)) {
					return;
				}
				let elementHeight = element.getBoundingClientRect().height;
				let expandedHeight = expandedElement ? expandedElement.getBoundingClientRect().height : 0;
				if (window.innerHeight < elementHeight + expandedHeight - 60 || top < 60) {
					window.scrollTo({
						behavior: "smooth",
						top: -60 + element.getBoundingClientRect().top + window.pageYOffset
					});
				} else {
					window.scrollTo({
						behavior: "smooth",
						top: window.pageYOffset + expandedElement.getBoundingClientRect().bottom - window.innerHeight
					});
				}
			}
		},100);
	}, [selectedIndex])

	return (
		<div>
			<div className="searchableTableSearch">
				<Input
					style={{ width: isMobile && !isTablet ? '100%' : '50%' }}
					iconPosition="left"
					placeholder="Search..."
					value={searchFilter}
					onChange={(e, { value }) => {setSelectedIndex(-1); onChangeFilter(value);}}>
						<input />
						<Icon name='search' />
						<Button icon onClick={() => {setSelectedIndex(-1); onChangeFilter('');}} >
							<Icon name='delete' />
						</Button>
				</Input>

				{props.showFilterOptions && (
					<span style={{ paddingLeft: '2em' }}>
						<Dropdown inline
									options={filterTypeOptions}
									value={filterType}
									onChange={(event, {value}) => {setSelectedIndex(-1); setFilterType(value as number);}}
						/>
					</span>
				)}

				{/* {props.showSearchExplanation && (<Popup wide trigger={<Icon name="help" />}
						header={'Advanced search'}
						content={props.explanation ? props.explanation : renderDefaultExplanation()}
					/>
				)} */}

				{props.showSearchExplanation && <Icon style={{cursor:"pointer"}} name="help" onClick={()=>navigate("/help")}/>}
			</div>

			{!props.hideRarityFilter && <div className={(isMobileOnly ? "rfmobile " : "") + "rarityfilter"}>
				<span className="mono">Show:{' '}</span>
				<span className={"rarityfilter-0"+(isRaritySelected(0)?" rarityselected":"")} style={isRaritySelected(0)?{color:'white'}:{}} onClick={()=>toggleRarity(0)}>All</span>|
				<span className={"rarityfilter-5"+(isRaritySelected(5)?" rarityselected":"")} onClick={()=>toggleRarity(5)}>Legendary</span>{' '}
				<span className={"rarityfilter-4"+(isRaritySelected(4)?" rarityselected":"")} onClick={()=>toggleRarity(4)}>Super Rare</span>{' '}
				<span className={"rarityfilter-3 rare"+(isRaritySelected(3)?" rarityselected":"")} onClick={()=>toggleRarity(3)}>Rare</span>{' '}
				<span className={"rarityfilter-2 uncommon"+(isRaritySelected(2)?" rarityselected":"")} onClick={()=>toggleRarity(2)}>Uncommon</span>{' '}
				<span className={"rarityfilter-1 common"+(isRaritySelected(1)?" rarityselected":"")} onClick={()=>toggleRarity(1)}>Common</span>
			</div>}

			{props.showTierFilter && <div className={(isMobileOnly ? "rfmobile " : "") + "rarityfilter"}>
				<span className="mono">Tier:{' '}</span>
				<span className={"tierFilter"+(tier==0?" tierFilterSelected":"")} onClick={()=>setTier(0)}>All</span>|
				<span className={"tierFilter"+(tier==1?" tierFilterSelected":"")} onClick={()=>setTier(1)}>1</span>
				<span className={"tierFilter"+(tier==2?" tierFilterSelected":"")} onClick={()=>setTier(2)}>2</span>
				<span className={"tierFilter"+(tier==3?" tierFilterSelected":"")} onClick={()=>setTier(3)}>3</span>
				<span className={"tierFilter"+(tier==4?" tierFilterSelected":"")} onClick={()=>setTier(4)}>4</span>
				<span className={"tierFilter"+(tier==5?" tierFilterSelected":"")} onClick={()=>setTier(5)}>5</span>
				<span className={"tierFilter"+(tier==6?" tierFilterSelected":"")} onClick={()=>setTier(6)}>6</span>
				<span className={"tierFilter"+(tier==7?" tierFilterSelected":"")} onClick={()=>setTier(7)}>7</span>
				<span className={"tierFilter"+(tier==8?" tierFilterSelected":"")} onClick={()=>setTier(8)}>8</span>
				<span className={"tierFilter"+(tier==9?" tierFilterSelected":"")} onClick={()=>setTier(9)}>9</span>				
				<span className={"tierFilter"+(tier==10?" tierFilterSelected":"")} onClick={()=>setTier(10)}>10</span>
			</div>}

			<Table sortable celled selectable striped collapsing unstackable compact="very" className="highlightTable">
				<Table.Header>{renderTableHeader(column, direction)}</Table.Header>
				<Table.Body>{data.map((row, idx) => {
					let cn="";
					let scn="";
					if (idx==locIndex && locPage == pagination_page) {
						if (((selectedIndex == idx) || (data.length==1 && totalPages == 1)) && props.renderExpandedRow) {
							cn = "highightTopRow";
							scn = "highlightBottomRow";
						} else {
							cn = "highlightRow";
						}
					}
					return <React.Fragment key={idx}>
						<Table.Row className={cn} id={"row"+idx} key={"row"+idx} style={{ cursor: props.renderExpandedRow ? 'pointer' : 'default' }}
							onClick={() => {
								if (!props.renderExpandedRow) {
									return;
								}
								if (idx==selectedIndex) {
									setSelectedIndex(-1);
								} else {
									setSelectedIndex(idx);
								}
							}}>
							{props.renderTableRow(row, idx)}
						</Table.Row>
						{((selectedIndex == idx) || (data.length==1 && totalPages == 1)) && props.renderExpandedRow &&
							<Table.Row id={"row"+idx+"e"} key={idx+'e'} style={{ cursor: 'default' }} className={"expanded-row "+scn}>
								{props.renderExpandedRow(row, idx)}
							</Table.Row>}
					</React.Fragment>
				})}</Table.Body>
				<Table.Footer>
					<Table.Row>
						<Table.HeaderCell colSpan={props.config.length}>
							<Pagination
								totalPages={totalPages}
								activePage={activePage}
								onPageChange={(event, { activePage }) => {
									setSelectedIndex(-1);
									setPaginationPage(activePage as number);
								}}
							/>
							<span style={{ paddingLeft: '2em'}}>
								Rows per page:{' '}
								<Dropdown
									inline
									defaultValue={props.defaultPerPage ? props.defaultPerPage.toString() : pagingOptions[0].text}
									options={pagingOptions}
									onChange={(event, {value}) => {
										setPaginationPage(1);
										setPaginationRows(value as number);
									}}
								/>
							</span>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Footer>
			</Table>
		</div>
	);
}