import '../assets/fomantic/dist/semantic.css';
import { Accordion, Container, Grid, Icon, Segment, Label, LabelDetail, Loader } from 'semantic-ui-react';
import ReactGA from 'react-ga4';
import React from 'react';
import {Helmet} from 'react-helmet';
import {Link} from '@reach/router';

import '../App.css';
import { NamerCrew, getCrew, FeaturedCrewSkillInfo, CrewNote, getCrewNote, getQuipments, Quipment } from '../services/NamerStats';
import { FeaturedCrew, FeaturedCrewSkill, StatType } from './FeaturedCrew';
import VoyageStats from './CrewStats/VoyageStats';
import EventStats from './CrewStats/EventStats';
import GauntletStats from './CrewStats/GauntletStats';
import { Footer } from './Footer';
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";
import CollectionStats from './CrewStats/CollectionStats';
import ShuttleStats from './CrewStats/ShuttleStats';
import FlavorStats from './CrewStats/FlavorStats';
import ArenaStats from './CrewStats/ArenaStats';
import { logEvent, rarityClass } from '../utils/utils';
import { getAssetURL } from '../App';
import NotFound from './NotFound';
import { isMobileOnly, isMobile, isTablet, useMobileOrientation } from 'react-device-detect';
import Quipments from './Quipments';

export const CrewPage = (props: {
    symbol: string;
    navigation: () => void;
}) => {
    let mobileOrientation = useMobileOrientation();
    let isOnlyMobile = isMobile && !isTablet && mobileOrientation.isPortrait;
    const statTypeFromStringMap = new Map<StatType, string>([ [StatType.Undefined, "undefined"],
        [StatType.Voyage, "voyage"], [StatType.Gauntlet, "gauntlet"], [StatType.Event, "event"],
        [StatType.Shuttle, "shuttle"], [StatType.Collection, "collection"], [StatType.Arena, "arena"],
        [StatType.Flavor, "flavor"], [StatType.Variants, "variants"]
    ]);
    const statTypeMap = new Map<string, StatType>([ ["undefined", StatType.Undefined],
        ["voyage", StatType.Voyage], ["gauntlet", StatType.Gauntlet], ["event", StatType.Event],
        ["shuttle", StatType.Shuttle], ["collection", StatType.Collection], ["arena", StatType.Arena], 
        ["flavor", StatType.Flavor], ["variants", StatType.Variants]
    ]);
    let defaults = new Map<StatType, boolean>([[StatType.Collection, true], [StatType.Flavor, true], [StatType.Arena, true]]);
    let urlParams = new URLSearchParams(window.location.search);
    let scrollOnFirstRender = StatType.Undefined;
	if (urlParams.has('stat')) {
		let stat = urlParams.get('stat') ?? "undefined";
        defaults.set(statTypeMap.get(stat), true);
        scrollOnFirstRender = statTypeMap.get(stat);
	}

    const [crew, setCrew] = React.useState<NamerCrew|undefined>(undefined);
    const [tempCrew, setTempCrew] = React.useState<NamerCrew|undefined>(undefined);
    const [crewNote, setCrewNote] = React.useState<CrewNote|undefined>(undefined);
    const [tempCrewNote, setTempCrewNote] = React.useState<CrewNote|undefined>({id:props.symbol});
    const [error, setError] = React.useState<string|undefined>(undefined);
    const [activeMap, setActiveMap] = React.useState<Map<number,boolean>>(new Map(defaults));
    const [activeIndex, setActiveIndex] = React.useState<StatType|undefined>();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [variants, setVariants] = React.useState<NamerCrew[][]|undefined>(undefined);
    const [quipments, setQuipments] = React.useState<Quipment[]|undefined>(undefined);

    const handleClick = (index: StatType, statClick?: boolean, scrollOnFirstRender?: boolean) => {
        if (!statClick && !scrollOnFirstRender) {
            ReactGA.event({category:"CrewStats", action:statTypeFromStringMap.get(index)});
        }
        if (!statClick || !activeMap.get(index)) {
            let newMap = new Map(activeMap);
            newMap.set(index, scrollOnFirstRender ? true : !activeMap.get(index));
            setActiveMap(newMap);
            setActiveIndex(StatType.Undefined);
            if (newMap.get(index)) {
                setTimeout(() => {
                    setActiveIndex(index);
                }, 100);
            }
        } else {
            setActiveIndex(StatType.Undefined);
            setTimeout(() => {
                setActiveIndex(index);
            }, 100);
        }
    }

    React.useEffect(() => {
        props.navigation();
        if (!crew || crew.symbol != props.symbol) {
            getCrewNote(props.symbol).then((data)=>setTempCrewNote(data));
            getCrew().then((data) => {
                if (data && data.length > 0) {
                    //data = data.filter(x=>x.rarity>=4);
                    data.forEach(x=> {
                        x.triplet = x.skills.join("/");
                        x.collection = x.collections ? x.collections.length : 0;
                        x.trait = x.traits ? x.traits.length : 0;
                        x.cStats = x.cCount[0]
                        x.cCrew = x.cCount[1]
                        x.cVanity = x.cCount[3]
                    });
                    let c = data.find(x=>x.symbol==props.symbol);
                    if (!c) {
                        setError("Oops, could not find the specified crew.");
                    } else {
                        setTempCrew(c);
                    }
                }            
            });
        }
    }, [props.symbol])

    const sortQuipments = (crew:any,quipments: any) => {
        quipments.sort((a,b)=>
            (b.bases[crew.skills[0]] == a.bases[crew.skills[0]]) ?
            (crew.skills.length == 1 ? -1 :
                (
                    (b.bases[crew.skills[1]] == a.bases[crew.skills[1]]) ?
                    (crew.skills.length == 3 ? -1 :
                        (
                            b.bases[crew.skills[2]] - a.bases[crew.skills[2]]
                        ))
                    : (b.bases[crew.skills[1]] - a.bases[crew.skills[1]])
                ))
            : (b.bases[crew.skills[0]] - a.bases[crew.skills[0]])
        );
        quipments.sort((a,b)=>{
            let aroll = 0, broll = 0;
            for (const skill of crew.skills) {
                aroll += a.bases[skill] + (a.minProfs[skill] + a.maxProfs[skill])/2;
                broll += b.bases[skill] + (b.minProfs[skill] + b.maxProfs[skill])/2;
            }
            return broll-aroll
        });
        for (let i=0; i<quipments.length; i++) {
            quipments[i].voyage = i < 4;
        }
        quipments.sort((a,b)=>{
            let aprof = 0, bprof = 0;
            for (const skill of crew.skills) {
                aprof += (a.minProfs[skill] + a.maxProfs[skill])/2;
                bprof += (b.minProfs[skill] + b.maxProfs[skill])/2;
            }
            return bprof-aprof
        });
        for (let i=0; i<quipments.length; i++) {
            quipments[i].gauntlet = i < 4;
        }
        quipments.sort((a,b)=>{
            let abase = 0, bbase = 0;
            for (const skill of crew.skills) {
                abase += a.bases[skill];
                bbase += b.bases[skill];
            }
            return bbase-abase
        });
        for (let i=0; i<quipments.length; i++) {
            quipments[i].shuttle = i < 4;
        }
        quipments.sort((a,b)=>(b.voyage==a.voyage)?(
            (b.gauntlet==a.gauntlet)?
                ((b.shuttle == a.shuttle) ? b.rarity-a.rarity : (b.shuttle?1:-1))
            :(b.gauntlet?1:-1)
        ):(b.voyage?1:-1));
    }

    React.useEffect(() => {
        if ((!tempCrewNote || tempCrewNote.id) && tempCrew) {
            setCrewNote(tempCrewNote);
            setCrew(tempCrew);

            //Calculate variants
            getCrew().then(allCrew=>{
                const genericHidden = [ "tosbridge", "tos", "tas", "dsc", "ent", "tng", "voy", "pic", "ds9", "low", "_nonbonus", "jadzia", "snw", "original", "feb2023", "apr2023", "apr2024", "june2024", "jan2024"]
                let variants = [];
                tempCrew.eTraits.forEach(trait=>{
                    if (!genericHidden.find(x=>x==trait) && !tempCrew.traits.find(x=>x==trait)) {
                        variants.push(trait);
                    }
                });
                if (variants.length == 0) {
                    for (const hidden of tempCrew.hiddenTraits) {
                        if (hidden == "oh" || hidden == "cochrane" || hidden == "leland" || hidden == "bynar_1001" || hidden == "kyle" || hidden == "lursa" || hidden == "betor") {
                            variants.push(hidden);
                            if (!(hidden == "lursa" || hidden == "betor")) break;
                        }
                    }
                }
                let variantLists = [];
                for (const variant of variants) {
                    let list = allCrew.filter(c=>c.hiddenTraits.some(t=>t==variant) && c.symbol != tempCrew.symbol);
                    if (list.length > 0) {
                        variantLists.push(list);
                    }
                }
                setVariants(variantLists);
                if (tempCrew.quipment) {
                    getQuipments().then((data)=>{
                        const qs = data.filter(x=>tempCrew.quipment.some(y=>y==x.id));
                        sortQuipments(tempCrew,qs);
                        setQuipments(qs);                    
                    });
                }
            });
        }
    }, [tempCrew, tempCrewNote]);

    React.useEffect(() => {
        if (loaded && scrollOnFirstRender != StatType.Undefined) {
            setTimeout(() => {
                handleClick(scrollOnFirstRender, false, true);
            }, 100);
        }
    }, [loaded]);

    React.useEffect(() => {
        setTimeout(() => {
            if (activeIndex == StatType.Undefined) {
                return;
            }
            if (!activeMap.get(activeIndex)) {
                return;
            }
            var element = document.getElementById('stat'+activeIndex);
            if (!element) {
                return;
            }
            let top = element.getBoundingClientRect().top;
            
            if (element.getBoundingClientRect().bottom < window.innerHeight && top > 0) {
                return;
            }

            var parent = document.getElementById('statt'+activeIndex);
            var offset = 0;
            if (parent) {
                offset = parent.getBoundingClientRect().height;
            }

            if (top < 0 || element.getBoundingClientRect().height + 80 > window.innerHeight) {
                window.scrollTo({
                    behavior: "smooth",
                    top: -60 + (top>0?top:0) + window.pageYOffset - offset
                });
            } else {
                window.scrollTo({
                    behavior: "smooth",
                    top: window.pageYOffset + 40 + element.getBoundingClientRect().bottom - window.innerHeight
                });
            }
           }, 100);
    }, [activeIndex])

    if (error) {
        return <NotFound/>
        //return <h4>{error}</h4>
    }
    if (!crew) {
        return <Container><div className="center ui active loader"/></Container>;
    }

    let primary : FeaturedCrewSkillInfo = {
        skill: crew.skills[0] as FeaturedCrewSkill,
        base: Math.floor(crew.baseScore[crew.skills[0]]),
        min: Math.floor(crew.gMinBySkill[crew.skills[0]]),
        max: Math.floor(crew.gMaxBySkill[crew.skills[0]])
    };
    let rawPrimary : FeaturedCrewSkillInfo = {
        skill: crew.skills[0] as FeaturedCrewSkill,
        base: Math.floor(crew.baseRaw[crew.skills[0]]),
        min: Math.floor(crew.gRawMinBySkill[crew.skills[0]]),
        max: Math.floor(crew.gRawMaxBySkill[crew.skills[0]])
    };
    let secondary : FeaturedCrewSkillInfo = crew.skills.length > 1 ? {
        skill: crew.skills[1] as FeaturedCrewSkill,
        base: Math.floor(crew.baseScore[crew.skills[1]]),
        min: Math.floor(crew.gMinBySkill[crew.skills[1]]),
        max: Math.floor(crew.gMaxBySkill[crew.skills[1]])
    } : undefined;
    let rawSecondary : FeaturedCrewSkillInfo = crew.skills.length > 1 ? {
        skill: crew.skills[1] as FeaturedCrewSkill,
        base: Math.floor(crew.baseRaw[crew.skills[1]]),
        min: Math.floor(crew.gRawMinBySkill[crew.skills[1]]),
        max: Math.floor(crew.gRawMaxBySkill[crew.skills[1]])
    } : undefined;
    let tertiary : FeaturedCrewSkillInfo = crew.skills.length > 2 ? {
        skill: crew.skills[2] as FeaturedCrewSkill,
        base: Math.floor(crew.baseScore[crew.skills[2]]),
        min: Math.floor(crew.gMinBySkill[crew.skills[2]]),
        max: Math.floor(crew.gMaxBySkill[crew.skills[2]])
    } : undefined;
    let rawTertiary : FeaturedCrewSkillInfo = crew.skills.length > 2 ? {
        skill: crew.skills[2] as FeaturedCrewSkill,
        base: Math.floor(crew.baseRaw[crew.skills[2]]),
        min: Math.floor(crew.gRawMinBySkill[crew.skills[2]]),
        max: Math.floor(crew.gRawMaxBySkill[crew.skills[2]])
    } : undefined;


    const topRolls = (crew: NamerCrew) => {
        let topPairs = [];
        if (!crew.gTopPairs) {
            return;
        }
        crew.gTopPairs.forEach((x, idx)=>{
            let prevRank = 0;
            if (x.rank == 1 || idx == 0 || prevRank == x.rank) {
                //topPairs.push(<span><b>#{x.rank} {x.pair}</b> <small style={{ fontSize: '80%' }}> x{x.count}</small></span>)
                topPairs.push(<Label key={idx} horizontal size="large" className="stats-label">
                    #{x.rank} {x.pair}
                    <LabelDetail>
                            x{x.count<10?" ":""}{x.count}
                    </LabelDetail>
                </Label>);
                prevRank = x.rank;
            }
        });
        return topPairs.map(x => x)
    }

    const bestSeats = (crew: NamerCrew) => {
        if (crew.skills.length < 3) {
            return ""
        }
        let bestRank = 10000;
        let traits: string[] = [];
        for (const trait of  Object.keys(crew.vSeating)) {
            let seat = crew.vSeating[trait];
            if (seat.ranks.triplet < bestRank) {
                bestRank = seat.ranks.triplet;
                traits = [];
            }
            if (seat.ranks.triplet == bestRank) {
                traits.push(trait);
            }
        }

        if (bestRank === 10000) {
            return ""
        }
    
        return (<span>
            <Label className="stats-label" size="large" horizontal>{"#" + bestRank + " " + crew.triplet}</Label> <small>on {traits.join(", ")} seats</small>
        </span>)
    }

    const bestBases = (crew: NamerCrew) => {
        return crew.bMentions.map((x, idx)=><Label className="stats-label" size="large" horizontal key={idx}>{x}</Label>)
    }

    const getOptimal = (label: string) => {
        return <span className="qoptimal">{label}</span>
    }

    const description = "Star Trek Timelines crew - Tier " + crew.tier + (crew.rarity == 5 ? "legendary" : "super rare") + " crew\r\n" + crewNote.note ? "" : (crewNote.note.substring(0,100) + "[...]");
    const link = "https://www.bigbook.app/crew/"+crew.symbol;
    const title = crew.name + " - The Big Book of Behold Advice";
    const image = "https://www.bigbook.app/images/"+crew.portrait;

    return (<div>
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={link} />
            <meta name="description" content={description}/>
            <meta property="og:url" content={link}/>
            <meta property="og:type" content="article"/>
            <meta property="og:title" content={title}/>
            <meta property="og:image" content={image}/>
            <meta property="og:image:alt" content="Crew portrait"/>
            <meta property="og:description" content={description}/>
            <meta property="article:author" content="Automaton_2000"/>
            <meta name="twitter:url" content={link}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
            <meta name="twitter:image" content={image}/>
            <meta name="twitter:image:alt" content="Crew portrait"/>
        </Helmet>
        <ScrollUpButton />
        <Grid stackable>
        <FeaturedCrew
            index={0}
            key={crew.symbol}
            symbol={crew.symbol}
            name={crew.name}
            rarity={crew.rarity}
            image={crew.fullBody}
            portrait={crew.portrait}
            background={crew.background}
            leftImage={true}
            primary={primary}
            secondary={secondary}
            tertiary={tertiary}
            rawPrimary={rawPrimary}
            rawSecondary={rawSecondary}
            rawTertiary={rawTertiary}
            note={crewNote ? crewNote.note : ""}
            noteAuthor={crewNote ? crewNote.author : undefined}
            noteDate={crewNote && crewNote.modified ? new Date(crewNote.modified) : undefined}
            video={crewNote ? crewNote.videoLink : undefined}
            audio={crewNote ? crewNote.audioLink : undefined}
            traits={crew.traitsNamed}
            collections={crew.collections.length > 0 ? crew.collections : undefined}
            tier={crew.tier}
            event={crew.eRating}
            eventR={crew.eRatingR}
            voyage={crew.vNRating}
            voyageR={crew.vNRank}
            gauntlet={crew.gRating}
            gauntletR={crew.gNRank}
            shuttle={crew.bRating}
            shuttleR={crew.bRatingRank}
            coll={crew.cNFinalRating}
            collR={crew.cNFinalRank}
            arena={crew.aRating}
            arenaR={crew.aRank}
            crewPage={true}
            crMentions={crew.crMention}
            crNow={crew.crNow}
            crLater={crew.crLater}
            date={crew.date}
            obtained={crew.obtained}
            nicknames={crew.nicknames}
            portal={crew.portal}
            showAd={true}
            ftm_name={crew.ftm_name}
            ftm_date={crew.ftm_date}
            handleClick={(stat) => {handleClick(stat, true)}}
        />
        </Grid>
        <div className="crewStats">
            <Accordion inverted fluid exclusive={false}>


                <Accordion.Title id={"statt"+StatType.Voyage} active={activeMap.get(StatType.Voyage)} index={StatType.Voyage} onClick={() => handleClick(StatType.Voyage)}>
                    <h2><Icon name='dropdown' />Voyage {bestSeats(crew)}</h2>
                </Accordion.Title>
                <Accordion.Content id={"stat"+StatType.Voyage} active={activeMap.get(StatType.Voyage)}>
                    <Segment inverted className="statBg"><VoyageStats crew={crew} showAd={activeMap.get(StatType.Voyage)}/></Segment>
                </Accordion.Content>
                <hr style={{opacity:"0.2"}}/>


                <Accordion.Title id={"statt"+StatType.Gauntlet} active={activeMap.get(StatType.Gauntlet)} index={StatType.Gauntlet} onClick={() => handleClick(StatType.Gauntlet)}>
                    <h2><Icon name='dropdown' />Gauntlet <span style={{marginLeft:"5px"}}>{topRolls(crew)}</span></h2> 
                </Accordion.Title>
                <Accordion.Content id={"stat"+StatType.Gauntlet} active={activeMap.get(StatType.Gauntlet)}>
                    <Segment inverted className="statBg"><GauntletStats crew={crew} full={true} showAd={activeMap.get(StatType.Gauntlet)}/></Segment>
                </Accordion.Content>
                <hr style={{opacity:"0.2"}}/>


                <Accordion.Title id={"statt"+StatType.Event} active={activeMap.get(StatType.Event)} index={StatType.Event} onClick={() => handleClick(StatType.Event)}>
                    <h2><Icon name='dropdown' />Events {crew.events}
                        <span style={{marginLeft:"4px"}}>
                            {crew.eCounts["F"]>0?<Label className="stats-label" size="large" horizontal>Faction<LabelDetail>{crew.eCounts["F"]}</LabelDetail></Label>:""}
                            {crew.eCounts["F/G"]>0?<Label className="stats-label" size="large" horizontal>F/G<LabelDetail>{crew.eCounts["F/G"]}</LabelDetail></Label>:""}
                        </span>
                    </h2>
                </Accordion.Title>
                <Accordion.Content id={"stat"+StatType.Event} active={activeMap.get(StatType.Event)}>
                    <Segment inverted className="statBg"><EventStats crew={crew} showAd={activeMap.get(StatType.Event)}/></Segment>
                </Accordion.Content>
                <hr style={{opacity:"0.2"}}/>


                <Accordion.Title id={"statt"+StatType.Shuttle} active={activeMap.get(StatType.Shuttle)} index={StatType.Shuttle} onClick={() => handleClick(StatType.Shuttle)}>
                    <h2><Icon name='dropdown' />Shuttle <span style={{marginLeft:"5px"}}>{bestBases(crew)}</span></h2> 
                </Accordion.Title>
                <Accordion.Content id={"stat"+StatType.Shuttle} active={activeMap.get(StatType.Shuttle)}>
                    <Segment inverted className="statBg"><ShuttleStats crew={crew} showAd={activeMap.get(StatType.Shuttle)}/></Segment>
                </Accordion.Content>
                <hr style={{opacity:"0.2"}}/>


                <Accordion.Title id={"statt"+StatType.Collection} active={activeMap.get(StatType.Collection)} index={StatType.Collection} onClick={() => handleClick(StatType.Collection)}>
                    <h2><Icon name='dropdown' />Collections <big>{crew.collections.length}</big>
                        <span style={{marginLeft:"5px"}}>
                            {crew.cCount[0]>0?<Label size="large" horizontal className="stats-label">Stat boosting <LabelDetail>{crew.cCount[0]}</LabelDetail></Label>:""}
                            {crew.cCount[1]>0?<Label size="large" horizontal className="stats-label">Crew rewarding <LabelDetail>{crew.cCount[1]}</LabelDetail></Label>:""}
                            {crew.cCount[3]>0?<Label size="large" horizontal className="stats-label">Vanity <LabelDetail>{crew.cCount[3]}</LabelDetail></Label>:""}
                        </span>
                    </h2>
                </Accordion.Title>
                <Accordion.Content id={"stat"+StatType.Collection} active={activeMap.get(StatType.Collection)}>
                    <Segment inverted className="statBg"><CollectionStats crew={crew} showAd={false&&activeMap.get(StatType.Collection)}/></Segment>
                </Accordion.Content>

                <Accordion.Title id={"statt"+StatType.Arena} active={activeMap.get(StatType.Arena)} index={StatType.Arena} onClick={() => handleClick(StatType.Arena)}>
                    <h2><Icon name='dropdown' />Ship battle</h2>
                </Accordion.Title>
                <Accordion.Content id={"stat"+StatType.Arena} active={activeMap.get(StatType.Arena)}>
                    <Segment inverted className="statBg"><ArenaStats crew={crew} showAd={false&&activeMap.get(StatType.Arena)}/></Segment>
                </Accordion.Content>


                {crew.flavor && <React.Fragment>
                    <Accordion.Title id={"statt"+StatType.Flavor} active={activeMap.get(StatType.Flavor)} index={StatType.Flavor} onClick={() => handleClick(StatType.Flavor)}>
                        <h2><Icon name='dropdown' />Flavor</h2>
                    </Accordion.Title>
                    <Accordion.Content id={"stat"+StatType.Flavor} active={activeMap.get(StatType.Flavor)}>
                        <Segment inverted className="statBg"><FlavorStats crew={crew} note={crewNote} showAd={false&&activeMap.get(StatType.Flavor)}/></Segment>
                    </Accordion.Content>
                </React.Fragment>}

                {variants && variants.length > 0 && <React.Fragment>
                    <Accordion.Title id={"statt"+StatType.Variants} active={activeMap.get(StatType.Variants)} index={StatType.Variants} onClick={() => handleClick(StatType.Variants)}>
                        <h2><Icon name='dropdown' />Variants</h2>
                    </Accordion.Title>
                    <Accordion.Content id={"stat"+StatType.Variants} active={activeMap.get(StatType.Variants)}>
                        {variants.map((varList, idx) =>
                            <Segment inverted className="statBg" key={idx}>
                                <Grid stackable columns="10" relaxed>
                                    {varList.sort((a,b)=>b.rarity-a.rarity||a.tier-b.tier).map((c,cidx)=>
                                        <div className="variants" key={"var"+cidx}>
                                            <Link className={rarityClass(c.rarity)} to={"/crew/"+c.symbol} 
                                                onClick={()=>logEvent("CrewLink", "variantList", c.symbol)}>
                                                <img className="var-portrait" src={getAssetURL(c.portrait)} alt={c.name}/>
                                            </Link>                                           
                                            <div className="var-details">
                                                <div className="var-details-c">
                                                    <span className={rarityClass(c.rarity)+" var-name"}>
                                                        <Link className={rarityClass(c.rarity)} to={"/crew/"+c.symbol} 
                                                            onClick={()=>logEvent("CrewLink", "variantList", c.symbol)}>{c.name}</Link>
                                                    </span>
                                                    <br/>
                                                    <span className="var-tier">Tier <b>{(!c.portal && c.obtained == "WebStore") ? "$" : c.tier}</b></span>
                                                </div>
                                            </div>
                                        </div>                            
                                    )}
                                </Grid>
                            </Segment>
                        )}
                    </Accordion.Content>
                </React.Fragment>}

                {crew.quipment && <React.Fragment>
                    <Accordion.Title id={"statt"+StatType.Quipment} active={activeMap.get(StatType.Quipment)} index={StatType.Quipment} onClick={() => handleClick(StatType.Quipment)}>
                        <h2><Icon name='dropdown' />{isMobileOnly?"Quipment":"Continuum equipment"}{' '}
                        <Label horizontal size="large" className="stats-label">
                            Legendary
                            <LabelDetail>
                                {crew.legendaryQuipment}
                            </LabelDetail>
                        </Label>
                        </h2>
                    </Accordion.Title>
                    <Accordion.Content id={"stat"+StatType.Quipment} active={activeMap.get(StatType.Quipment)}>
                        {!quipments && <div className="center ui active loader"/>}
                        {quipments && <Segment inverted className="statBg">
                            <Grid stackable columns="10" relaxed>
                                {quipments.map((item, idx)=>
                                    <div className={isOnlyMobile?"quipmentM":"quipment"} key={"var"+idx}>
                                        <span className={rarityClass(item.rarity)}>
                                            <img className="q-portrait" src={getAssetURL(item.icon)} alt={item.name}/>
                                        </span>                                           
                                        <div className="var-details">
                                            <div>
                                                <span className={rarityClass(item.rarity)+" qname"}>{item.name}</span>
                                                <br/>
                                                <div className="qskilldata">
                                                {Object.keys(item.bases).map((base,bidx)=>{
                                                    if (item.bases[base]>0 || item.minProfs[base]>0 || item.maxProfs[base]>0)
                                                        return <span className="qskill" key={"s"+bidx}>
                                                            <div className="qskilliconcontainer"><img className="qskillicon" src={getAssetURL("icon_"+base+".png")} alt={base}/></div>
                                                            {item.bases[base]} <small style={{ fontSize: '80%', color: 'darkgray' }}>({item.minProfs[base]}-{item.maxProfs[base]})</small> 
                                                        </span> 
                                                })}
                                                <div>
                                                {item.voyage && getOptimal("voyage")}
                                                {item.gauntlet && getOptimal("gauntlet")}
                                                {item.shuttle && getOptimal("shuttle")}
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                            
                                )}
                            </Grid>
                        </Segment>}
                    </Accordion.Content>
                </React.Fragment>}
            </Accordion>
        </div>
        <Footer loaded={()=>setLoaded(true)}/>
    </div>)
  }

  export default CrewPage;