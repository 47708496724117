import '../../assets/fomantic/dist/semantic.css';
import { List, Image } from 'semantic-ui-react';
import React from 'react';
import AdSense from 'react-adsense';
import ReactGA from 'react-ga4';

import '../../App.css';
import { NamerCrew } from '../../services/NamerStats';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { Link } from '@reach/router';

export const GauntletStats = (props: {
    crew: NamerCrew;
    full?: boolean;
    showAd?: boolean;
}) => {
    const isOnlyMobile = isMobile && !isTablet;
    const lines = (props.crew.gSp??[]).length + (props.crew.gS??[]).length + (props.crew.gA??[]).length + (props.crew.gB??[]).length + 
        (props.crew.gC??[]).length + (props.crew.gD??[]).length + (props.crew.gF??[]).length;

    const getLink = (gauntlet, text: string) => {
        return <Link className="blueLink" to={"/gauntlet/"+gauntlet}>{text}</Link>
    }

    const getTierMentions = (g: string[]) => {
        return g.map((perf, idx) => {
            let tokens = perf.split(" ")
            if (tokens.length < 3) {
                return <small key={idx}>{perf}</small>
            }
            let gauntlet = tokens[1].split(",").join(", ");
            return (<div className={isOnlyMobile?"":"mono"} style={isOnlyMobile?{marginTop: "5px"}: {}} key={idx}>
                {tokens[0].padStart(3, "\xa0")} in {getLink(tokens[1], gauntlet)} {tokens[2]}
            </div>)         
        });
    }

    const crew = props.crew;
    let mentions = crew.gSp || crew.gS || crew.gA || crew.gB || crew.gC || crew.gD || crew.gF;

    const getTopPerformances = () => {
        if (!mentions) {
            return;
        }
        let style = isOnlyMobile?{marginTop: "5px"} : {};
        return <React.Fragment>
            {crew.gSp && <List.Item style={style}>
                <Image><List.Header className="center">Tier S+</List.Header></Image>
                <List.Content>
                    <List.Description>
                        {getTierMentions(crew.gSp)}
                    </List.Description>
                </List.Content>
                </List.Item>}
                {crew.gS && <List.Item>
                <Image><List.Header className="center">Tier S</List.Header></Image>
                <List.Content>
                    <List.Description>
                        {getTierMentions(crew.gS)}
                    </List.Description>
                </List.Content>
                </List.Item>}
                {crew.gA && <List.Item style={style}>
                <Image><List.Header className="center">Tier A</List.Header></Image>
                <List.Content>
                    <List.Description>
                        {getTierMentions(crew.gA)}
                    </List.Description>
                </List.Content>
                </List.Item>}
                {crew.gB && <List.Item style={style}>
                <Image><List.Header className="center">Tier B</List.Header></Image>
                <List.Content>
                    <List.Description>
                        {getTierMentions(crew.gB)}
                    </List.Description>
                </List.Content>
                </List.Item>}
                {crew.gC && <List.Item style={style}>
                <Image><List.Header className="center">Tier C</List.Header></Image>
                <List.Content>
                    <List.Description>
                        {getTierMentions(crew.gC)}
                    </List.Description>
                </List.Content>
                </List.Item>}
                {crew.gD && <List.Item style={style}>
                <Image><List.Header className="center">Tier D</List.Header></Image>
                <List.Content>
                    <List.Description>
                        {getTierMentions(crew.gD)}
                    </List.Description>
                </List.Content>
                </List.Item>}
                {crew.gF && <List.Item style={style}>
                <Image><List.Header className="center">Tier F</List.Header></Image>
                <List.Content>
                    <List.Description>
                        {getTierMentions(crew.gF)}
                    </List.Description>
                </List.Content>
                </List.Item>}
        </React.Fragment>
    }

    return (<List divided relaxed>
        {props.full && <List.Item>
            <List.Icon name='chart bar outline' size='large' verticalAlign='top' />
            <List.Content>
                <List.Header>Number of gauntlets with crit</List.Header>
                <List.Description>
                    <div className="gridDisplay">
                        {crew.gCritCount.map((x, idx)=> {
                            if (x === 0) {
                                return "";
                            }
                            return <span key={"c"+idx}>{(idx === 0 ? "65%" : (idx === 1 ? "45%" : "25%"))} crit in {x} gauntlet{x>1?"s":""}</span>
                        })}
                    </div>
                    {!props.crew.gCritCount.find(x=>x !== 0) && "Zero"}
                </List.Description>
            </List.Content>
        </List.Item>}
        <List.Item>
            <List.Icon name='trophy' size='large' verticalAlign='top' />
            <List.Content>
                <List.Header>Top performances</List.Header>
                <List.Description>
                    {mentions && <span>Ordered list of gauntlets by performance rating. {isOnlyMobile ? "Tap" : "Click"} for details.</span>}
                    {!mentions && <span>{crew.name} is rated <b>F-tier</b> in all gauntlets.</span>}
                    {mentions && <List divided relaxed>
                        {!isOnlyMobile && getTopPerformances()}
                    </List>}
                </List.Description>
            </List.Content>
            {mentions && isOnlyMobile && getTopPerformances()}
        </List.Item>
        {crew.gPairMentions && crew.gPairMentions.length > 0 && <List.Item>
            <List.Icon name='trophy' size='large' verticalAlign='top' />
            <List.Content>
                <List.Header>Average rolls in top 5</List.Header>
                <List.Description>
                    <div className={isOnlyMobile?"":"mono"} style={{display:"block", marginTop:"5px"}}>
                        {crew.gPairMentions.map((x,idx)=>(<div key={idx}>{x}</div>))}
                    </div>
                </List.Description>
            </List.Content>
        </List.Item>}
        {crew.gMentions && crew.gMentions.length > 0 && <List.Item>
            <List.Icon name='trophy' size='large' verticalAlign='top' />
            <List.Content>
                <List.Header>Top 5 overall scores</List.Header>
                <List.Description>
                    {crew.gMentions.map((x,idx)=>(<div key={idx}>{x}</div>))}
                </List.Description>
            </List.Content>
        </List.Item>}
        {props.showAd && lines > 5 && <div className="adslot">
            {(window as any).adsbygoogle?.loaded && <span className='adlabel'>Advertisement</span>}
            {isMobileOnly && <AdSense.Google
                className="ad"
                client='ca-pub-5995456634193629'
                slot='9887762297'
                style={{ display: 'block'}}
                format='auto'
                responsive='true'
            />}
            {!isMobileOnly && <AdSense.Google
                className="ad adfixed"
                client='ca-pub-5995456634193629'
                slot='4467914653'
                style={{ display: 'inline-block', width: '970px', height: '250px', border: '1px solid #444444'}}
                format='fixed'
            />}
        </div>}
    </List>)
  }

  export default GauntletStats;                